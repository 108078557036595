import { Stack, TextField, Typography } from "@mui/material";
import CustomCheckbox from "../Base/CustomCheckbox";

export interface ResponseConfirmationCheckProps {
  responsesAreRepresentative: boolean;
  handleResponsesAreRepresentativechange: any;
  clarifications: string;
  handleClarificationsChange: any;
  otherInfo: string;
  handleOtherInfoChange: any;
}

const ResponseConfirmationCheck = (props: ResponseConfirmationCheckProps) => {
  return (
    <>
      <CustomCheckbox
        value={props.responsesAreRepresentative}
        onChange={props.handleResponsesAreRepresentativechange}
        text="Confirm that all responses provided in the above Section areRepresentative of all the LE’s branches."
      />
      <Stack spacing={2} pl="48px">
        <Typography fontSize="16px">
          {
            "If no, clarify which questions the difference/s relate to and the branch/es that this applies to. "
          }
        </Typography>
        <TextField
          disabled={props.responsesAreRepresentative}
          multiline
          rows={4}
          value={props.clarifications}
          className="ownership-multiline ownership-input-root"
          onChange={props.handleClarificationsChange}
        />
      </Stack>
      <Stack spacing={2}>
        <Typography fontSize="16px">
          {
            "If appropriate, provide any additional information/context to the answers in this section"
          }
        </Typography>
        <TextField
          multiline
          rows={4}
          value={props.otherInfo}
          className="ownership-multiline ownership-input-root"
          onChange={props.handleOtherInfoChange}
        />
      </Stack>
    </>
  );
};

export default ResponseConfirmationCheck;
