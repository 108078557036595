import React, { PropsWithChildren } from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material";

const CustomStyleButton = styled(Button)<ButtonProps>(({ theme }) => ({
  ".MuiButton-root": {
    backgroundColor: theme.palette.primary.main,
    "& :disabled": {
      backgroundColor: "#9CAABB",
      color: "#FFF",
    },
  },
}));

function CustomButton(props: PropsWithChildren<ButtonProps>) {
  return <CustomStyleButton {...props}>{props.children}</CustomStyleButton>;
}

export default CustomButton;
