import {
  Button,
  LinearProgress,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import CustomTable from "../Base/CustomTable";
import Propsectus from "../../utils/mockup/documents/Prospectus.pdf";
import TermSheet from "../../utils/mockup/documents/TermSheet.pdf";
import { getProductDocumentDetails } from "../../routes/hytokenisation/getDocumentDetails";

import { ReactComponent as DownloadIcon } from "../../assets/images/download.svg";
import { TokenDetail } from "../../utils/mockup/tokenisation/getTokenDetail";
import { currencyFormatter } from "../../utils/functions/currencyFormatter";
import { getHydidDisplayName } from "../../routes/walletid/hydid/getHydid";
import { useEffect, useState } from "react";

export interface TokenDetailsBoxProps {
  tokenDetails: TokenDetail;
  mockup: boolean;
  canPledge: boolean;
  onClickPledge: any;
}

const TokenDetailsBox = (props: TokenDetailsBoxProps) => {
  const [issuer, setIssuer] = useState(props.tokenDetails.issuer);
  const [loadedIssuer, setLoadedIssuer] = useState(props.mockup);
  useEffect(() => {
    if (!props.mockup) {
      getHydidDisplayName(props.tokenDetails.issuer)
        .then((value) => {
          setIssuer(value);
        })
        .finally(() => setLoadedIssuer(true));
    } else {
      setLoadedIssuer(true);
    }
  }, [props.mockup, props.tokenDetails.issuer]);

  const onClickDownloadProspectus = () => {
    if (props.mockup) {
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.href = Propsectus;
      a.download = "prospectus";
      a.click();
      window.URL.revokeObjectURL(Propsectus);
    } else {
      getProductDocumentDetails({
        hash: props.tokenDetails.prospectus_hash,
      }).then((value) => {
        const linkSource = `data:application/pdf;base64,${value.bytes}`;
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = linkSource;
        a.download = "prospectus";
        a.click();
        document.body.removeChild(a);
      });
    }
  };

  const onClickDownloadTermSheet = () => {
    if (props.mockup) {
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.href = TermSheet;
      a.download = "termsheet";
      a.click();
      window.URL.revokeObjectURL(TermSheet);
    } else {
      getProductDocumentDetails({
        hash: props.tokenDetails.term_sheet_hash,
      }).then((value) => {
        const linkSource = `data:application/pdf;base64,${value.bytes}`;
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = linkSource;
        a.download = "termsheet";
        a.click();
        document.body.removeChild(a);
      });
    }
  };

  return (
    <Stack direction="column" spacing={4} width="100%" alignItems="flex-end">
      <CustomTable>
        <TableBody>
          <TableRow key="name">
            <TableCell style={{ width: "25%" }}>
              <Typography variant="body1" fontWeight={600}>
                Name
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography variant="body1" textAlign="left">
                {props.tokenDetails.asset}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow key="price">
            <TableCell style={{ width: "25%" }}>
              <Typography variant="body1" fontWeight={600}>
                Price
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography variant="body1" textAlign="left">
                {currencyFormatter(props.tokenDetails.price)}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow key="nominal">
            <TableCell style={{ width: "25%" }}>
              <Typography variant="body1" fontWeight={600}>
                Nominal
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography variant="body1" textAlign="left">
                {currencyFormatter(props.tokenDetails.nominal)}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow key="interest">
            <TableCell>
              <Typography variant="body1" fontWeight={600}>
                Interest
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body1">
                {props.tokenDetails.interest}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow key="fixed/variable">
            <TableCell>
              <Typography variant="body1" fontWeight={600}>
                Fixed/Variable
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body1">{props.tokenDetails.type}</Typography>
            </TableCell>
          </TableRow>
          <TableRow key="term">
            <TableCell>
              <Typography variant="body1" fontWeight={600}>
                Term
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body1">{props.tokenDetails.term}</Typography>
            </TableCell>
          </TableRow>
          <TableRow key="issuer">
            <TableCell sx={{ borderBottom: "unset" }}>
              <Typography variant="body1" fontWeight={600}>
                Issuer
              </Typography>
            </TableCell>
            <TableCell sx={{ borderBottom: "unset" }}>
              {loadedIssuer ? (
                <Typography variant="body1">{issuer}</Typography>
              ) : (
                <LinearProgress />
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </CustomTable>

      <CustomTable>
        <TableBody>
          <TableRow key="prospectus">
            <TableCell style={{ width: "25%" }}>
              <Typography variant="body1" fontWeight={600}>
                Prospectus
              </Typography>
            </TableCell>
            <TableCell>
              <Button
                variant="outlined"
                color="primary"
                endIcon={<DownloadIcon />}
                component="label"
                onClick={onClickDownloadProspectus}
              >
                Download PDF
              </Button>
            </TableCell>
          </TableRow>
          <TableRow key="termsheet">
            <TableCell sx={{ borderBottom: "unset" }}>
              <Typography variant="body1" fontWeight={600}>
                {"Term Sheet"}
              </Typography>
            </TableCell>
            <TableCell sx={{ borderBottom: "unset" }}>
              <Button
                variant="outlined"
                color="primary"
                endIcon={<DownloadIcon />}
                component="label"
                onClick={onClickDownloadTermSheet}
              >
                Download PDF
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </CustomTable>
      {props.canPledge && (
        <Button
          variant="contained"
          color="primary"
          component="label"
          onClick={props.onClickPledge}
          sx={{ width: "fit-content" }}
        >
          {"Pledge"}
        </Button>
      )}
    </Stack>
  );
};

export default TokenDetailsBox;
