import { Box, BoxProps, styled } from "@mui/material";

const CustomModalBox = styled(Box)<BoxProps>(({ theme }) => ({
  width: "fit-content",
  height: "fit-content",
  display: "flex",
  flexDirection: "column",
  component: "div",
  justifyContent: "center",
  alignItems: "center",
  top: "50%",
  left: "50%",
  padding: "32px",
  position: "absolute",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  border: "1px solid #EBEBEB",
  borderRadius: "8px",
  boxShadow: `${theme.shadows[4]}`,
}));

export default CustomModalBox;
