import axios, { AxiosResponse } from "axios";
import { HyDidInfo, HyLabsResponse } from "../../utils/types/interfaces";
import { HYNETWORK_AXIOS_CONFIG_JSON_CONTENT } from "../util";

export interface UpdateFreezeStatus {
  user_id: string;
  freeze: boolean;
}

export function setFreezeStatus(
  data: UpdateFreezeStatus
): Promise<UpdateFreezeStatus> {
  return axios
    .post("/admin/hy-did/status", data, HYNETWORK_AXIOS_CONFIG_JSON_CONTENT)
    .then((response: AxiosResponse): UpdateFreezeStatus => {
      const hynetwork_response: HyLabsResponse = response.data;
      const status_info: UpdateFreezeStatus = hynetwork_response.data;
      return status_info;
    })
    .catch((err) => {
      throw err;
    });
}

export interface GetUserHyDidInfo {
  user_id: string;
  info: HyDidInfo;
}

export function getUserHydidInfo(
  path_user_id: string
): Promise<GetUserHyDidInfo> {
  return axios
    .get(`/admin/hy-did/${path_user_id}`, HYNETWORK_AXIOS_CONFIG_JSON_CONTENT)
    .then((response: AxiosResponse): GetUserHyDidInfo => {
      const hynetwork_response: HyLabsResponse = response.data;
      const hydid_info: GetUserHyDidInfo = hynetwork_response.data;
      return hydid_info;
    })
    .catch((err) => {
      throw err;
    });
}
