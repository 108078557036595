import { Button, ButtonProps, Typography, styled } from "@mui/material";

export enum CustomMarkColor {
  Green = "rgba(54, 210, 0, 0.12)",
  Red = "rgba(238, 80, 90, 0.12)",
  Grey = " rgba(154, 154, 154, 0.12)",
}

export enum CustomMarkTextColor {
  Green = "#36D200",
  Red = "#EE505A",
  Grey = "#9A9A9A",
}

export interface CustomMarkProps {
  markColor: CustomMarkColor;
  markTextColor: CustomMarkTextColor;
  text: string;
}

const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: "rgba(54, 210, 0, 0.12)",
  width: "fit-content",
  height: "26px",
  padding: "4px 8px",
  borderRadius: "4px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
}));

const CustomMark = (props: ButtonProps & CustomMarkProps) => {
  const { text, markColor, markTextColor, ...buttonProps } = props;
  return (
    <CustomButton {...buttonProps} sx={{ backgroundColor: markColor }}>
      <Typography variant="body2" fontWeight={600} color={markTextColor}>
        {text}
      </Typography>
    </CustomButton>
  );
};

export default CustomMark;
