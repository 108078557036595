var BASE64_MARKER: string = ";base64,";

export function dataURLtoBase64(dataURI?: string): string {
  if (dataURI) {
    const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    const base64 = dataURI.substring(base64Index);
    return base64;
  } else {
    return "";
  }
}
