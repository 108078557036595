import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  MenuItem,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CustomInputField from "../../components/Base/CustomInputField";
import BusinessArea from "../../components/Questionnaire/BusinessArea";
import CustomButton from "../../components/Base/CustomButton";
import {
  KYB_CONTEXT_DELIMITER,
  KYBContext,
} from "../../context/KYBContextProvider";
import { onboardProfessionalInstitution } from "../../routes/hynetwork/onboarding";
import {
  EmployeesNumber,
  EmployeesNumberUtils,
  TotalAssetsValue,
  TotalAssetsValueUtils,
} from "../../utils/types/enums";
import {
  ProfessionalInstitution,
  ProInstAbc,
  ProInstAmlCtfSanctionPoliciesProcedures,
  ProInstAmlCtfSanctionRiskAssessment,
  ProInstAmlCtfSanctionStandards,
  ProInstAudit,
  ProInstBusinessArea,
  ProInstKycCddEdd,
  ProInstMonitoringAndReporting,
  ProInstPaymentTransparency,
  ProInstProductAndServices,
  ProInstQualityAssuranceComplianceTesting,
  ProInstSanctions,
  ProInstTrainingAndEducation,
} from "../../utils/types/interfaces";
import { getEnumKeys } from "../../utils/functions/getEnumKeys";
import ResponseConfirmationCheck from "../../components/Questionnaire/ResponseConfirmationCheck";
import OwnershipStructure from "../../components/Questionnaire/OwnershipStructure";
import CustomSelectField from "../../components/Base/CustomSelectField";
import { useNavigate } from "react-router-dom";
import CustomCheckbox from "../../components/Base/CustomCheckbox";

//TODO verify if user logged and institution reguluated

function Ownership() {
  const navigate = useNavigate();

  const {
    institution,
    professionalInstitutionSummary,
    professionalInstitution,
    setProfessionalInstitution,
    cleanSession,
  } = useContext(KYBContext);

  useEffect(() => {
    if (
      institution === undefined ||
      institution?.registered_address === "" ||
      institution?.company_name === "" ||
      institution?.company_registration_number === "" ||
      professionalInstitutionSummary === undefined ||
      professionalInstitutionSummary?.registered_address.length === 0 ||
      professionalInstitutionSummary?.incorporation_date === ""
    ) {
      navigate("/questionnaire/summary");
    }
  }, [institution, professionalInstitutionSummary, navigate]);

  const [publiclyTraded, setPubliclyTraded] = useState(
    professionalInstitution !== undefined &&
      professionalInstitution.ownership_structure !== undefined &&
      professionalInstitution.ownership_structure.publicly_traded !== undefined
      ? professionalInstitution.ownership_structure.publicly_traded
      : false
  );

  const [exchange, setExchange] = useState(
    professionalInstitution !== undefined &&
      professionalInstitution.ownership_structure !== undefined &&
      professionalInstitution.ownership_structure.exchange !== undefined
      ? professionalInstitution.ownership_structure.exchange
      : ""
  );

  const [ticker, setTicker] = useState(
    professionalInstitution !== undefined &&
      professionalInstitution.ownership_structure !== undefined &&
      professionalInstitution.ownership_structure.ticker !== undefined
      ? professionalInstitution.ownership_structure.ticker
      : ""
  );

  const [memberOwned, setMemberOwned] = useState(
    professionalInstitution !== undefined &&
      professionalInstitution.ownership_structure !== undefined &&
      professionalInstitution.ownership_structure.member_owned !== undefined
      ? professionalInstitution.ownership_structure.member_owned
      : false
  );

  const [governmentOwned, setGovernmentOwned] = useState(
    professionalInstitution !== undefined &&
      professionalInstitution.ownership_structure !== undefined &&
      professionalInstitution.ownership_structure.government_owned !== undefined
      ? professionalInstitution.ownership_structure.government_owned
      : false
  );

  const [privatelyOwned, setPrivatelyOwned] = useState(
    professionalInstitution !== undefined &&
      professionalInstitution.ownership_structure !== undefined &&
      professionalInstitution.ownership_structure.privately_owned !== undefined
      ? professionalInstitution.ownership_structure.privately_owned
      : false
  );

  const [beneficialOwner, setBeneficialOwner] = useState(
    professionalInstitution !== undefined &&
      professionalInstitution.ownership_structure !== undefined &&
      professionalInstitution.ownership_structure
        .private_shareholder_details !== undefined
      ? professionalInstitution.ownership_structure.private_shareholder_details
          .split(KYB_CONTEXT_DELIMITER)[0]
          .trim()
      : ""
  );

  const [dateOfBirth, setDateOfBirth] = useState(
    professionalInstitution !== undefined &&
      professionalInstitution.ownership_structure !== undefined &&
      professionalInstitution.ownership_structure
        .private_shareholder_details !== undefined
      ? professionalInstitution.ownership_structure.private_shareholder_details
          .split(KYB_CONTEXT_DELIMITER)[1]
          .trim()
      : ""
  );

  const [percBearerShares, setPercBearerShares] = useState(
    professionalInstitution !== undefined &&
      professionalInstitution.perc_bearer_shares !== undefined
      ? professionalInstitution.perc_bearer_shares
      : ""
  );

  const [branchesUnderOblCheck, setBranchesUnderOblCheck] = useState(
    professionalInstitution !== undefined &&
      professionalInstitution.obl_operated !== undefined
      ? professionalInstitution.obl_operated
      : false
  );

  const [branchesUnderObl, setBranchesUnderObl] = useState(
    !branchesUnderOblCheck &&
      professionalInstitution !== undefined &&
      professionalInstitution.branches_under_obl !== undefined &&
      professionalInstitution.branches_under_obl.length > 0
      ? professionalInstitution.branches_under_obl[0]
      : ""
  );

  const [virtualBankLicenceCheck, setVirtualBankLicenceCheck] = useState(false);

  const [primaryFinancialRegulator, setPrimaryFinancialRegulator] = useState(
    professionalInstitution !== undefined &&
      professionalInstitution.primary_financial_regulator !== undefined
      ? professionalInstitution.primary_financial_regulator
      : ""
  );

  const [legalEntityIdentifier, setLegalEntityIdentifier] = useState(
    professionalInstitution !== undefined &&
      professionalInstitution.legal_entity_identifier !== undefined
      ? professionalInstitution.legal_entity_identifier
      : ""
  );

  const [ultParentLegalName, setUltParentLegalName] = useState(
    professionalInstitution !== undefined &&
      professionalInstitution.ult_parent_legal_name !== undefined
      ? professionalInstitution.ult_parent_legal_name
      : ""
  );

  const [licensingAuthorityJurisdiction, setLicensingAuthorityJurisdiction] =
    useState(
      professionalInstitution !== undefined &&
        professionalInstitution.licensing_authority_jurisdiction !== undefined
        ? professionalInstitution.licensing_authority_jurisdiction
        : ""
    );

  const [retailBanking, setRetailBanking] = useState(
    professionalInstitution !== undefined &&
      professionalInstitution.business_area !== undefined &&
      professionalInstitution.business_area.retail_banking !== undefined
      ? professionalInstitution.business_area.retail_banking
      : false
  );

  const [privateBanking, setPrivateBanking] = useState(
    professionalInstitution !== undefined &&
      professionalInstitution.business_area !== undefined &&
      professionalInstitution.business_area.private_banking !== undefined
      ? professionalInstitution.business_area.private_banking
      : false
  );

  const [commercialBanking, setCommercialBanking] = useState(
    professionalInstitution !== undefined &&
      professionalInstitution.business_area !== undefined &&
      professionalInstitution.business_area.commercial_banking !== undefined
      ? professionalInstitution.business_area.commercial_banking
      : false
  );

  const [transactionalBanking, setTransactionalBanking] = useState(
    professionalInstitution !== undefined &&
      professionalInstitution.business_area !== undefined &&
      professionalInstitution.business_area.transactional_banking !== undefined
      ? professionalInstitution.business_area.transactional_banking
      : false
  );

  const [investmentBanking, setInvestmentBanking] = useState(
    professionalInstitution !== undefined &&
      professionalInstitution.business_area !== undefined &&
      professionalInstitution.business_area.investment_banking !== undefined
      ? professionalInstitution.business_area.investment_banking
      : false
  );

  const [financialMarketTrading, setFinancialMarketTrading] = useState(
    professionalInstitution !== undefined &&
      professionalInstitution.business_area !== undefined &&
      professionalInstitution.business_area.financial_market_trading !==
        undefined
      ? professionalInstitution.business_area.financial_market_trading
      : false
  );

  const [securitiesService, setSecuritiesService] = useState(
    professionalInstitution !== undefined &&
      professionalInstitution.business_area !== undefined &&
      professionalInstitution.business_area.securities_service !== undefined
      ? professionalInstitution.business_area.securities_service
      : false
  );

  const [broker, setBroker] = useState(
    professionalInstitution !== undefined &&
      professionalInstitution.business_area !== undefined &&
      professionalInstitution.business_area.broker !== undefined
      ? professionalInstitution.business_area.broker
      : false
  );

  const [multilateralDevelopmentBank, setMultilateralDevelopmentBank] =
    useState(
      professionalInstitution !== undefined &&
        professionalInstitution.business_area !== undefined &&
        professionalInstitution.business_area.multilateral_development_bank !==
          undefined
        ? professionalInstitution.business_area.multilateral_development_bank
        : false
    );

  const [wealthManager, setWealthManager] = useState(
    professionalInstitution !== undefined &&
      professionalInstitution.business_area !== undefined &&
      professionalInstitution.business_area.wealth_manager !== undefined
      ? professionalInstitution.business_area.wealth_manager
      : false
  );

  const [other, setOther] = useState(
    professionalInstitution !== undefined &&
      professionalInstitution.business_area !== undefined &&
      professionalInstitution.business_area.other !== undefined &&
      professionalInstitution.business_area.other
      ? professionalInstitution.business_area.other
      : ""
  );

  const [nonResidentCustomerCheck, setNonResidentCustomerCheck] = useState(
    professionalInstitution !== undefined &&
      professionalInstitution.non_resident_customer_check !== undefined
      ? professionalInstitution.non_resident_customer_check
      : false
  );

  const [nonResidentCustomerCountries, setNonResidentCustomerCountries] =
    useState(
      !nonResidentCustomerCheck &&
        professionalInstitution !== undefined &&
        professionalInstitution.non_resident_customer_countries !== undefined &&
        professionalInstitution.non_resident_customer_countries.length > 0
        ? professionalInstitution.non_resident_customer_countries[0]
        : ""
    );

  const [numEmployee, setNumEmployee] = useState<EmployeesNumber>(
    professionalInstitution !== undefined &&
      professionalInstitution.employees !== undefined
      ? professionalInstitution.employees
      : EmployeesNumber.E0to50
  );

  const [assets, setAssets] = useState<TotalAssetsValue>(
    professionalInstitution !== undefined &&
      professionalInstitution.assets !== undefined
      ? professionalInstitution.assets
      : TotalAssetsValue.V0to10
  );

  const [
    responsesRepresentativeOfAllLeBranchesCheck,
    setResponsesRepresentativeOfAllLeBranchesCheck,
  ] = useState(
    professionalInstitution !== undefined &&
      professionalInstitution.responses_representative_of_all_le_branches_check !==
        undefined &&
      professionalInstitution.responses_representative_of_all_le_branches_check
        .check !== undefined
      ? professionalInstitution
          .responses_representative_of_all_le_branches_check.check
      : false
  );

  const [clarifyDifferences, setClarifyDifferences] = useState(
    !responsesRepresentativeOfAllLeBranchesCheck &&
      professionalInstitution !== undefined &&
      professionalInstitution.responses_representative_of_all_le_branches_check !==
        undefined &&
      professionalInstitution.responses_representative_of_all_le_branches_check
        .clarify_differences !== undefined
      ? professionalInstitution
          .responses_representative_of_all_le_branches_check.clarify_differences
      : ""
  );

  const [additionalInformation, setAdditionalInformation] = useState(
    professionalInstitution !== undefined &&
      professionalInstitution.responses_representative_of_all_le_branches_check !==
        undefined &&
      professionalInstitution.responses_representative_of_all_le_branches_check
        .additional_informations !== undefined
      ? professionalInstitution
          .responses_representative_of_all_le_branches_check
          .additional_informations
      : ""
  );

  const handlePubliclyTradedChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (event) => {
    setPubliclyTraded(event.target.checked);
    if (!event.target.checked) {
      setExchange("");
      setTicker("");
    }
  };

  const handleExchangeChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setExchange(event.target.value);
  };

  const handleTickerChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setTicker(event.target.value);
  };

  const handleMemberOwnedChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setMemberOwned(event.target.checked);
  };

  const handleStateOwnedChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setGovernmentOwned(event.target.checked);
  };

  const handlePrivatelyOwnedChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (event) => {
    setPrivatelyOwned(event.target.checked);
  };

  const handleBeneficialOwnerChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (event) => {
    setBeneficialOwner(event.target.value);
  };

  const handleDateOfBirthChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setDateOfBirth(event.target.value);
  };

  const handleEntitySharesChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    Number(event.target.value) < 0
      ? setPercBearerShares(0)
      : setPercBearerShares(event.target.value);
  };

  const handleOblDetailsCheckChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (event) => {
    setBranchesUnderOblCheck(event.target.checked);
  };

  const handleOblDetailsChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setBranchesUnderObl(event.target.value);
  };

  const handleRetailChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setRetailBanking(event.target.checked);
  };

  const handlePrivateChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setPrivateBanking(event.target.checked);
  };

  const handleCommercialChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setCommercialBanking(event.target.checked);
  };

  const handleTransactionalChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (event) => {
    setTransactionalBanking(event.target.checked);
  };

  const handleInvestmentChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setInvestmentBanking(event.target.checked);
  };

  const handleFinancialChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setFinancialMarketTrading(event.target.checked);
  };

  const handleSecuritiesChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setSecuritiesService(event.target.checked);
  };

  const handleBrokerChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setBroker(event.target.checked);
  };

  const handleMultilateralChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setMultilateralDevelopmentBank(event.target.checked);
  };

  const handleWealthChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setWealthManager(event.target.checked);
  };

  const handleOtherChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setOther(event.target.value);
  };

  const handleVirtualBankLicenceCheckChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (event) => {
    setVirtualBankLicenceCheck(event.target.checked);
  };

  const handleNamePrimaryChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setPrimaryFinancialRegulator(event.target.value);
  };

  const handleLegalEntityIdChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (event) => {
    setLegalEntityIdentifier(event.target.value);
  };

  const handleLegalNameChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setUltParentLegalName(event.target.value);
  };

  const handleJurisdictionChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setLicensingAuthorityJurisdiction(event.target.value);
  };

  const handleCustomersCountriesChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (event) => {
    setNonResidentCustomerCountries(event.target.value);
  };

  const handleNonResidentCustomerCheckChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (event) => {
    setNonResidentCustomerCheck(event.target.checked);
  };

  const handleNumEmployeeChange = (
    event: SelectChangeEvent<EmployeesNumber>
  ) => {
    setNumEmployee(
      EmployeesNumber[event.target.value as keyof typeof EmployeesNumber]
    );
  };

  const handleTotAssetsChange = (
    event: SelectChangeEvent<TotalAssetsValue>
  ) => {
    setAssets(
      TotalAssetsValue[event.target.value as keyof typeof TotalAssetsValue]
    );
  };

  const handleResponsesRepresentativeOfAllLeBranchesCheck: React.ChangeEventHandler<
    HTMLInputElement
  > = (event) => {
    setResponsesRepresentativeOfAllLeBranchesCheck(event.target.checked);
  };

  const handleClarificationsChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (event) => {
    setClarifyDifferences(event.target.value);
  };

  const handleOtherInfoChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setAdditionalInformation(event.target.value);
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    handleOnSaveDraft();
    const form_data = buildForm();

    onboardProfessionalInstitution(form_data)
      .then(() => {
        cleanSession?.();
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleOnSaveDraft = () => {
    const form_data_draft = buildForm();
    form_data_draft.ownership_structure.private_shareholder_details =
      beneficialOwner + KYB_CONTEXT_DELIMITER + dateOfBirth;
    setProfessionalInstitution?.(form_data_draft);
  };

  const buildForm = () => {
    const form_data: ProfessionalInstitution = {
      country:
        institution?.registered_address &&
        institution?.registered_address.split(KYB_CONTEXT_DELIMITER)[0]
          ? institution?.registered_address
              .split(KYB_CONTEXT_DELIMITER)[0]
              .trim()
          : "",
      company_name: institution?.company_name || "",
      company_registration_number:
        institution?.company_registration_number || "",
      registered_address: professionalInstitutionSummary?.registered_address
        ? professionalInstitutionSummary?.registered_address.join(", ")
        : "",
      incorporation_date:
        professionalInstitutionSummary?.incorporation_date + "T00:00:00Z" || "",
      business_address: professionalInstitutionSummary?.business_address
        ? professionalInstitutionSummary?.business_address.join(", ")
        : "",
      ownership_structure: {
        publicly_traded: publiclyTraded,
        exchange: exchange,
        ticker: ticker,
        member_owned: memberOwned,
        government_owned: governmentOwned,
        privately_owned: privatelyOwned,
        private_shareholder_details: beneficialOwner + ", " + dateOfBirth,
      },
      perc_bearer_shares: Number(percBearerShares),
      obl_operated: branchesUnderOblCheck,
      branches_under_obl: [branchesUnderObl],
      primary_financial_regulator: primaryFinancialRegulator,
      legal_entity_identifier: legalEntityIdentifier,
      ult_parent_legal_name: ultParentLegalName,
      licensing_authority_jurisdiction: licensingAuthorityJurisdiction,
      business_area: getBusinessArea(),
      non_resident_customer_check: nonResidentCustomerCheck,
      non_resident_customer_countries: [nonResidentCustomerCountries],
      employees: numEmployee ? numEmployee : EmployeesNumber.E0to50,
      assets: assets ? assets : TotalAssetsValue.V0to10,
      responses_representative_of_all_le_branches_check: {
        check: responsesRepresentativeOfAllLeBranchesCheck,
        clarify_differences: clarifyDifferences,
        additional_informations: additionalInformation,
      },
      product_and_service: ProInstProductAndServices.defaultValue(),
      aml_ctf_sanction_standard: ProInstAmlCtfSanctionStandards.defaultValue(),
      abc: ProInstAbc.defaultValue(),
      aml_ctf_sanction_policy_procedure:
        ProInstAmlCtfSanctionPoliciesProcedures.defaultValue(),
      aml_ctf_sanction_risk_assessment:
        ProInstAmlCtfSanctionRiskAssessment.defaultValue(),
      kyc_cdd_edd: ProInstKycCddEdd.defaultValue(),
      monitoring_and_reporting: ProInstMonitoringAndReporting.defaultValue(),
      payment_transparency: ProInstPaymentTransparency.defaultValue(),
      sanctions: ProInstSanctions.defaultValue(),
      training_and_education: ProInstTrainingAndEducation.defaultValue(),
      quality_assurance_compliance_testing:
        ProInstQualityAssuranceComplianceTesting.defaultValue(),
      audit: ProInstAudit.defaultValue(),
      declaration_statement_signature: "declaration_statement_signature",
    };
    return form_data;
  };

  const getBusinessArea = (): ProInstBusinessArea => {
    return {
      retail_banking: retailBanking,
      private_banking: privateBanking,
      commercial_banking: commercialBanking,
      transactional_banking: transactionalBanking,
      investment_banking: investmentBanking,
      financial_market_trading: financialMarketTrading,
      securities_service: securitiesService,
      broker: broker,
      multilateral_development_bank: multilateralDevelopmentBank,
      wealth_manager: wealthManager,
      other: other,
    };
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack alignItems="start" spacing={4} mb="32px">
        <Typography width="100%" fontSize="26px" fontWeight="500">
          Ownership
        </Typography>
        <OwnershipStructure
          publiclyTraded={publiclyTraded}
          handlePubliclyTradedChange={handlePubliclyTradedChange}
          exchange={exchange}
          handleExchangeChange={handleExchangeChange}
          ticker={ticker}
          handleTickerChange={handleTickerChange}
          memberOwned={memberOwned}
          handleMemberOwnedChange={handleMemberOwnedChange}
          stateOwned={governmentOwned}
          handleStateOwnedChange={handleStateOwnedChange}
          privatelyOwned={privatelyOwned}
          handlePrivatelyOwnedChange={handlePrivatelyOwnedChange}
          beneficialOwner={beneficialOwner}
          handleBeneficialOwnerChange={handleBeneficialOwnerChange}
          dateOfBirth={dateOfBirth}
          handleDateOfBirthChange={handleDateOfBirthChange}
        />
        <CustomInputField
          enableDescription={true}
          description="% of the Entity’s total shares composed of bearer shares"
          descriptionTextColor="text.main"
          placeholder=""
          value={percBearerShares}
          mandatory={true}
          type="number"
          disabled={false}
          fullWidth={false}
          className="ownership-input"
          onChange={handleEntitySharesChange}
          error={false}
          errorText=""
        />
        <Stack spacing={2}>
          <CustomCheckbox
            value={branchesUnderOblCheck}
            onChange={handleOblDetailsCheckChange}
            text="Does the Entity, or any of its branches, operate under an Offshore Banking Licence (OBL)?"
          />
          <Stack pl="48px">
            <Typography fontSize="16px">If yes provide details.</Typography>
            <TextField
              multiline
              disabled={!branchesUnderOblCheck}
              rows={4}
              value={branchesUnderObl}
              className="ownership-multiline ownership-input-root"
              onChange={handleOblDetailsChange}
            />
          </Stack>
        </Stack>
        <Stack spacing={2}>
          <CustomCheckbox
            value={virtualBankLicenceCheck}
            onChange={handleVirtualBankLicenceCheckChange}
            text="Does the bank have a Virtual Bank Licence or provide services only through online channels?"
          />
          <CustomInputField
            enableDescription={true}
            description="Name of Primary financial regulator/supervisory authority"
            descriptionTextColor="text.main"
            placeholder=""
            value={primaryFinancialRegulator}
            mandatory={true}
            type="text"
            disabled={false}
            fullWidth={false}
            className="ownership-input ownership-input-root"
            onChange={handleNamePrimaryChange}
            error={false}
            errorText=""
          />
          <CustomInputField
            enableDescription={true}
            description="Provide Legal Entity Identifier (LEI), if available"
            descriptionTextColor="text.main"
            placeholder=""
            value={legalEntityIdentifier}
            mandatory={true}
            type="text"
            disabled={false}
            fullWidth={false}
            className="ownership-input ownership-input-root"
            onChange={handleLegalEntityIdChange}
            error={false}
            errorText=""
          />
          <CustomInputField
            enableDescription={true}
            description="Provide the Full Legal Name of the of ultimate parent (if different from Entity completing DDQ)"
            descriptionTextColor="text.main"
            placeholder=""
            value={ultParentLegalName}
            mandatory={true}
            type="text"
            disabled={false}
            fullWidth={false}
            className="ownership-input ownership-input-root"
            onChange={handleLegalNameChange}
            error={false}
            errorText=""
          />
          <CustomInputField
            enableDescription={true}
            description="Jurisdiction of licensing authority and regulator of ultimate parent"
            descriptionTextColor="text.main"
            placeholder=""
            value={licensingAuthorityJurisdiction}
            mandatory={true}
            type="text"
            disabled={false}
            fullWidth={false}
            className="ownership-input ownership-input-root"
            onChange={handleJurisdictionChange}
            error={false}
            errorText=""
          />
        </Stack>
        <BusinessArea
          retail={retailBanking}
          handleRetailChange={handleRetailChange}
          privateB={privateBanking}
          handlePrivateChange={handlePrivateChange}
          commercial={commercialBanking}
          handleCommercialChange={handleCommercialChange}
          transactional={transactionalBanking}
          handleTransactionalChange={handleTransactionalChange}
          investment={investmentBanking}
          handleInvestmentChange={handleInvestmentChange}
          financial={financialMarketTrading}
          handleFinancialChange={handleFinancialChange}
          securities={securitiesService}
          handleSecuritiesChange={handleSecuritiesChange}
          broker={broker}
          handleBrokerChange={handleBrokerChange}
          multilateral={multilateralDevelopmentBank}
          handleMultilateralChange={handleMultilateralChange}
          wealth={wealthManager}
          handleWealthChange={handleWealthChange}
          other={other}
          handleOtherChange={handleOtherChange}
        />
        <Stack spacing={2}>
          <CustomCheckbox
            value={nonResidentCustomerCheck}
            onChange={handleNonResidentCustomerCheckChange}
            text={
              "Does the Entity have a significant (>10%) portfolio of non-resident customers or does it derive more thanks 10% of it revenue from non-resident customers? (non-resident is non-UK tax resident)."
            }
          />
          <Stack pl="48px">
            <Typography fontSize="16px">
              If yes, provide top 5 countries of customer residency.
            </Typography>
            <TextField
              multiline
              disabled={!nonResidentCustomerCheck}
              rows={4}
              value={nonResidentCustomerCountries}
              className="ownership-multiline ownership-input-root"
              onChange={handleCustomersCountriesChange}
            />
          </Stack>
        </Stack>
        <Box width={"400px"}>
          <CustomSelectField
            enableDescription
            description="Number of employee"
            placeholder="Selection"
            value={numEmployee}
            displayEmpty
            fullWidth={true}
            className="ownership-input ownership-input-root"
            onChange={handleNumEmployeeChange}
            descriptionTextColor={"text.main"}
            mandatory={false}
            disabled={false}
            error={false}
            errorText={""}
          >
            {getEnumKeys(EmployeesNumber).map((key) => {
              return (
                <MenuItem key={key} value={EmployeesNumber[key]}>
                  {EmployeesNumberUtils.prettyToString(EmployeesNumber[key])}
                </MenuItem>
              );
            })}
          </CustomSelectField>
        </Box>
        <Box width={"400px"}>
          <CustomSelectField
            enableDescription
            description="Total assets"
            placeholder="Selection"
            value={assets}
            displayEmpty
            fullWidth={true}
            className="ownership-input ownership-input-root"
            onChange={handleTotAssetsChange}
            descriptionTextColor={"text.main"}
            mandatory={false}
            disabled={false}
            error={false}
            errorText={""}
          >
            {getEnumKeys(TotalAssetsValue).map((key) => {
              return (
                <MenuItem key={key} value={TotalAssetsValue[key]}>
                  {TotalAssetsValueUtils.prettyToString(TotalAssetsValue[key])}
                </MenuItem>
              );
            })}
          </CustomSelectField>
        </Box>
        <ResponseConfirmationCheck
          handleResponsesAreRepresentativechange={
            handleResponsesRepresentativeOfAllLeBranchesCheck
          }
          responsesAreRepresentative={
            responsesRepresentativeOfAllLeBranchesCheck
          }
          clarifications={clarifyDifferences}
          handleClarificationsChange={handleClarificationsChange}
          otherInfo={additionalInformation}
          handleOtherInfoChange={handleOtherInfoChange}
        />
      </Stack>
      <Stack
        spacing={1}
        width="100%"
        direction="row"
        mb="64px"
        justifyContent="end"
      >
        <CustomButton variant="outlined" onClick={handleOnSaveDraft}>
          <Typography px="16px" py="12px" fontWeight="500">
            Save draft
          </Typography>
        </CustomButton>
        <CustomButton
          variant="contained"
          type="submit"
          disabled={
            (publiclyTraded && (exchange === "" || ticker === "")) ||
            (privatelyOwned &&
              (beneficialOwner === "" || dateOfBirth === "")) ||
            (!retailBanking &&
              !privateBanking &&
              !commercialBanking &&
              !transactionalBanking &&
              !investmentBanking &&
              !financialMarketTrading &&
              !securitiesService &&
              !broker &&
              !multilateralDevelopmentBank &&
              !wealthManager &&
              other === "") ||
            primaryFinancialRegulator === "" ||
            legalEntityIdentifier === "" ||
            ultParentLegalName === "" ||
            licensingAuthorityJurisdiction === "" ||
            percBearerShares === "" ||
            (!responsesRepresentativeOfAllLeBranchesCheck &&
              clarifyDifferences === "") ||
            assets === undefined ||
            numEmployee === undefined
          }
        >
          <Typography px="42px" py="12px" fontWeight="500">
            Next
          </Typography>
        </CustomButton>
      </Stack>
    </form>
  );
}

export default Ownership;
