import { Modal, Typography } from "@mui/material";
import { useState } from "react";
import { HyDidInfo, UserInfo } from "../../utils/types/interfaces";
import { TokenDetail } from "../../utils/mockup/tokenisation/getTokenDetail";
import BackButtonTitled from "../Base/BackButtonTitled";
import DefaultCard from "../Base/DefaultCard";
import PledgeBox from "./PledgeBox";
import CustomModalBox from "../Base/CustomModalBox";

export interface PledgeViewProps {
  userInfo: UserInfo;
  hyDidInfo: HyDidInfo;
  tokenDetails: TokenDetail;
  onClickBackButton: any;
}

enum SignStatus {
  NOT_SIGNED,
  SIGNED,
  SIGNING,
  ERROR,
}

const PledgeView = (props: PledgeViewProps) => {
  const [signed, setSigned] = useState(SignStatus.NOT_SIGNED);
  const [pledge, setPledge] = useState(false);

  const handleOnClickSign = () => {
    setSigned(SignStatus.SIGNING);
  };

  const handleOnClickPledge = () => {
    setPledge(true);
  };

  const handleOnClickSignInModal = () => {
    setSigned(SignStatus.SIGNED);
  };

  return (
    <>
      <BackButtonTitled
        section="Pledge"
        title={props.tokenDetails.asset}
        onClick={props.onClickBackButton}
      />
      <DefaultCard marginTop="32px">
        <PledgeBox
          username={props.userInfo.username}
          nftIdAddress={props.hyDidInfo.identity_nft_address}
          tokenDetails={props.tokenDetails}
          signed={signed === SignStatus.SIGNED}
          onClickSign={handleOnClickSign}
          onClickPledge={handleOnClickPledge}
        />
      </DefaultCard>
      <Modal
        open={signed === SignStatus.SIGNING}
        onClose={handleOnClickSignInModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomModalBox>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            DocuSign pop up
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Signing document...
          </Typography>
        </CustomModalBox>
      </Modal>

      <Modal
        open={pledge}
        onClose={props.onClickBackButton}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomModalBox>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Pledge asset
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Pledge will come in MVP.
          </Typography>
        </CustomModalBox>
      </Modal>
    </>
  );
};

export default PledgeView;
