export interface Asset {
  id: string;
  asset: string;
  price: number;
  units: number;
  value: number;
}

export function getAssets(): Asset[] {
  return [
    {
      id: "ASSET1",
      asset: "HyLabs 2Yr Fixed 3.5% Green Bond",
      price: 102.3,
      units: 100,
      value: 10_230,
    },
    {
      id: "ASSET2",
      asset: "Runtime 5Yr Variable SONIA+3% Bond",
      price: 80.3,
      units: 100,
      value: 8_030,
    },
    {
      id: "ASSET3",
      asset: "Runtime 3Yr Variable SONIA+3% Bond",
      price: 90,
      units: 10,
      value: 900,
    },
  ];
}
