import { TokenDetail } from "../../utils/mockup/tokenisation/getTokenDetail";
import BackButtonTitled from "../Base/BackButtonTitled";
import DefaultCard from "../Base/DefaultCard";
import { useState } from "react";
import TokenDetailsBox from "./TokenDetailsBox";
import PledgeView from "./PledgeView";
import { HyDidInfo, UserInfo } from "../../utils/types/interfaces";
import { UserCategory } from "../../utils/types/enums";

export interface TokenDetailsViewProps {
  tokenDetails: TokenDetail;
  onClickBackButton: any;
  userInfo: UserInfo | null;
  hyDidInfo: HyDidInfo | null;
}

const TokenDetailsView = (props: TokenDetailsViewProps) => {
  const [pledge, setPledge] = useState(false);

  const mockup = props.tokenDetails.mockup;

  const onClickPledge = () => {
    setPledge(true);
  };

  const handleOnClickBackInPledgeView = () => {
    setPledge(false);
  };

  const canPledge =
    props.hyDidInfo !== null && props.userInfo !== null
      ? (props.userInfo.category === UserCategory.ACCREDITED ||
          props.userInfo.category === UserCategory.PROFESSIONAL) &&
        !props.hyDidInfo.is_frozen &&
        props.tokenDetails.in_process
      : false;

  return (
    <>
      {pledge && canPledge ? (
        <>
          <PledgeView
            userInfo={props.userInfo!}
            tokenDetails={props.tokenDetails}
            hyDidInfo={props.hyDidInfo!}
            onClickBackButton={handleOnClickBackInPledgeView}
          />
        </>
      ) : (
        <>
          <BackButtonTitled
            section="Assets"
            title={props.tokenDetails.asset}
            onClick={props.onClickBackButton}
          />
          <DefaultCard marginTop="32px">
            <TokenDetailsBox
              tokenDetails={props.tokenDetails}
              canPledge={canPledge}
              onClickPledge={onClickPledge}
              mockup={mockup}
            />
          </DefaultCard>
        </>
      )}
    </>
  );
};

export default TokenDetailsView;
