import { Divider, IconButton, Stack, Typography } from "@mui/material";
import { ReactComponent as BackIcon } from "../../assets/images/arrow-left-circle.svg";
import theme from "../../theme";

export interface BackButtonTitledProps {
  section: string;
  title: string;
  onClick: any;
}

const BackButtonTitled = (props: BackButtonTitledProps) => {
  return (
    <Stack
      direction="row"
      divider={<Divider orientation="vertical" flexItem />}
      spacing={1}
    >
      <IconButton aria-label="back" onClick={props.onClick}>
        <BackIcon />
      </IconButton>
      <Stack direction="column">
        <Typography
          variant="body2"
          fontWeight={600}
          color={theme.palette.text.secondary}
        >
          {props.section}
        </Typography>
        <Typography variant="h4" color="black">
          {props.title}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default BackButtonTitled;
