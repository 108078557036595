import { Grid, Typography } from "@mui/material";
import DefaultCard from "../Base/DefaultCard";
import {
  FiatAsset,
  getFiatAssets,
} from "../../utils/mockup/wallet/getFiatAssets";
import { Asset, getAssets } from "../../utils/mockup/wallet/getAssets";
import FiatBox from "./Fiat/FiatBox";
import CryptoAssetsBox from "./CryptoAssets/CryptoAssetsBox";
import AssetsBox from "./Assets/AssetsBox";
import { currencyFormatter } from "../../utils/functions/currencyFormatter";
import { UserInfo } from "../../utils/types/interfaces";
import { UserCategory } from "../../utils/types/enums";

export interface DigitalWalletViewProps {
  userInfo: UserInfo;
  handleAssetDetailsOnClick: any;
}

const DigitalWalletView = (props: DigitalWalletViewProps) => {
  const assets =
    props.userInfo !== null &&
    props.userInfo !== undefined &&
    props.userInfo.category === UserCategory.RETAIL
      ? []
      : getAssets();

  const fiatAssets = getFiatAssets();
  var totalAssets = assets.reduce((a: number, b: Asset) => {
    return a + b.value;
  }, 0);

  totalAssets = fiatAssets.reduce((a: number, b: FiatAsset) => {
    return a + b.value;
  }, totalAssets);

  return (
    <Grid container spacing={4} width={"100%"}>
      <Grid item xs={12} sm={6}>
        <Typography variant="h4" color="black">
          {"Digital wallet"}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="h4" color="black" textAlign="right">
          {`Total assets: ${currencyFormatter(totalAssets)}`}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <DefaultCard title={"Fiat"}>
          <FiatBox fiatAssets={fiatAssets} />
        </DefaultCard>
      </Grid>
      <Grid item xs={12} sm={6}>
        <DefaultCard height="100% !important" title={"Crypto assets"}>
          <CryptoAssetsBox />
        </DefaultCard>
      </Grid>
      <Grid item xs={12}>
        <DefaultCard title={"Assets"}>
          <AssetsBox
            assets={assets}
            handleOnClickDetails={props.handleAssetDetailsOnClick}
          />
        </DefaultCard>
      </Grid>
    </Grid>
  );
};

export default DigitalWalletView;
