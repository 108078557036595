import React from "react";
import { Checkbox, Stack, Typography } from "@mui/material";

interface CustomCheckboxProps {
  value: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  text: string;
}

function CustomCheckbox(props: CustomCheckboxProps) {
  return (
    <Stack direction="row" spacing={1} alignItems="center" width="100%">
      <Checkbox checked={props.value} value={props.value} onChange={props.onChange} />
      <Typography>{props.text}</Typography>
    </Stack>
  );
}

export default CustomCheckbox;
