import { Grid, MenuItem, SelectChangeEvent, Typography } from "@mui/material";
import CustomInputField from "../../Base/CustomInputField";
import CustomSelectField from "../../Base/CustomSelectField";
import { AvailableIssuer, EntityInfo } from "../../../utils/types/interfaces";

export interface OverviewSectionProps {
  dataGetEntityInfo: EntityInfo;
  dataGetAvailableIssuers: AvailableIssuer[];
  tokenName: string;
  handleTokenNameChange: React.ChangeEventHandler<HTMLInputElement>;
  isin: string;
  handleIsinChange: React.ChangeEventHandler<HTMLInputElement>;
  issuer: string;
  handleIssuerChange: (event: SelectChangeEvent<string>) => void;
  capitalStructure: string;
  handleCapitalStructureChange: (event: SelectChangeEvent<string>) => void;
  currency: string;
  handleCurrencyChange: (event: SelectChangeEvent<string>) => void;
  launchDate: string;
  handleLaunchDateChange: React.ChangeEventHandler<HTMLInputElement>;
  completionDate: string;
  handleCompletionDateChange: React.ChangeEventHandler<HTMLInputElement>;
}

const OverviewSection = (props: OverviewSectionProps) => {
  return (
    <>
      <Typography variant="h5">Overview</Typography>
      <Grid container columnSpacing={2} rowSpacing={2}>
        <Grid item xs={12} sm={6}>
          {/* token name */}
          <CustomInputField
            enableDescription={true}
            description="Token Name"
            descriptionTextColor="text.main"
            placeholder=""
            value={props.tokenName}
            mandatory={true}
            type="text"
            disabled={false}
            fullWidth={true}
            onChange={props.handleTokenNameChange}
            error={false}
            errorText=""
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* ISIN */}
          <CustomInputField
            enableDescription={true}
            description="ISIN / SEDOL"
            descriptionTextColor="text.main"
            placeholder=""
            value={props.isin}
            mandatory={true}
            type="text"
            disabled={false}
            fullWidth={true}
            onChange={props.handleIsinChange}
            error={false}
            errorText=""
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          {/* Product provider */}
          <CustomInputField
            enableDescription={true}
            description="Product Provider"
            descriptionTextColor="text.main"
            placeholder=""
            value={
              props.dataGetEntityInfo.ProfessionalInstitution
                ? props.dataGetEntityInfo.ProfessionalInstitution.company_name
                : ""
            }
            mandatory={true}
            type="text"
            disabled={true}
            fullWidth={true}
            onChange={props.handleTokenNameChange}
            error={false}
            errorText=""
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* Issuer */}
          <CustomSelectField
            enableDescription={true}
            description="Issuer"
            descriptionTextColor="text.main"
            placeholder=""
            value={props.issuer}
            mandatory={true}
            disabled={false}
            fullWidth={true}
            onChange={props.handleIssuerChange}
            error={false}
            errorText=""
          >
            {props.dataGetAvailableIssuers.map((value) => {
              return (
                <MenuItem
                  key={value.issuer_identifier}
                  value={value.issuer_identifier}
                >
                  {value.display_name + " (" + value.issuer_identifier + ")"}
                </MenuItem>
              );
            })}
          </CustomSelectField>
        </Grid>

        <Grid item xs={12} sm={6}>
          {/* capital structure */}
          <CustomSelectField
            enableDescription={true}
            description="Capital Structure"
            descriptionTextColor="text.main"
            placeholder=""
            value={props.capitalStructure}
            mandatory={true}
            disabled={false}
            fullWidth={true}
            onChange={props.handleCapitalStructureChange}
            error={false}
            errorText=""
          >
            <MenuItem value={"Fixed Income Bond"}>
              {"Fixed Income Bond"}
            </MenuItem>
          </CustomSelectField>
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* currency */}
          <CustomSelectField
            enableDescription={true}
            description="Currency"
            descriptionTextColor="text.main"
            placeholder=""
            value={props.currency}
            mandatory={true}
            disabled={false}
            fullWidth={true}
            onChange={props.handleCurrencyChange}
            error={false}
            errorText=""
          >
            <MenuItem value={"GBP"}>{"GBP"}</MenuItem>
          </CustomSelectField>
        </Grid>

        <Grid item xs={12} sm={6}>
          {/* launch date */}
          <CustomInputField
            enableDescription={true}
            description="Launch Date"
            descriptionTextColor="text.main"
            placeholder=""
            value={props.launchDate}
            mandatory={true}
            type="date"
            disabled={false}
            fullWidth={true}
            onChange={props.handleLaunchDateChange}
            error={false}
            errorText=""
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* completion date  */}
          <CustomInputField
            enableDescription={true}
            description="Completion Date"
            descriptionTextColor="text.main"
            placeholder=""
            value={props.completionDate}
            mandatory={true}
            type="date"
            disabled={false}
            fullWidth={true}
            onChange={props.handleCompletionDateChange}
            error={false}
            errorText=""
          />
        </Grid>
      </Grid>
    </>
  );
};

export default OverviewSection;
