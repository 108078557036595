import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, IconButton, Stack, Typography } from "@mui/material";
import arrow_left_circle from "../../assets/images/arrow-left-circle.svg";
import CustomInputField from "../../components/Base/CustomInputField";
import { emailRegex } from "../../utils/types/regexes";
import { resetPassword } from "../../routes/hynetwork/resetPassword";
import CreateAccountLink from "../../components/Intro/CreateAccountLink";
import CustomButton from "../../components/Base/CustomButton";

function ResetPassword() {
  const navigate = useNavigate();
  const handleClickBack = () => {
    navigate("/auth/login");
  };
  const [emailText, setEmailText] = useState("");
  const [emailTextError, setEmailTextError] = useState(false);

  const handleEmailChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setEmailText(event.target.value);
  };

  const handleClickReset: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    if (emailRegex.test(emailText)) {
      resetPassword({ identifier: emailText })
        .then(() => {
          navigate("/auth/login");
        })
        .catch(() => {
          setEmailTextError(true);
        });
    } else {
      setEmailTextError(true);
    }
  };

  return (
    <>
      <form onSubmit={handleClickReset}>
        <Stack spacing={4} mb="32px" width="416px">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <IconButton
                sx={{ position: "absolute" }}
                onClick={handleClickBack}
              >
                <img src={arrow_left_circle} alt="" />
              </IconButton>
              <Typography variant="h4" flexGrow="1" textAlign="center">
                Forgot password
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" textAlign="center">
                Enter the email address you used when <br />
                you joined and we’ll send you instructions <br />
                to reset your password.
              </Typography>
            </Grid>
          </Grid>
          <CustomInputField
            enableDescription={true}
            description="Email"
            descriptionTextColor="text.secondary"
            placeholder="Add your email address"
            value={emailText}
            mandatory={true}
            type="text"
            disabled={false}
            fullWidth={true}
            onChange={handleEmailChange}
            error={emailTextError}
            errorText="Please enter a valid email address"
          />
          <CustomButton
            variant="contained"
            fullWidth={true}
            type="submit"
            disabled={emailText === ""}
          >
            <Typography paddingY="12px" fontWeight="500">
              Send
            </Typography>
          </CustomButton>
        </Stack>
      </form>
      <CreateAccountLink />
    </>
  );
}

export default ResetPassword;
