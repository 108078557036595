import React, { createContext } from "react";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import QuestionnaireHeader from "../components/Questionnaire/QuestionnaireHeader";
import QuestionnaireFooter from "../components/Questionnaire/QuestionnaireFooter";
import "../style/questionnaire.css";

type ContextType = {
  setHeaderText?: (text: string) => void;
};

export const HeaderTextContext = createContext<ContextType>({});

function QuestionnaireRoot() {
  return (
    <Box minHeight="100vh" width="100%" position="relative">
      <QuestionnaireHeader />
      <Box marginX="auto" paddingX="64px" mt="32px" pb="82px">
        <main>
          <Outlet />
        </main>
      </Box>
      <QuestionnaireFooter />
    </Box>
  );
}

export default QuestionnaireRoot;
