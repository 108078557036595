export interface TokenDetail {
  id: string;
  asset: string;
  price: number;
  nominal: number;
  interest: string;
  type: string;
  term: string;
  issuer: string;
  prospectus_hash: Uint8Array;
  term_sheet_hash: Uint8Array;
  in_process: boolean;
  mockup: boolean;
}

const assets: TokenDetail[] = [
  {
    id: "ASSET1",
    asset: "HyLabs 2Yr Fixed 3.5% Green Bond",
    price: 102.3,
    nominal: 100_000_000,
    interest: "3.5% fixed",
    type: "Fixed",
    term: "2 Years",
    issuer: "HyLabs Limited",
    prospectus_hash: new Uint8Array([0]),
    term_sheet_hash: new Uint8Array([0]),
    in_process: false,
    mockup: true,
  },
  {
    id: "ASSET2",
    asset: "Runtime 5Yr Variable SONIA+3% Bond",
    price: 80.3,
    nominal: 200_000_000,
    interest: "SONIA+3%",
    type: "Variable",
    term: "5 Years",
    issuer: "Runtime",
    prospectus_hash: new Uint8Array([0]),
    term_sheet_hash: new Uint8Array([0]),
    in_process: false,
    mockup: true,
  },
  {
    id: "ASSET3",
    asset: "Runtime 3Yr Variable SONIA+3% Bond",
    price: 90,
    nominal: 100_000_000,
    interest: "SONIA+3%",
    type: "Variable",
    term: "3 Years",
    issuer: "Runtime",
    prospectus_hash: new Uint8Array([0]),
    term_sheet_hash: new Uint8Array([0]),
    in_process: false,
    mockup: true,
  },
  {
    id: "ASSET4",
    asset: "HyLabs 7Yr Fixed 3.5% Green Bond",
    price: 100,
    nominal: 100_000_000,
    interest: "3.5% fixed",
    type: "Fixed",
    term: "7 Years",
    issuer: "HyLabs Limited",
    prospectus_hash: new Uint8Array([0]),
    term_sheet_hash: new Uint8Array([0]),
    in_process: true,
    mockup: true,
  },
  {
    id: "ASSET5",
    asset: "Runtime 8Yr Variable SONIA+3% Bond",
    price: 90,
    nominal: 9_000_000,
    interest: "SONIA+3%",
    type: "Variable",
    term: "8 Years",
    issuer: "Runtime",
    prospectus_hash: new Uint8Array([0]),
    term_sheet_hash: new Uint8Array([0]),
    in_process: true,
    mockup: true,
  },
  {
    id: "ASSET6",
    asset: "HyLabs 15Yr Variable SONIA+3% Bond",
    price: 1000,
    nominal: 100_000_000,
    interest: "SONIA+3%",
    type: "Variable",
    term: "15 Years",
    issuer: "HyLabs Limited",
    prospectus_hash: new Uint8Array([0]),
    term_sheet_hash: new Uint8Array([0]),
    in_process: true,
    mockup: true,
  },
];

export function getTokenDetail(id: string): TokenDetail | undefined {
  return assets.find((value) => value.id === id);
}
