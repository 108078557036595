import React from "react";
import IntroHeader from "../components/Intro/IntroHeader";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import "../style/intro.css";
import LoginContextProvider from "../context/LoginContextProvider";
import IntroFooter from "../components/Intro/IntroFooter";

function AuthRoot() {
  return (
    <Box
      id="introroot-box"
      minWidth="100vw"
      width="max-content"
      minHeight="100vh"
      className="introBgGradient"
      display="flex"
      flexDirection="column"
    >
      <IntroHeader />
      <Box marginBottom="auto">
        <Box marginBottom="32px" marginTop="32px">
          <Box
            marginX="auto"
            bgcolor="white"
            width="fit-content"
            padding="32px"
            borderRadius="8px"
            height="auto"
            minHeight="100%"
            marginBottom="auto"
          >
            <LoginContextProvider>
              <Outlet />
            </LoginContextProvider>
          </Box>
        </Box>
      </Box>
      <IntroFooter />
    </Box>
  );
}

export default AuthRoot;
