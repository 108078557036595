import { useLoaderData, useNavigate } from "react-router-dom";
import { useRequestWithoutParams } from "../../hooks/useAxiosRequest";
import { LinearProgress } from "@mui/material";
import TokenisationPlatformView from "../../components/TokenisationPlatform/TokenisationPlatformView";
import TokenDetailsView from "../../components/TokenisationPlatform/TokenDetailsView";
import {
  TokenDetail,
  getTokenDetail,
} from "../../utils/mockup/tokenisation/getTokenDetail";
import { useState } from "react";
import { FixedIncomeBond, UserInfo } from "../../utils/types/interfaces";
import TokenIssuanceView from "../../components/TokenisationPlatform/TokenIssuanceView";
import { getHydidInfo } from "../../routes/walletid/hydid/getHydid";

const TokenisationPlatform = () => {
  const navigate = useNavigate();

  const userInfo = useLoaderData() as UserInfo;

  const [tokenDetails, setTokenDetails] = useState<TokenDetail | undefined>(
    undefined
  );

  const [tokenIssuance, setTokenIssuance] = useState(false);

  let {
    // cancel: cancelHydidInfo,
    data: dataHydidInfo,
    //error: errorHydidInfo,
    loaded: loadedHydidInfo,
  } = useRequestWithoutParams(getHydidInfo, { requireTimeout: true });

  if (userInfo === null) {
    navigate("/auth/login");
  }

  const handleMockupTokenDetailsOnClick = (token_id: string) => {
    const data = getTokenDetail(token_id);
    setTokenDetails(data);
  };

  const handleTokenDetailsOnClick = (token: FixedIncomeBond) => {
    const data: TokenDetail = {
      id: "" + token.name + token.issuer_id + token.product_provider_id,
      asset: token.name,
      price: token.division,
      nominal: token.notional,
      interest: token.coupon_rate,
      type: token.coupon_type,
      term: token.term,
      issuer: token.issuer_id,
      prospectus_hash: token.kiid_sha256_hash,
      term_sheet_hash: token.offer_document_sha256_hash,
      in_process: true,
      mockup: false,
    };
    setTokenDetails(data);
  };

  const handleOnClickBackButtonInTokenDetailsView = () => {
    setTokenDetails(undefined);
  };

  const handleTokenIssuanceOnClick = (asset_id: string) => {
    setTokenIssuance(true);
  };

  const handleOnClickBackButtonInTokenIssuanceView = () => {
    setTokenIssuance(false);
  };

  return (
    <>
      {!loadedHydidInfo ? (
        <LinearProgress sx={{ width: "100%" }} />
      ) : tokenDetails === undefined && !tokenIssuance ? (
        <TokenisationPlatformView
          dataGetUserInfo={userInfo}
          onClickNewIssuance={handleTokenIssuanceOnClick}
          onClickTokenAvailable={handleMockupTokenDetailsOnClick}
          onClickTokenInProcess={handleTokenDetailsOnClick}
          onClickTokenIssued={handleMockupTokenDetailsOnClick}
        />
      ) : tokenDetails !== undefined && !tokenIssuance ? (
        <TokenDetailsView
          tokenDetails={tokenDetails}
          userInfo={userInfo!}
          hyDidInfo={dataHydidInfo}
          onClickBackButton={handleOnClickBackButtonInTokenDetailsView}
        />
      ) : (
        <TokenIssuanceView
          dataGetUserInfo={userInfo!}
          onClickBackButton={handleOnClickBackButtonInTokenIssuanceView}
        />
      )}
    </>
  );
};

export default TokenisationPlatform;
