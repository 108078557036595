import React from "react";
import CustomRoundedRow from "../../Base/CustomRoundedRow";
import { Button, Stack, Typography } from "@mui/material";
import { ReactComponent as MissingIcon } from "../../../assets/images/gray-minus-circle.svg";
import { ReactComponent as DoneIcon } from "../../../assets/images/green-check-circle.svg";
import { ReactComponent as UpdateIcon } from "../../../assets/images/refresh-cw.svg";
import { ReactComponent as UploadIcon } from "../../../assets/images/white-upload.svg";
import { EntityDocument } from "../../../utils/types/interfaces";

interface UploadFileProps {
  file: EntityDocument | undefined;
  onChange: (event: any) => void;
  description: string;
}

function UploadFile(props: UploadFileProps) {
  return (
    <CustomRoundedRow disabled={props.file === undefined}>
      <Stack direction="row" spacing={1} width="100%" alignItems="center">
        {props.file === undefined ? <MissingIcon /> : <DoneIcon />}
        <Typography fontSize="18px" fontWeight="500" flexGrow={1}>
          {props.description}
        </Typography>
        <Button
          variant={props.file === undefined ? "contained" : "outlined"}
          color="primary"
          endIcon={
            props.file === undefined ? (
              <UploadIcon color="white" />
            ) : (
              <UpdateIcon />
            )
          }
          component="label"
        >
          {props.file === undefined ? "Upload" : "Update"}
          <input type="file" accept=".pdf" hidden onChange={props.onChange} />
        </Button>
      </Stack>
    </CustomRoundedRow>
  );
}

export default UploadFile;
