import React, { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import ActivationForm from "../../components/Intro/ActivationForm";
import arrow_left_circle from "../../assets/images/arrow-left-circle.svg";
import ActivationSuccess from "../../assets/images/ActivationSuccess.svg";
import theme from "../../theme";
import { getUserInfo } from "../../routes/hynetwork/getUser";
import { UserKind, UserOnboardingStatus } from "../../utils/types/enums";
import { getUserOnboardingStatus } from "../../routes/hynetwork/onboarding";

function Activation() {
  const navigate = useNavigate();
  const handleClickBack = () => {
    navigate("/auth/login");
  };

  const [pathname, setPathname] = useState<string | undefined>(undefined);

  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (success) {
      getUserInfo().then((value) => {
        switch (value.kind) {
          case UserKind.ADMIN:
            setPathname("/");
            break;
          case UserKind.INDIVIDUAL:
            setPathname("/auth/kyc");
            break;
          case UserKind.INSTITUTION:
            setPathname("/auth/kyb");
            break;
        }
      });
    } else {
      getUserOnboardingStatus().then((response) => {
        if (
          response.onboarding_status === UserOnboardingStatus.Onboarded ||
          response.onboarding_status === UserOnboardingStatus.NotRequired
        ) {
          navigate("/");
        }
        if (response.onboarding_status === UserOnboardingStatus.MissingData) {
          if (response.kind === UserKind.INDIVIDUAL) {
            navigate("/auth/KYC");
          }
          if (response.kind === UserKind.INSTITUTION) {
            navigate("/auth/KYB");
          }
        }
      });
    }
  }, [success, navigate]);

  const handleEmailChange = () => {
    console.log("change email");
  };

  const successForm = (
    <Stack spacing={4} pt="10px">
      <img src={ActivationSuccess} alt="ActivationSuccess" height="160px" />
      <Stack spacing={1} textAlign="center" pt="10px">
        <Typography fontSize="26px" fontWeight="500">
          Success!
        </Typography>
        <Typography>
          Your email has now been verified. Please <br />
          click on the link below to complete your ID <br />
          certification.
        </Typography>
      </Stack>
      <Button
        component={RouterLink}
        to={{
          pathname: pathname,
        }}
        variant="contained"
      >
        <Typography fontWeight="500" paddingY="12px">
          Verify your identity
        </Typography>
      </Button>
    </Stack>
  );

  return (
    <Box width="416px">
      {success ? (
        successForm
      ) : (
        <>
          <Box display="flex" mb="32px">
            <IconButton sx={{ position: "absolute" }} onClick={handleClickBack}>
              <img src={arrow_left_circle} alt="" />
            </IconButton>
            <Typography variant="h4" flexGrow="1" textAlign="center">
              Verification
            </Typography>
          </Box>
          <ActivationForm setSuccess={setSuccess} />
          <Divider />
          <Box mt="32px" mx="auto" width="fit-content">
            <Button variant="text" onClick={handleEmailChange}>
              <Typography
                textAlign="center"
                color={theme.palette.secondary.main}
              >
                Change Email address
              </Typography>
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}

export default Activation;
