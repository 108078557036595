import React from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import KYBContextProvider from "../context/KYBContextProvider";

function Root() {
  return (
    <>
      <Box minHeight="100vh">
          <KYBContextProvider>
              <Outlet />
          </KYBContextProvider>
      </Box>
    </>
  );
}

export default Root;
