import { Box, BoxProps, styled } from "@mui/material";
import { PropsWithChildren } from "react";

export enum CustomRoundedRowColor {
  Yellow = "rgba(255, 199, 55, 0.16)",
  Green = "rgba(54, 210, 0, 0.12)",
}

export interface CustomRoundedRowProps {
  disabled?: boolean;
  rowColor?: CustomRoundedRowColor;
}

const CustomBox = styled(Box)<BoxProps>(({ theme }) => ({
  width: "100%",
  height: "64px",
  padding: "16px",
  border: `1px solid ${theme.palette.grey.A200}`,
  borderRadius: "8px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  boxSizing: "border-box",
}));

const CustomRoundedRow = (props: PropsWithChildren<CustomRoundedRowProps>) => {
  return (
    <CustomBox
      bgcolor={
        props.disabled !== undefined && props.disabled
          ? "rgba(235, 235, 235, 0.5)"
          : props.rowColor
          ? props.rowColor
          : "white"
      }
    >
      {props.children}
    </CustomBox>
  );
};

export default CustomRoundedRow;
