import {
  Grid,
  InputAdornment,
  MenuItem,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import CustomInputField from "../../Base/CustomInputField";
import CustomSelectField from "../../Base/CustomSelectField";

export interface TermsSectionProps {
  term: number;
  handleTermChange: React.ChangeEventHandler<HTMLInputElement>;
  type: string;
  handleTypeChange: (event: SelectChangeEvent<string>) => void;
  coupon: number;
  handleCouponChange: React.ChangeEventHandler<HTMLInputElement>;
  referenceRate: string;
  handleReferenceRateChange: (event: SelectChangeEvent<string>) => void;
  couponFrequency: number;
  handleCouponFrequencyChange: React.ChangeEventHandler<HTMLInputElement>;
}

const TermsSection = (props: TermsSectionProps) => {
  return (
    <>
      <Typography variant="h5">Terms</Typography>
      <Grid container columnSpacing={2} rowSpacing={2}>
        <Grid item xs={12} sm={6}>
          {/* term */}
          <CustomInputField
            enableDescription={true}
            description="Term"
            descriptionTextColor="text.main"
            placeholder=""
            value={props.term}
            mandatory={true}
            type="number"
            disabled={false}
            fullWidth={true}
            onChange={props.handleTermChange}
            error={false}
            errorText=""
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">Year</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* fixed / variable */}
          <CustomSelectField
            enableDescription={true}
            description="Fixed / Variable"
            descriptionTextColor="text.main"
            placeholder=""
            value={props.type}
            mandatory={true}
            disabled={false}
            fullWidth={true}
            onChange={props.handleTypeChange}
            error={false}
            errorText=""
          >
            <MenuItem value={"Fixed"}>{"Fixed"}</MenuItem>
            <MenuItem value={"Variable"}>{"Variable"}</MenuItem>
          </CustomSelectField>
        </Grid>

        <Grid item xs={12} sm={6}>
          {/* coupon */}
          <CustomInputField
            enableDescription={true}
            description="Coupon"
            descriptionTextColor="text.main"
            placeholder=""
            value={props.coupon.toString()}
            mandatory={true}
            type="number"
            disabled={false}
            fullWidth={true}
            onChange={props.handleCouponChange}
            error={false}
            errorText=""
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* reference */}
          <CustomSelectField
            enableDescription={true}
            description="Reference rate (if required)"
            descriptionTextColor="text.main"
            placeholder=""
            value={props.referenceRate}
            mandatory={false}
            disabled={false}
            fullWidth={true}
            onChange={props.handleReferenceRateChange}
            error={false}
            errorText=""
          >
            <MenuItem value={"LIBOR"}>{"LIBOR"}</MenuItem>
          </CustomSelectField>
        </Grid>

        <Grid item xs={12} sm={6}>
          {/* coupon frequency */}
          <CustomSelectField
            enableDescription={true}
            description="Coupon frequency"
            descriptionTextColor="text.main"
            placeholder=""
            value={props.couponFrequency}
            mandatory={true}
            disabled={false}
            fullWidth={true}
            onChange={props.handleCouponFrequencyChange}
            error={false}
            errorText=""
          >
            <MenuItem value={0}>None</MenuItem>
            <MenuItem value={1}>Monthly</MenuItem>
            <MenuItem value={3}>Quarterly</MenuItem>
            <MenuItem value={6}>Half-Yearly</MenuItem>
            <MenuItem value={12}>Annually</MenuItem>
          </CustomSelectField>
        </Grid>
      </Grid>
    </>
  );
};

export default TermsSection;
