import axios, { AxiosResponse } from "axios";
import {
  HyLabsResponse,
  ProductDocument,
  ProductDocumentRequest,
} from "../../utils/types/interfaces";
import { HYNETWORK_AXIOS_CONFIG_JSON_CONTENT } from "../util";

export function getProductDocumentDetails(
  data: ProductDocumentRequest
): Promise<ProductDocument> {
  return axios
    .post(
      `/hy-tokenisation/documents`,
      data,
      HYNETWORK_AXIOS_CONFIG_JSON_CONTENT
    )
    .then((response: AxiosResponse): ProductDocument => {
      const hynetwork_response: HyLabsResponse = response.data;
      const product_doc: ProductDocument = hynetwork_response.data;
      return product_doc;
    })
    .catch((err) => {
      throw err;
    });
}
