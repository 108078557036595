import axios, { AxiosResponse } from "axios";
import {
  HyLabsResponse,
  MintFixedIncomeBond,
  PreIssuanceFixedIncomeBond,
  PrepareFixedIncomeBond,
} from "../../../utils/types/interfaces";
import { HYNETWORK_AXIOS_CONFIG_JSON_CONTENT } from "../../util";

export function prepareFixedIncomeBond(
  data: PrepareFixedIncomeBond
): Promise<string> {
  return axios
    .post(`/hy-tokenisation/fibt`, data, HYNETWORK_AXIOS_CONFIG_JSON_CONTENT)
    .then((response: AxiosResponse): string => {
      const hynetwork_response: HyLabsResponse = response.data;
      const fixed_income_bond_id: string = hynetwork_response.data;
      return fixed_income_bond_id;
    })
    .catch((err) => {
      throw err;
    });
}

export function mintFixedIncomeBond(
  data: MintFixedIncomeBond
): Promise<string> {
  return axios
    .post(
      `/hy-tokenisation/fibt/mint`,
      data,
      HYNETWORK_AXIOS_CONFIG_JSON_CONTENT
    )
    .then((response: AxiosResponse): string => {
      const hynetwork_response: HyLabsResponse = response.data;
      const fixed_income_bond_address: string = hynetwork_response.data;
      return fixed_income_bond_address;
    })
    .catch((err) => {
      throw err;
    });
}

export function getPreIssuanceFixedIncomeBond(): Promise<PreIssuanceFixedIncomeBond> {
  return axios
    .get(`/hy-tokenisation/fibt`, HYNETWORK_AXIOS_CONFIG_JSON_CONTENT)
    .then((response: AxiosResponse): PreIssuanceFixedIncomeBond => {
      const hynetwork_response: HyLabsResponse = response.data;
      const pre_issuance_fib: PreIssuanceFixedIncomeBond =
        hynetwork_response.data;
      return pre_issuance_fib;
    })
    .catch((err) => {
      throw err;
    });
}
