import axios, { AxiosResponse } from "axios";
import { GetUserStatus, HyLabsResponse, UserInfo } from "../../utils/types/interfaces";
import { HYNETWORK_AXIOS_CONFIG_JSON_CONTENT } from "../util";


export function getUserInfo(): Promise<UserInfo> {
  return axios
    .get("/hy-network/user/info", HYNETWORK_AXIOS_CONFIG_JSON_CONTENT)
    .then((response: AxiosResponse): UserInfo => {
      const hynetwork_response: HyLabsResponse = response.data;
      const user_info: UserInfo = hynetwork_response.data;
      return user_info;
    })
    .catch((err) => {
      throw err;
    });
}

export function getUserStatus(): Promise<GetUserStatus> {
  return axios
    .get("/hy-network/user/status", HYNETWORK_AXIOS_CONFIG_JSON_CONTENT)
    .then((response: AxiosResponse): GetUserStatus => {
      const hynetwork_response: HyLabsResponse = response.data;
      const user_status: GetUserStatus = hynetwork_response.data;
      return user_status;
    })
    .catch((err) => {
      throw err;
    });
}
