import { dataURLtoBase64 } from "./dataURLtoBase64";

const PDFJS = require("pdfjs-dist");
PDFJS.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${PDFJS.version}/build/pdf.worker.min.js`;

export function pdfToImgBase64(buffer: ArrayBuffer): Promise<string> {
  const uri = URL.createObjectURL(new Blob([buffer]));
  const canvas = document.createElement("canvas");
  canvas.setAttribute("className", "canv");
  document.querySelector(".canv");
  return PDFJS.getDocument({ url: uri })
    .promise.then((pdf_doc: any) => {
      return pdf_doc
        .getPage(1)
        .then((page: any) => {
          var viewport = page.getViewport({ scale: 1 });
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          var render_context = {
            canvasContext: canvas.getContext("2d"),
            viewport: viewport,
          };
          return page
            .render(render_context)
            .promise.then(() => {
              const val = canvas.toDataURL("image/png");
              const base64img = dataURLtoBase64(val);
              return base64img;
            })
            .catch((err: any) => {
              throw err;
            });
        })
        .catch((err: any) => {
          throw err;
        });
    })
    .catch((err: any) => {
      throw err;
    });
}
