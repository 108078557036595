import React, { useState } from "react";

interface LoginContextType {
  userIdentifier: string;
  setUserIdentifier?: (identifier: string) => void;
  cleanUserIdentifier?: () => void;
}

function getDefaultState() {
  const userIdentifier = localStorage.getItem("userIdentifier");
  return userIdentifier ? userIdentifier : "";
}

const defaultState: LoginContextType = {
  userIdentifier: getDefaultState(),
};

export const LoginContext = React.createContext<LoginContextType>(defaultState);

interface LoginProviderProps {
  children: React.ReactNode;
}

const LoginContextProvider = ({ children }: LoginProviderProps) => {
  const [userIdentifier, setUserIdentifier] = useState(
    defaultState.userIdentifier
  );

  const handleSetUserIdentifier = (identifier: string) => {
    setUserIdentifier(identifier);
    localStorage.setItem("userIdentifier", identifier);
  };

  const handleCleanUserIdentifier = () => {
    localStorage.removeItem("userIdentifier");
  };

  return (
    <LoginContext.Provider
      value={{
        userIdentifier: userIdentifier,
        setUserIdentifier: handleSetUserIdentifier,
        cleanUserIdentifier: handleCleanUserIdentifier,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export default LoginContextProvider;
