import axios, { AxiosResponse } from "axios";
import { HyLabsResponse, UserRegistration } from "../../utils/types/interfaces";
import {
  HyLabsResponseDataUserId,
  HYNETWORK_AXIOS_CONFIG_NO_CREDENTIAL_JSON_CONTENT,
} from "../util";


export function registration(
  data: UserRegistration
): Promise<HyLabsResponseDataUserId> {
  return axios
    .post(
      "/hy-network/registration",
      data,
      HYNETWORK_AXIOS_CONFIG_NO_CREDENTIAL_JSON_CONTENT
    )
    .then((response: AxiosResponse): HyLabsResponseDataUserId => {
      const hynetwork_response: HyLabsResponse = response.data;
      const reg_response: HyLabsResponseDataUserId = hynetwork_response.data;
      return reg_response;
    })
    .catch((err) => {
      throw err;
    });
}
