import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Modal,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { UserInfo } from "../../utils/types/interfaces";
import BackButtonTitled from "../Base/BackButtonTitled";
import DigitalIDBox from "../Dashboard/Common/DigitalIDBox";
import DefaultCard from "../Base/DefaultCard";
import { MapEntityDocumentLight } from "../../utils/types/types";
import { useRequestWithParams } from "../../hooks/useAxiosRequest";
import { getUserDocumentsLight } from "../../routes/walletid/hydid/document";
import { getHistoryLogs } from "../../utils/mockup/admin/getHistoryLogs";
import CustomTable from "../Base/CustomTable";
import AssetsBox from "../DigitalWallet/Assets/AssetsBox";
import { getAssets } from "../../utils/mockup/wallet/getAssets";
import { useEffect, useState } from "react";
import {
  AssetDetail,
  getAssetDetails,
} from "../../utils/mockup/wallet/getAssetDetails";
import AssetDetailsView from "../DigitalWallet/Assets/AssetDetailsView";
import {
  GetUserHyDidInfo,
  getUserHydidInfo,
  setFreezeStatus,
} from "../../routes/admin/hydid";
import CustomModalBox from "../Base/CustomModalBox";
import { UserCategory, UserOnboardingStatus } from "../../utils/types/enums";

export interface UserDetailsViewProps {
  user: UserInfo;
  onClickBackButton: any;
}

const UserDetailsView = (props: UserDetailsViewProps) => {
  const [assetDetails, setAssetDetails] = useState<AssetDetail | undefined>(
    undefined
  );

  const [hyDidInfo, setHyDidInfo] = useState<GetUserHyDidInfo | undefined>(
    undefined
  );
  const [loadedHyDidInfo, setLoadedHyDidInfo] = useState(false);

  const [openFreezeModal, setOpenFreezeModal] = useState(false);

  const [isFrozen, setIsFrozen] = useState<boolean | undefined>(undefined);

  const {
    //cancel: cancelGetDoc,
    data: dataGetDoc,
    //error: errorGetDoc,
    loaded: loadedGetDoc,
  } = useRequestWithParams(getUserDocumentsLight, props.user.id);

  const userHasHyDid =
    props.user.onboarding_status === UserOnboardingStatus.Onboarded;

  const showAssets =
    props.user.onboarding_status === UserOnboardingStatus.Onboarded &&
    props.user.category !== UserCategory.RETAIL;

  useEffect(() => {
    if (userHasHyDid) {
      getUserHydidInfo(props.user.id)
        .then((value) => {
          setHyDidInfo(value);
        })
        .finally(() => setLoadedHyDidInfo(true));
    }
  });

  if (isFrozen === undefined && hyDidInfo) {
    setIsFrozen(hyDidInfo.info.is_frozen);
  }

  const documents: MapEntityDocumentLight = {};
  if (dataGetDoc) {
    dataGetDoc.documents.forEach((obj) => (documents[obj.kind] = obj));
  }

  const historyLog = userHasHyDid ? getHistoryLogs() : [getHistoryLogs()[0]];
  const assets = showAssets ? getAssets() : [];

  const handleAssetDetailsOnClick = (asset_id: string) => {
    const data = getAssetDetails(asset_id);
    setAssetDetails(data);
  };

  const handleOnClickBackButtonInAssetDetailsView = () => {
    setAssetDetails(undefined);
  };

  const statusButton = (
    <Box
      alignItems={"center"}
      display={"flex"}
      sx={{
        backgroundColor: isFrozen ? "#9A9A9A" : "#36D200",
        padding: "12px 16px",
        height: "fit-content",
        borderRadius: "8px",
      }}
    >
      <Typography variant="body1" color={"white"}>
        {isFrozen ? "Frozen" : "Live"}
      </Typography>
    </Box>
  );

  const handleChangeFreezeStatus = () => {
    setOpenFreezeModal(true);
  };

  const handleOnClickFreezeUnfreeze = () => {
    const freeze = !isFrozen;
    setFreezeStatus({
      user_id: props.user.id,
      freeze,
    }).then((value) => {
      setIsFrozen(freeze);
      setOpenFreezeModal(false);
    });
  };

  return (
    <>
      {assetDetails === undefined ? (
        <>
          <Grid container rowSpacing={4} columnSpacing={4}>
            <Grid item xs={12}>
              <Stack direction="row" justifyContent={"space-between"}>
                <BackButtonTitled
                  section={"User"}
                  title={props.user.username}
                  onClick={props.onClickBackButton}
                />
                {loadedHyDidInfo && userHasHyDid ? (
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    {statusButton}
                    <Button
                      variant="outlined"
                      sx={{
                        padding: "12px 16px",
                        height: "fit-content",
                      }}
                      onClick={handleChangeFreezeStatus}
                    >
                      <Typography variant="body1">
                        {isFrozen ? "Unfreeze" : "Freeze"}
                      </Typography>
                    </Button>
                  </Stack>
                ) : userHasHyDid ? (
                  <LinearProgress />
                ) : (
                  <Box
                    alignItems={"center"}
                    display={"flex"}
                    sx={{
                      backgroundColor: "#9A9A9A",
                      padding: "12px 16px",
                      height: "fit-content",
                      borderRadius: "8px",
                    }}
                  >
                    <Typography variant="body1" color={"white"}>
                      Not Onboarded Yet
                    </Typography>
                  </Box>
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} sm={4}>
              {loadedGetDoc ? (
                <DigitalIDBox
                  documents={documents}
                  userKind={props.user.kind}
                  userCategory={props.user.category}
                />
              ) : (
                <LinearProgress />
              )}
            </Grid>
            <Grid item xs={12} sm={8}>
              <DefaultCard
                title="History Log"
                height={"100%"}
                justifyContent={"flex-start !important"}
              >
                <CustomTable>
                  <TableBody>
                    {historyLog.map((value) => {
                      return (
                        <TableRow key={value.id}>
                          <TableCell>
                            <Typography variant="h6">
                              {value.operation}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body1">
                              {value.date}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </CustomTable>
              </DefaultCard>
            </Grid>

            <Grid item xs={12}>
              <DefaultCard title={"Digital Assets"}>
                <AssetsBox
                  assets={assets}
                  handleOnClickDetails={handleAssetDetailsOnClick}
                />
              </DefaultCard>
            </Grid>
          </Grid>

          <Modal
            open={openFreezeModal}
            onClose={() => setOpenFreezeModal(false)}
          >
            <CustomModalBox width={"400px !important"}>
              <Typography id="modal-modal-title" variant="h4" component="h2">
                {isFrozen ? "Unfreeze user" : "Freeze user"}
              </Typography>
              <Typography
                textAlign={"center"}
                id="modal-modal-description"
                sx={{ mt: 2 }}
              >
                {"Are you sure you want to " +
                  (isFrozen ? "unfreeze" : "freeze") +
                  " " +
                  props.user.username +
                  "?"}
              </Typography>
              <Button
                variant="contained"
                onClick={handleOnClickFreezeUnfreeze}
                fullWidth
                sx={{ marginTop: "32px" }}
              >
                {isFrozen ? "Unfreeze" : "Freeze"}
              </Button>
              <Button
                variant="outlined"
                onClick={() => setOpenFreezeModal(false)}
                fullWidth
                sx={{ marginTop: "16px" }}
              >
                Cancel
              </Button>
            </CustomModalBox>
          </Modal>
        </>
      ) : (
        <AssetDetailsView
          asseDetails={assetDetails}
          onClickBackButton={handleOnClickBackButtonInAssetDetailsView}
        />
      )}
    </>
  );
};

export default UserDetailsView;
