import { PersonOfSignificantControl } from "../../types/interfaces";

export function getPersonOfSignificantControl(): PersonOfSignificantControl[] {
  return [
    {
      id: "1",
      name: "Ethan Lee",
      email_address: "e.lee@bluecloud.io",
      verified: true,
    },
    {
      id: "2",
      name: "Mavis Greene",
      email_address: "m.greene@bluecloud.io",
      verified: true,
    },
    {
      id: "3",
      name: "Amber Winters",
      email_address: "a.winters@bluecloud.io",
      verified: false,
    },
  ];
}
