import {
  Box,
  Button,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CustomTable from "../../Base/CustomTable";
import { currencyFormatter } from "../../../utils/functions/currencyFormatter";

const CryptoAssetsBox = () => {
  return (
    <Stack
      direction="column"
      spacing={4}
      width="100%"
      height="100%"
      justifyContent="space-between"
    >
      <CustomTable>
        <TableHead>
          <TableRow>
            <TableCell>Asset</TableCell>
            <TableCell>Available</TableCell>
            <TableCell>Pledged</TableCell>
            <TableCell>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell colSpan={4}>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                padding="10px"
                gap="10px"
                width="fit-content"
                sx={{ backgroundColor: "rgba(238, 80, 90, 0.12)" }}
                borderRadius="8px"
                marginX="auto"
              >
                <Typography variant="h5" color="black">
                  {"Coming in stage 2"}
                </Typography>
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      </CustomTable>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="end"
        alignItems="center"
      >
        <Typography variant="h5" color="black">
          {`Total crypto: ${currencyFormatter(0)}`}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          component="label"
          disabled={true}
        >
          Deposit
        </Button>
        <Button
          variant="contained"
          color="primary"
          component="label"
          disabled={true}
        >
          Withdraw
        </Button>
      </Stack>
    </Stack>
  );
};

export default CryptoAssetsBox;
