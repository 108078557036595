import { LinearProgress } from "@mui/material";
import { UserCategory, UserKind } from "../../utils/types/enums";
import { MapEntityDocumentLight } from "../../utils/types/types";
import { useRequestWithoutParams } from "../../hooks/useAxiosRequest";
import { getUserDocumentsLight } from "../../routes/walletid/hydid/document";
import { useLoaderData, useNavigate } from "react-router-dom";
import { getHydidInfo } from "../../routes/walletid/hydid/getHydid";
import { getPreIssuanceFixedIncomeBond } from "../../routes/hytokenisation/fibt/fixedIncomeBondToken";
import AdminView from "../../components/Dashboard/AdminView";
import IndividualView from "../../components/Dashboard/IndividualView";
import InstitutionView from "../../components/Dashboard/InstitutionView";
import {
  getAssets,
  getRetailAssets,
} from "../../utils/mockup/dashboard/getAssets";
import { useEffect, useState } from "react";
import {
  HyDidInfo,
  UserDocuments,
  UserInfo,
} from "../../utils/types/interfaces";

const Dashboard = () => {
  const navigate = useNavigate();

  const [getDoc, setGetDoc] = useState<UserDocuments | null>(null);
  const [hydidInfo, setHydidInfo] = useState<HyDidInfo | null>(null);
  const [loadedHydidInfo, setLoadedHydidInfo] = useState(false);

  const userInfo = useLoaderData() as UserInfo;

  useEffect(() => {
    if (
      userInfo !== undefined &&
      userInfo !== null &&
      userInfo.kind !== UserKind.ADMIN
    ) {
      getUserDocumentsLight(userInfo.id).then((value) => setGetDoc(value));
      getHydidInfo()
        .then((value) => {
          setHydidInfo(value);
          setLoadedHydidInfo(true);
        })
        .catch((err: any) => setLoadedHydidInfo(true));
    }
  }, [userInfo]);

  const {
    //cancel: cancelGetTokenInProcess,
    data: dataGetTokenInProcess,
    //error: errorGetTokenInProcess,
    loaded: loadedGetTokenInProcess,
  } = useRequestWithoutParams(getPreIssuanceFixedIncomeBond);

  const documents: MapEntityDocumentLight = {};
  if (getDoc !== null) {
    getDoc.documents.forEach((obj) => (documents[obj.kind] = obj));
  }

  const handleOnClickManageDigitalWalletBox = () => {
    navigate("/digital-wallet");
  };

  const handleOnClickViewMoreDigitalIdBox = () => {
    navigate("/digital-id");
  };

  const handleOnClickViewTokenPlatformBox = () => {
    navigate("/tokenisation-platform");
  };

  const assets =
    userInfo !== null &&
    userInfo !== undefined &&
    userInfo.category === UserCategory.RETAIL
      ? getRetailAssets()
      : getAssets();

  return (
    <>
      {userInfo !== null && userInfo !== undefined ? (
        userInfo.kind === UserKind.ADMIN ? (
          <AdminView
            dataGetTokenInProcess={dataGetTokenInProcess}
            loadedGetTokenInProcess={loadedGetTokenInProcess}
          />
        ) : userInfo.kind === UserKind.INDIVIDUAL ? (
          <IndividualView
            dataGetTokenInProcess={dataGetTokenInProcess}
            loadedGetTokenInProcess={loadedGetTokenInProcess}
            assets={assets}
            documents={documents}
            userInfo={userInfo}
            dataGetHyDidInfo={hydidInfo}
            loadedGetHyDidInfo={loadedHydidInfo}
            handleOnClickManageDigitalWalletBox={
              handleOnClickManageDigitalWalletBox
            }
            handleOnClickViewMoreDigitalIdBox={
              handleOnClickViewMoreDigitalIdBox
            }
            handleOnClickViewTokenPlatformBox={
              handleOnClickViewTokenPlatformBox
            }
          />
        ) : (
          <InstitutionView
            dataGetTokenInProcess={dataGetTokenInProcess}
            loadedGetTokenInProcess={loadedGetTokenInProcess}
            assets={assets}
            documents={documents}
            userInfo={userInfo}
            dataGetHyDidInfo={hydidInfo}
            loadedGetHyDidInfo={loadedHydidInfo}
            handleOnClickManageDigitalWalletBox={
              handleOnClickManageDigitalWalletBox
            }
            handleOnClickViewMoreDigitalIdBox={
              handleOnClickViewMoreDigitalIdBox
            }
            handleOnClickViewTokenPlatformBox={
              handleOnClickViewTokenPlatformBox
            }
          />
        )
      ) : (
        <LinearProgress sx={{ width: "100%" }} />
      )}
    </>
  );
};

export default Dashboard;
