import { Stack, Typography } from "@mui/material";
import { DocumentType } from "../../../../utils/types/enums";
import BackButtonTitled from "../../../Base/BackButtonTitled";
import { EntityDocumentLight } from "../../../../utils/types/interfaces";
import ManageDocument from "./ManageDocument";
import { upgradeAccreditedStatus } from "../../../../routes/walletid/hydid/accreditedStatus";
import { HyLabsResponseDataUserId } from "../../../../routes/util";

export interface DocumentViewProps {
  documents?: EntityDocumentLight[];
  documentType: DocumentType;
  onClickBackButton: any;
  handleRefreshDocuments: any;
}

const DocumentView = (props: DocumentViewProps) => {
  const documentsSaved: DocumentType[] = [];

  const associatedDocuments =
    DocumentType.getAssociatedDocumentTypeIncludingSelf(props.documentType);

  const missingDocumentsType: DocumentType[] = [];
  const existingDocuments: EntityDocumentLight[] = [];

  if (props.documents !== undefined) {
    for (let i = 0; i < associatedDocuments.length; i++) {
      const index = props.documents.findIndex(
        (val: EntityDocumentLight) => associatedDocuments[i] === val.kind
      );
      if (index < 0) {
        missingDocumentsType.push(associatedDocuments[i]);
      } else {
        existingDocuments.push(props.documents[index]);
      }
    }
  }

  const onSave = (saved: DocumentType) => {
    if (
      props.documentType === DocumentType.ConfirmationStatement ||
      props.documentType === DocumentType.ProofOfReserves
    ) {
      if (
        documentsSaved.findIndex((val: DocumentType) => saved === val) < 0 &&
        missingDocumentsType.findIndex((val: DocumentType) => saved === val) >
          -1
      ) {
        documentsSaved.push(saved);
        if (
          missingDocumentsType.every((val: DocumentType) =>
            documentsSaved.includes(val)
          )
        ) {
          upgradeAccreditedStatus()
            .then((val: HyLabsResponseDataUserId) => {
              //now the user category is accredited
              //force refresh of DigitalID documents
              props.handleRefreshDocuments();
            })
            .catch((err: any) => {
              //TODO: handle error
              console.log(err);
            });
        }
      }
    } else {
      //force refresh of whole DigitalID documents
      props.handleRefreshDocuments();
    }
  };

  const description = DocumentType.getDocumentViewDescription(
    props.documentType
  );

  const manageDocuments = (
    <>
      {existingDocuments.map((val: EntityDocumentLight) => {
        return (
          <ManageDocument
            key={val.id}
            title={DocumentType.getManageDocumentTitle(val.kind)}
            document_type={val.kind}
            document_id={val.id}
          />
        );
      })}
      {missingDocumentsType.map((val: DocumentType) => {
        return (
          <ManageDocument
            key={val}
            title={DocumentType.getManageDocumentTitle(val)}
            document_type={val}
            onSave={onSave}
          />
        );
      })}
    </>
  );

  return (
    <>
      <BackButtonTitled
        section="Documents"
        title={DocumentType.getDocumentViewTitle(props.documentType)}
        onClick={props.onClickBackButton}
      />
      {description && (
        <Typography
          width="600px"
          marginLeft="60px"
          marginTop="16px"
          variant="body2"
          fontWeight={500}
          color="black"
        >
          {description}
        </Typography>
      )}
      <Stack direction="row" spacing={4} marginTop="32px">
        {manageDocuments}
      </Stack>
    </>
  );
};

export default DocumentView;
