export interface AssetDetail {
  id: string;
  asset: string;
  price: number;
  units: number;
  value: number;
  nominal: number;
  interest: string;
  type: string;
  term: string;
  issuer: string;
  prospectus_hash: string;
  term_sheet_hash: string;
  transaction_history: TransactionHistory[];
  coupon_history: CouponHistory[];
}

export interface TransactionHistory {
  id: string;
  operation: string;
  date: string;
  units: number;
  price: number;
  book_cost: number;
}

export interface CouponHistory {
  id: string;
  date: string;
  value: number;
}

const assets: AssetDetail[] = [
  {
    id: "ASSET1",
    asset: "HyLabs 2Yr Fixed 3.5% Green Bond",
    price: 102.3,
    units: 100,
    value: 10_230,
    nominal: 100_000_000,
    interest: "3.5% fixed",
    type: "Fixed",
    term: "2 Years",
    issuer: "HyLabs Limited",
    prospectus_hash: "prospectus_hash",
    term_sheet_hash: "term_sheet_hash",
    transaction_history: [
      {
        id: "1",
        operation: "Purchase",
        date: "2nd January 2023",
        units: 100,
        price: 102.3,
        book_cost: 10_230,
      },
    ],
    coupon_history: [
      {
        id: "1",
        date: "January 2023",
        value: 5,
      },
    ],
  },
  {
    id: "ASSET2",
    asset: "Runtime 5Yr Variable SONIA+3% Bond",
    price: 80.3,
    units: 100,
    value: 8_030,
    nominal: 200_000_000,
    interest: "SONIA+3%",
    type: "Variable",
    term: "5 Years",
    issuer: "Runtime",
    prospectus_hash: "prospectus_hash",
    term_sheet_hash: "term_sheet_hash",
    transaction_history: [
      {
        id: "1",
        operation: "Purchase",
        date: "2nd January 2023",
        units: 100,
        price: 80.3,
        book_cost: 8030,
      },
    ],
    coupon_history: [
      {
        id: "1",
        date: "January 2023",
        value: 5,
      },
    ],
  },
  {
    id: "ASSET3",
    asset: "Runtime 3Yr Variable SONIA+3% Bond",
    price: 90,
    units: 10,
    value: 900,
    nominal: 100_000_000,
    interest: "SONIA+3%",
    type: "Variable",
    term: "3 Years",
    issuer: "Runtime",
    prospectus_hash: "prospectus_hash",
    term_sheet_hash: "term_sheet_hash",
    transaction_history: [
      {
        id: "1",
        operation: "Purchase",
        date: "2nd January 2023",
        units: 10,
        price: 90,
        book_cost: 900,
      },
    ],
    coupon_history: [
      {
        id: "1",
        date: "January 2023",
        value: 5,
      },
    ],
  },
];

export function getAssetDetails(id: string): AssetDetail | undefined {
  return assets.find((value) => value.id === id);
}
