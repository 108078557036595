import React, { useState } from "react";
import {
  Institution,
  ProfessionalInstitution,
} from "../utils/types/interfaces";

export type ProfessionalInstitutionSummary = {
  business_address: string[];
  incorporation_date: string;
  registered_address: string[];
};

interface KYBContextType {
  institution: Institution | undefined;
  setInstitution?: (institution: Institution) => void;
  professionalInstitutionSummary: ProfessionalInstitutionSummary | undefined;
  setProfessionalInstitutionSummary?: (
    professionalInstitutionSummary: ProfessionalInstitutionSummary
  ) => void;
  professionalInstitution: ProfessionalInstitution | undefined;
  setProfessionalInstitution?: (
    professionalInstitution: ProfessionalInstitution
  ) => void;
  cleanSession?: () => void;
}

const defaultState: KYBContextType = {
  institution: undefined,
  professionalInstitution: undefined,
  professionalInstitutionSummary: undefined,
};

export const KYBContext = React.createContext<KYBContextType>(defaultState);

interface KYBProviderProps {
  children: React.ReactNode;
}

const KYBContextProvider = ({ children }: KYBProviderProps) => {
  const [institution, setInstitution] = useState(
    sessionStorage.getItem("institution") !== null ?
      JSON.parse(sessionStorage.getItem("institution")!) : undefined
  );

  const [professionalInstitutionSummary, setProfessionalInstitutionSummary] =
    useState(
      sessionStorage.getItem("professionalInstitutionSummary") !== null ?
        JSON.parse(sessionStorage.getItem("professionalInstitutionSummary")!) : undefined
    );

  const [professionalInstitution, setProfessionalInstitution] = useState(
    sessionStorage.getItem("professionalInstitution") !== null &&
      JSON.parse(sessionStorage.getItem("professionalInstitution")!)
  );

  const handleSetInstitution = (data: Institution) => {
    setInstitution(data);
    sessionStorage.setItem("institution", JSON.stringify(data));
  };

  const handleSetProfessionalInstitutionSummary = (
    data: ProfessionalInstitutionSummary
  ) => {
    setProfessionalInstitutionSummary(data);
    sessionStorage.setItem(
      "professionalInstitutionSummary",
      JSON.stringify(data)
    );
  };

  const handleSetProfessionalInstitution = (data: ProfessionalInstitution) => {
    setProfessionalInstitution(data);
    sessionStorage.setItem("professionalInstitution", JSON.stringify(data));
  };

  const cleanSession = () => {
    sessionStorage.removeItem("institution");
    sessionStorage.removeItem("professionalInstitutionSummary");
    sessionStorage.removeItem("professionalInstitution");
  };

  return (
    <KYBContext.Provider
      value={{
        institution: institution,
        setInstitution: handleSetInstitution,
        professionalInstitutionSummary: professionalInstitutionSummary,
        setProfessionalInstitutionSummary:
          handleSetProfessionalInstitutionSummary,
        professionalInstitution: professionalInstitution,
        setProfessionalInstitution: handleSetProfessionalInstitution,
        cleanSession: cleanSession,
      }}
    >
      {children}
    </KYBContext.Provider>
  );
};

export const KYB_CONTEXT_DELIMITER = "KYB_CONTEXT_DELIMITER";

export default KYBContextProvider;
