import {
  AbcProgrammeApplicableTo,
  AssetClassExperience,
  AuditedEvery,
  BoardReportingPeriod,
  DocumentExtension,
  DocumentType,
  EddType,
  EmployeeInComplianceDepartment,
  EmployeesNumber,
  EnterpriseWideRiskAssessmentFrequency,
  FixedIncomeBondStatus,
  ManualUpdateTimeframe,
  ProductDocumentExtension,
  ProductDocumentType,
  RetentionPeriod,
  SanctionListUsedFor,
  ScreeningMethod,
  TotalAssetsValue,
  UserCategory,
  UserKind,
  UserOnboardingStatus,
  UserStatus,
} from "./enums";

export interface HyLabsResponse {
  message: string;
  data: any;
}

export interface Assets {
  cash: number;
  investments: number;
  property: number;
  pension: number;
  other: number;
}

export interface Liabilities {
  credit_cards: number;
  loans: number;
  mortgage: number;
  other: number;
}

export interface ExternalApi {
  external_id: string;
  sanction_check: boolean;
  api_provider: number;
}

export interface Individual {
  first_name: string;
  last_name: string;
  phone_number: string;
  agree_on_checks: boolean;
  income: number;
  one_off_income: number;
  expenses: number;
  one_off_expenses: number;
  assets: Assets;
  liabilities: Liabilities;
  pep: boolean;
  accredited_status: boolean;
  external_api: ExternalApi;
  documents: EntityDocument[];
}

export interface AssetClassExperiences {
  cash: AssetClassExperience;
  bonds: AssetClassExperience;
  equity: AssetClassExperience;
  private_equity: AssetClassExperience;
  hedge_funds: AssetClassExperience;
  digital_assets: AssetClassExperience;
  derivatives: AssetClassExperience;
}

export interface Institution {
  first_name: string;
  last_name: string;
  phone_number: string;
  position: string;
  company_name: string;
  company_registration_number: string;
  registered_address: string;
  incorporation_date: string;
  pep: boolean;
  revenue: number;
  profit: number;
  accredited_status: boolean;
  assets: Assets;
  liabilities: Liabilities;
  asset_class_experiences: AssetClassExperiences;
  external_api: ExternalApi;
  documents: EntityDocument[];
}

export interface ProInstOwnershipStructure {
  publicly_traded: boolean;
  exchange?: string;
  ticker?: string;
  member_owned: boolean;
  government_owned: boolean;
  privately_owned: boolean;
  private_shareholder_details?: string;
}

export interface ProInstBusinessArea {
  retail_banking: boolean;
  private_banking: boolean;
  commercial_banking: boolean;
  transactional_banking: boolean;
  investment_banking: boolean;
  financial_market_trading: boolean;
  securities_service: boolean;
  broker: boolean;
  multilateral_development_bank: boolean;
  wealth_manager: boolean;
  other?: string;
}

export interface ResponsesCheck {
  check: boolean;
  clarify_differences?: string;
  additional_informations?: string;
}

export interface ProInstProductAndServices {
  correspondent_banking_service?: ProInstCorrespondentBankingServices;
  private_banking: boolean;
  trade_finance: boolean;
  payable_through_accounts: boolean;
  stored_value_instruments: boolean;
  cross_border_bulk_cash_delivery: boolean;
  domestic_bulk_cash_delivery: boolean;
  international_cash_letter: boolean;
  remote_deposit_capture: boolean;
  virtual_digital_currencies: boolean;
  low_price_securities: boolean;
  hold_mail: boolean;
  cross_border_remittances: boolean;
  services_to_non_account_holders: boolean;
  sponsoring_private_atm: boolean;
  high_risk_products_services: boolean;
  responses_representative_of_all_le_branches_check: ResponsesCheck;
}

export namespace ProInstProductAndServices {
  export function defaultValue(): ProInstProductAndServices {
    return {
      private_banking: false,
      trade_finance: false,
      payable_through_accounts: false,
      stored_value_instruments: false,
      cross_border_bulk_cash_delivery: false,
      domestic_bulk_cash_delivery: false,
      international_cash_letter: false,
      remote_deposit_capture: false,
      virtual_digital_currencies: false,
      low_price_securities: false,
      hold_mail: false,
      cross_border_remittances: false,
      services_to_non_account_holders: false,
      sponsoring_private_atm: false,
      high_risk_products_services: false,
      responses_representative_of_all_le_branches_check: { check: false },
    };
  }
}

export interface ProInstCorrespondentBankingServices {
  offer_to_domestic_banks: boolean;
  allow_downstream_to_domestic_banks: boolean;
  can_identify_downstream_with_domestic_banks: boolean;
  offer_to_foreign_banks: boolean;
  allow_downstream_to_foreign_banks: boolean;
  can_identify_downstream_with_foreign_banks: boolean;
  offer_to_regulated_msb_mvts: boolean;
  allow_downstream_to_regulated_msb_mvts: boolean;
  can_identify_downstream_relationships_with_regulated_msb_mvts: boolean;
}

export interface ProInstAmlCtfSanctionStandards {
  appointed_officer: boolean;
  cash_reporting?: boolean;
  cdd: boolean;
  edd: boolean;
  beneficial_ownership: boolean;
  independent_testing: boolean;
  periodic_review: boolean;
  policies_and_procedures: boolean;
  risk_assessment: boolean;
  sanctions: boolean;
  pep_screening: boolean;
  adverse_information_screening: boolean;
  suspicious_activity_reporting: boolean;
  training_and_education: boolean;
  transaction_monitoring: boolean;
  ft_employees_in_compliance_dep: EmployeeInComplianceDepartment;
  policy_approved_annually: boolean;
  policy_approval_practice?: string;
  board_reporting_period: BoardReportingPeriod;
  use_third_party: boolean;
  third_party_details?: string;
  responses_representative_of_all_le_branches_check: ResponsesCheck;
}

export namespace ProInstAmlCtfSanctionStandards {
  export function defaultValue(): ProInstAmlCtfSanctionStandards {
    return {
      appointed_officer: false,
      cdd: false,
      edd: false,
      beneficial_ownership: false,
      independent_testing: false,
      periodic_review: false,
      policies_and_procedures: false,
      risk_assessment: false,
      sanctions: false,
      pep_screening: false,
      adverse_information_screening: false,
      suspicious_activity_reporting: false,
      training_and_education: false,
      transaction_monitoring: false,
      ft_employees_in_compliance_dep:
        EmployeeInComplianceDepartment.LessThanTen,
      policy_approved_annually: false,
      board_reporting_period: BoardReportingPeriod.Monthly,
      use_third_party: false,
      responses_representative_of_all_le_branches_check: { check: false },
    };
  }
}

export interface ProInstAbc {
  documented_policies_and_procedures: boolean;
  enterprise_wide_programme: boolean;
  coordinator_officer: boolean;
  implementer_staff: boolean;
  programme_applicable_to: AbcProgrammeApplicableTo;
  prohibits_give_receive_bribes: boolean;
  enhanced_requirements_for_pub_officials_interaction: boolean;
  prohibits_books_records_falsification: boolean;
  programme_monitoring: boolean;
  regular_management_information_sharing: boolean;
  enterprise_wide_risk_assessment: boolean;
  enterprise_wide_risk_assessment_frequency?: EnterpriseWideRiskAssessmentFrequency;
  residual_risk_rating: boolean;
  inherent_risk_coverage: ProInstAbcInherentRiskCoverage;
  audit_abc_policies_procedures: boolean;
  abc_training: ProInstAbcTraining;
  responses_representative_of_all_le_branches_check: ResponsesCheck;
}

export namespace ProInstAbc {
  export function defaultValue(): ProInstAbc {
    return {
      documented_policies_and_procedures: false,
      enterprise_wide_programme: false,
      coordinator_officer: false,
      implementer_staff: false,
      programme_applicable_to: AbcProgrammeApplicableTo.NotApplicable,
      prohibits_give_receive_bribes: false,
      enhanced_requirements_for_pub_officials_interaction: false,
      prohibits_books_records_falsification: false,
      programme_monitoring: false,
      regular_management_information_sharing: false,
      enterprise_wide_risk_assessment: false,
      residual_risk_rating: false,
      inherent_risk_coverage: {
        ext_liabilities: false,
        ext_corruption: false,
        txn_prod_serv: false,
        gifts_corruption: false,
        business_activity_change: false,
      },
      audit_abc_policies_procedures: false,
      abc_training: {
        board_and_senior_committee_management: false,
        first_line_defence: false,
        second_line_defence: false,
        third_line_defence: false,
        third_party_outsourced: false,
        non_employed_workers: false,
        targeted_training: false,
      },
      responses_representative_of_all_le_branches_check: { check: false },
    };
  }
}

export interface ProInstAbcInherentRiskCoverage {
  ext_liabilities: boolean;
  ext_corruption: boolean;
  txn_prod_serv: boolean;
  gifts_corruption: boolean;
  business_activity_change: boolean;
}

export interface ProInstAbcTraining {
  board_and_senior_committee_management: boolean;
  first_line_defence: boolean;
  second_line_defence: boolean;
  third_line_defence: boolean;
  third_party_outsourced: boolean;
  non_employed_workers: boolean;
  targeted_training: boolean;
}

export interface ProInstAmlCtfSanctionPoliciesProcedures {
  money_laundering: boolean;
  terrorist_financing: boolean;
  sanctions_violations: boolean;
  updated_annually: boolean;
  gapped_to_us: boolean;
  retain_result_us?: boolean;
  gapped_to_eu: boolean;
  retain_result_eu?: boolean;
  prohibit_anonymous_accounts: boolean;
  prohibit_unlicensed_banks_nbfi_accounts: boolean;
  prohibit_sub_dealing_with_unlicensed_banks: boolean;
  prohibit_shell_banks: boolean;
  prohibit_sub_dealing_with_shell_banks: boolean;
  prohibit_accounts_for_section_311_entities: boolean;
  prohibit_accounts_for_unregulated_unlicesed_entitites: boolean;
  financial_crime_risk_issues: boolean;
  existing_customer_termination_financial_crime_risk: boolean;
  suspicious_activities_escalation_and_investigation: boolean;
  outline_screening_for_sanctions_pep_negative_media: boolean;
  outline_maintenance_internal_watchlists: boolean;
  business_risk_boundary: boolean;
  record_retention_compliable_with_applicable_laws: boolean;
  retention_period?: RetentionPeriod;
  responses_representative_of_all_le_branches_check: ResponsesCheck;
}

export namespace ProInstAmlCtfSanctionPoliciesProcedures {
  export function defaultValue(): ProInstAmlCtfSanctionPoliciesProcedures {
    return {
      money_laundering: false,
      terrorist_financing: false,
      sanctions_violations: false,
      updated_annually: false,
      gapped_to_us: false,
      retain_result_us: false,
      gapped_to_eu: false,
      prohibit_anonymous_accounts: false,
      prohibit_unlicensed_banks_nbfi_accounts: false,
      prohibit_sub_dealing_with_unlicensed_banks: false,
      prohibit_shell_banks: false,
      prohibit_sub_dealing_with_shell_banks: false,
      prohibit_accounts_for_section_311_entities: false,
      prohibit_accounts_for_unregulated_unlicesed_entitites: false,
      financial_crime_risk_issues: false,
      existing_customer_termination_financial_crime_risk: false,
      suspicious_activities_escalation_and_investigation: false,
      outline_screening_for_sanctions_pep_negative_media: false,
      outline_maintenance_internal_watchlists: false,
      business_risk_boundary: false,
      record_retention_compliable_with_applicable_laws: false,
      responses_representative_of_all_le_branches_check: { check: false },
    };
  }
}

export interface ProInstAmlCtfSanctionRiskAssessment {
  aml_ctf_ewra: ProInstAmlCtfEwra;
  sanctions_ewra: ProInstSanctionsEwra;
  responses_representative_of_all_le_branches_check: ResponsesCheck;
}

export namespace ProInstAmlCtfSanctionRiskAssessment {
  export function defaultValue(): ProInstAmlCtfSanctionRiskAssessment {
    return {
      aml_ctf_ewra: {
        client_risk: false,
        product_risk: false,
        channel_risk: false,
        geography_risk: false,
        txn_monitoring_control: false,
        customer_due_diligence_control: false,
        pep_identification_control: false,
        txn_screening_control: false,
        name_screening_against_adverse_media_control: false,
        training_and_education_control: false,
        governance_control: false,
        management_information_control: false,
        risk_assessment_in_last_year: false,
      },
      sanctions_ewra: {
        client_risk: false,
        product_risk: false,
        channel_risk: false,
        geography_risk: false,
        customer_due_diligence_control: false,
        txn_screening_control: false,
        name_screening_control: false,
        list_management_control: false,
        training_and_education_control: false,
        governance_control: false,
        management_information_control: false,
        risk_assessment_in_last_year: false,
      },
      responses_representative_of_all_le_branches_check: { check: false },
    };
  }
}

export interface ProInstAmlCtfEwra {
  client_risk: boolean;
  product_risk: boolean;
  channel_risk: boolean;
  geography_risk: boolean;
  txn_monitoring_control: boolean;
  customer_due_diligence_control: boolean;
  pep_identification_control: boolean;
  txn_screening_control: boolean;
  name_screening_against_adverse_media_control: boolean;
  training_and_education_control: boolean;
  governance_control: boolean;
  management_information_control: boolean;
  risk_assessment_in_last_year: boolean;
  risk_assessment_date?: string;
}

export interface ProInstSanctionsEwra {
  client_risk: boolean;
  product_risk: boolean;
  channel_risk: boolean;
  geography_risk: boolean;
  customer_due_diligence_control: boolean;
  txn_screening_control: boolean;
  name_screening_control: boolean;
  list_management_control: boolean;
  training_and_education_control: boolean;
  governance_control: boolean;
  management_information_control: boolean;
  risk_assessment_in_last_year: boolean;
  risk_assessment_date?: string;
}

export interface ProInstKycCddEdd {
  customer_identity_verification: boolean;
  pol_and_proc_set_out_when_cdd_must_be_completed: boolean;
  gather_retain_ownership_structure_cdd: boolean;
  gather_retain_customer_identification_cdd: boolean;
  gather_retain_expected_activity_cdd: boolean;
  gather_retain_nature_of_business_cdd: boolean;
  gather_retain_product_usage_cdd: boolean;
  gather_retain_purpose_and_nature_of_relationships_cdd: boolean;
  gather_retain_source_of_funds_product_usage_cdd: boolean;
  gather_retain_source_of_wealth_cdd: boolean;
  ultimate_beneficial_ownership_identified: boolean;
  ultimate_beneficial_owners_verified: boolean;
  authorised_signatories_identified: boolean;
  key_controllers_identified: boolean;
  other_relevant_parties_identified?: string;
  perc_min_threshold_applied_to_ben_ownership_identification: number;
  due_diligence_process_outputs_risk_classification_to_clients: boolean;
  risk_classification_use_product_usage?: boolean;
  risk_classification_use_geography?: boolean;
  risk_classification_use_business_type?: boolean;
  risk_classification_use_legal_entity_type?: boolean;
  risk_classification_use_adverse_information?: boolean;
  risk_classification_use_other?: string;
  risk_based_screening_customers_for_adverse_media: boolean;
  risk_based_screening_at_onboarding?: boolean;
  risk_based_screening_at_kyc_renewal?: boolean;
  risk_based_screening_at_trigger_event?: boolean;
  adverse_media_screening_method: ScreeningMethod;
  risk_based_screening_customers_pep: boolean;
  risk_based_screening_customers_pep_at_onboarding?: boolean;
  risk_based_screening_customers_pep_at_kyc_renewal?: boolean;
  risk_based_screening_customers_pep_at_trigger_event?: boolean;
  pep_screening_method: ScreeningMethod;
  policies_procedure_processes_to_determine_customers_pep: boolean;
  customers_pep_process_based_on_kyc_renewal: boolean;
  customers_pep_process_based_on_trigger_event: boolean;
  maintain_and_report_metrics_crnt_and_past_due_diligence_rvws: boolean;
  prohibited_customer_category: ProInstKycCddEddProhibitedCustomerCategories;
  responses_representative_of_all_le_branches_check: ResponsesCheck;
}

export namespace ProInstKycCddEdd {
  export function defaultValue(): ProInstKycCddEdd {
    return {
      customer_identity_verification: false,
      pol_and_proc_set_out_when_cdd_must_be_completed: false,
      gather_retain_ownership_structure_cdd: false,
      gather_retain_customer_identification_cdd: false,
      gather_retain_expected_activity_cdd: false,
      gather_retain_nature_of_business_cdd: false,
      gather_retain_product_usage_cdd: false,
      gather_retain_purpose_and_nature_of_relationships_cdd: false,
      gather_retain_source_of_funds_product_usage_cdd: false,
      gather_retain_source_of_wealth_cdd: false,
      ultimate_beneficial_ownership_identified: false,
      ultimate_beneficial_owners_verified: false,
      authorised_signatories_identified: false,
      key_controllers_identified: false,
      perc_min_threshold_applied_to_ben_ownership_identification: 1,
      due_diligence_process_outputs_risk_classification_to_clients: false,
      risk_based_screening_customers_for_adverse_media: false,
      adverse_media_screening_method: ScreeningMethod.Manual,
      risk_based_screening_customers_pep: false,
      pep_screening_method: ScreeningMethod.Manual,
      policies_procedure_processes_to_determine_customers_pep: false,
      customers_pep_process_based_on_kyc_renewal: false,
      customers_pep_process_based_on_trigger_event: false,
      maintain_and_report_metrics_crnt_and_past_due_diligence_rvws: false,
      prohibited_customer_category: {
        non_account_customers: EddType.None,
        non_resident_customers: EddType.None,
        shell_banks: EddType.None,
        mvts_msb_customers: EddType.None,
        peps: EddType.None,
        pep_related: EddType.None,
        pep_close_associate: EddType.None,
        correspondent_banks: EddType.None,
        arms_defense_military: EddType.None,
        extractive_industries: EddType.None,
        precious_metals_and_stones: EddType.None,
        unregulated_charities: EddType.None,
        regulated_charities: EddType.None,
        adult_entertainment: EddType.None,
        non_government_organisations: EddType.None,
        virtual_currencies: EddType.None,
        marijuana: EddType.None,
        embassies_consulates: EddType.None,
        gambling: EddType.None,
        payment_service_provider: EddType.None,
      },
      responses_representative_of_all_le_branches_check: { check: false },
    };
  }
}

export interface ProInstKycCddEddProhibitedCustomerCategories {
  non_account_customers: EddType;
  non_resident_customers: EddType;
  shell_banks: EddType;
  mvts_msb_customers: EddType;
  peps: EddType;
  pep_related: EddType;
  pep_close_associate: EddType;
  correspondent_banks: EddType;
  correspondent_banks_edd_or_restriction_contains_wcbp2014?: boolean;
  arms_defense_military: EddType;
  extractive_industries: EddType;
  precious_metals_and_stones: EddType;
  unregulated_charities: EddType;
  regulated_charities: EddType;
  adult_entertainment: EddType;
  non_government_organisations: EddType;
  virtual_currencies: EddType;
  marijuana: EddType;
  embassies_consulates: EddType;
  gambling: EddType;
  payment_service_provider: EddType;
  other?: string;
}

export interface ProInstMonitoringAndReporting {
  risk_based_policies_for_suspicious_activity: boolean;
  monitor_transactions_for_suspicious_activities_method: ScreeningMethod;
  monitor_transactions_for_suspicious_activities_type?: string;
  regulatory_requirements_to_report_suspicious_transactions: boolean;
  policies_to_comply_with_suspicious_transaction_reporting?: boolean;
  policies_to_review_monitoring_activity: boolean;
  responses_representative_of_all_le_branches_check: ResponsesCheck;
}

export namespace ProInstMonitoringAndReporting {
  export function defaultValue(): ProInstMonitoringAndReporting {
    return {
      risk_based_policies_for_suspicious_activity: false,
      monitor_transactions_for_suspicious_activities_method:
        ScreeningMethod.Manual,
      regulatory_requirements_to_report_suspicious_transactions: false,
      policies_to_review_monitoring_activity: false,
      responses_representative_of_all_le_branches_check: { check: false },
    };
  }
}

export interface ProInstPaymentTransparency {
  entity_adhere_to_wgpt_standards: boolean;
  entity_have_policies_compliance_with_fatf: boolean;
  entity_have_policies_compliance_with_local: boolean;
  entity_have_policies_compliance_with_local_regulations?: string;
  entity_have_policies_compliance_with_n?: string;
  processes_to_respond_rfis: boolean;
  controls_to_support_inclusion_of_originator: boolean;
  controls_to_support_inclusion_of_beneficiary: boolean;
  responses_representative_of_all_le_branches_check: ResponsesCheck;
}

export namespace ProInstPaymentTransparency {
  export function defaultValue(): ProInstPaymentTransparency {
    return {
      entity_adhere_to_wgpt_standards: false,
      entity_have_policies_compliance_with_fatf: false,
      entity_have_policies_compliance_with_local: false,
      processes_to_respond_rfis: false,
      controls_to_support_inclusion_of_originator: false,
      controls_to_support_inclusion_of_beneficiary: false,
      responses_representative_of_all_le_branches_check: { check: false },
    };
  }
}

export interface ProInstSanctions {
  entity_have_sanctions_policy_approved_by_management: boolean;
  policies_to_prevent_use_another_accounts: boolean;
  policies_to_prohibit_to_evade_sanctions_prohibitions: boolean;
  screen_customers: boolean;
  screen_customers_method: ScreeningMethod;
  screen_sanctions_data: boolean;
  screen_sanctions_data_method: ScreeningMethod;
  un_sanctions_lists: SanctionListUsedFor;
  ofac_sanctions_lists: SanctionListUsedFor;
  ofsi_sanctions_lists: SanctionListUsedFor;
  eu_sanctions_lists: SanctionListUsedFor;
  days_before_updates_manual_against_customer_data: ManualUpdateTimeframe;
  days_before_updates_manual_against_transactions: ManualUpdateTimeframe;
  physical_presence_located_in_countries_with_sanctions: boolean;
  responses_representative_of_all_le_branches_check: ResponsesCheck;
}

export namespace ProInstSanctions {
  export function defaultValue(): ProInstSanctions {
    return {
      entity_have_sanctions_policy_approved_by_management: false,
      policies_to_prevent_use_another_accounts: false,
      policies_to_prohibit_to_evade_sanctions_prohibitions: false,
      screen_customers: false,
      screen_customers_method: ScreeningMethod.Manual,
      screen_sanctions_data: false,
      screen_sanctions_data_method: ScreeningMethod.Manual,
      un_sanctions_lists: SanctionListUsedFor.NotUsed,
      ofac_sanctions_lists: SanctionListUsedFor.NotUsed,
      ofsi_sanctions_lists: SanctionListUsedFor.NotUsed,
      eu_sanctions_lists: SanctionListUsedFor.NotUsed,
      days_before_updates_manual_against_customer_data:
        ManualUpdateTimeframe.LessThanTwoDays,
      days_before_updates_manual_against_transactions:
        ManualUpdateTimeframe.LessThanTwoDays,
      physical_presence_located_in_countries_with_sanctions: false,
      responses_representative_of_all_le_branches_check: { check: false },
    };
  }
}

export interface ProInstTrainingAndEducation {
  training_includes_reporting_transactions_to_authorities: boolean;
  training_includes_examples: boolean;
  training_includes_internal_policies: boolean;
  training_includes_new_issues_occur_in_market: boolean;
  training_includes_conduct_and_culture: boolean;
  training_provided_board_and_senior_committee: boolean;
  training_provided_1st_line_defence: boolean;
  training_provided_2nd_line_defence: boolean;
  training_provided_3rd_line_defence: boolean;
  training_provided_3rd_parties?: boolean;
  training_provided_non_employed_workers?: boolean;
  provide_aml_ctf_sanctions_training: boolean;
  provide_customised_training: boolean;
  responses_representative_of_all_le_branches_check: ResponsesCheck;
}

export namespace ProInstTrainingAndEducation {
  export function defaultValue(): ProInstTrainingAndEducation {
    return {
      training_includes_reporting_transactions_to_authorities: false,
      training_includes_examples: false,
      training_includes_internal_policies: false,
      training_includes_new_issues_occur_in_market: false,
      training_includes_conduct_and_culture: false,
      training_provided_board_and_senior_committee: false,
      training_provided_1st_line_defence: false,
      training_provided_2nd_line_defence: false,
      training_provided_3rd_line_defence: false,
      provide_aml_ctf_sanctions_training: false,
      provide_customised_training: false,
      responses_representative_of_all_le_branches_check: { check: false },
    };
  }
}

export interface ProInstQualityAssuranceComplianceTesting {
  kyc_processes_and_documents_have_quality_assurance_testing: boolean;
  program_risk: boolean;
  responses_representative_of_all_le_branches_check: ResponsesCheck;
}

export namespace ProInstQualityAssuranceComplianceTesting {
  export function defaultValue(): ProInstQualityAssuranceComplianceTesting {
    return {
      kyc_processes_and_documents_have_quality_assurance_testing: false,
      program_risk: false,
      responses_representative_of_all_le_branches_check: { check: false },
    };
  }
}

export interface ProInstAudit {
  have_internal_audit_and_testing_function: boolean;
  how_often_is_entity_audit_by_internal_audit_department: AuditedEvery;
  how_often_is_entity_audit_by_external_third_party?: AuditedEvery;
  cover_aml_ctf_sanctions_policy_and_procedures: boolean;
  cover_kyc_cdd_edd_and_underlying_methodologies: boolean;
  cover_transaction_monitoring: boolean;
  cover_transaction_screening_including_for_sanctions: boolean;
  cover_name_screening_and_list_management: boolean;
  cover_training_and_education: boolean;
  cover_technology: boolean;
  cover_governance: boolean;
  cover_reporting_metrics_and_management_information: boolean;
  cover_suspicious_activity_filing: boolean;
  cover_enterprise_wide_risk_assessment: boolean;
  cover_other?: string;
  adverse_findings_tracked_to_completion_assessed: boolean;
  responses_representative_of_all_le_branches_check: ResponsesCheck;
}

export namespace ProInstAudit {
  export function defaultValue(): ProInstAudit {
    return {
      have_internal_audit_and_testing_function: false,
      how_often_is_entity_audit_by_internal_audit_department: AuditedEvery.Year,
      cover_aml_ctf_sanctions_policy_and_procedures: false,
      cover_kyc_cdd_edd_and_underlying_methodologies: false,
      cover_transaction_monitoring: false,
      cover_transaction_screening_including_for_sanctions: false,
      cover_name_screening_and_list_management: false,
      cover_training_and_education: false,
      cover_technology: false,
      cover_governance: false,
      cover_reporting_metrics_and_management_information: false,
      cover_suspicious_activity_filing: false,
      cover_enterprise_wide_risk_assessment: false,
      adverse_findings_tracked_to_completion_assessed: false,
      responses_representative_of_all_le_branches_check: { check: false },
    };
  }
}

export interface ProfessionalInstitution {
  country: string;
  company_name: string;
  company_registration_number: string;
  registered_address: string;
  incorporation_date: string;
  business_address?: string;
  branches?: string[];
  ownership_structure: ProInstOwnershipStructure;
  perc_bearer_shares: number;
  obl_operated: boolean;
  branches_under_obl?: string[];
  primary_financial_regulator: string;
  legal_entity_identifier?: string;
  ult_parent_legal_name?: string;
  licensing_authority_jurisdiction: string;
  business_area: ProInstBusinessArea;
  non_resident_customer_check: boolean;
  non_resident_customer_countries: string[];
  employees: EmployeesNumber;
  assets: TotalAssetsValue;
  responses_representative_of_all_le_branches_check: ResponsesCheck;
  product_and_service: ProInstProductAndServices;
  aml_ctf_sanction_standard: ProInstAmlCtfSanctionStandards;
  abc: ProInstAbc;
  aml_ctf_sanction_policy_procedure: ProInstAmlCtfSanctionPoliciesProcedures;
  aml_ctf_sanction_risk_assessment: ProInstAmlCtfSanctionRiskAssessment;
  kyc_cdd_edd: ProInstKycCddEdd;
  monitoring_and_reporting: ProInstMonitoringAndReporting;
  payment_transparency: ProInstPaymentTransparency;
  sanctions: ProInstSanctions;
  training_and_education: ProInstTrainingAndEducation;
  quality_assurance_compliance_testing: ProInstQualityAssuranceComplianceTesting;
  audit: ProInstAudit;
  declaration_statement_signature: string;
}

export interface HyDidInfo {
  id: string;
  account_address: string;
  identity_nft_address: string;
  is_frozen: boolean;
}

export interface EntityInfo {
  Individual?: Individual;
  Institution?: Institution;
  ProfessionalInstitution?: ProfessionalInstitution;
  None?: string | null;
}

export interface QueryFetchPrice {
  price: boolean;
}

export interface Token {
  Info?: TokenInfo;
  InfoWithPrice?: TokenInfoWithPrice;
  None?: string | null;
}

export interface TokenInfo {
  id: string;
  token_address: string;
  name: string;
  product_type: ProductType;
  symbol: string;
  total_supply: number;
}

export interface TokenInfoWithPrice {
  token_info: TokenInfo;
  price: number;
}

export interface ProductType {
  FixedIncomeBond?: string;
}

export interface EntityDocumentLight {
  id?: string;
  sha256_hash: string;
  expiration_date?: string;
  kind: DocumentType;
  extension: DocumentExtension;
  img: string;
}

export interface EntityDocument {
  id?: string;
  bytes: string;
  sha256_hash?: string;
  expiration_date?: string;
  kind: DocumentType;
  extension: DocumentExtension;
  img: string;
}

export interface IsHyDidFrozen {
  id: string;
  is_frozen: boolean;
}

export interface IsUserAdmin {
  id: string;
  is_admin: boolean;
}

export interface UserInfo {
  id: string;
  username: string;
  email: string;
  mobile_number: string;
  kind: UserKind;
  category: UserCategory;
  status: UserStatus;
  onboarding_status: UserOnboardingStatus;
  is_admin: boolean;
}

export interface GetUserStatus {
  id: string;
  status: UserStatus;
}

export interface GetUserOnboardingStatus {
  id: string;
  kind: UserKind;
  category: UserCategory;
  onboarding_status: UserOnboardingStatus;
}

export interface UserRegistration {
  username: string;
  email: string;
  mobile_number: string;
  password: string;
  kind: UserKind;
}

export interface Activate {
  code: string;
}

export interface Passwords {
  identifier: string;
  old_password: string;
  new_password: string;
  confirm_new_password: string;
}

export interface UserCredentials {
  identifier: string;
  password: string;
}

export interface UserIdentifier {
  identifier: string;
}

export interface HyWalletInfo {
  token_info: Token;
  amount: number;
}

export interface UserDocuments {
  user_id: string;
  documents: EntityDocumentLight[];
}

export interface UpdateDocument {
  old_document: EntityDocumentLight;
  new_document: EntityDocument;
}

export interface DeleteDocument {
  id: string;
}

export interface QueryExpiringIn {
  in_days: number;
}

export interface SharedDocument {
  id?: string;
  document_id: string;
  shared_with: string;
  granted_until: string;
}

export interface ThirdPartyDocument {
  id: string;
  document: EntityDocumentLight;
  data_owner: string;
  granted_until: string;
}

export interface ProductDocument {
  sha256_hash?: string;
  bytes: string;
  kind: ProductDocumentType;
  extension: ProductDocumentExtension;
}

export interface ProductDocumentRequest {
  hash: Uint8Array;
}

export interface FixedIncomeBond {
  name: string;
  identifier: string;
  structure: string;
  currency: string;
  issuer_id: string;
  product_provider_id: string;
  term: string;
  launch_date: string;
  completion_date: string;
  notional: number;
  division: number;
  minimum: number;
  allow_professional: boolean;
  allow_accredited: boolean;
  allow_retail: boolean;
  coupon_frequency: string;
  coupon_type: string;
  coupon_rate: string;
  coupon_reference_rate: string;
  redemption: string;
  optionality: string;
  collateral: string;
  platform_cost: string;
  advisor_cost: string;
  offer_document_sha256_hash: Uint8Array;
  kiid_sha256_hash: Uint8Array;
  resolution_sha256_hash: Uint8Array;
  agreement_sha256_hash: Uint8Array;
  status: FixedIncomeBondStatus;
}

export interface PrepareFixedIncomeBond {
  name: string;
  identifier: string;
  structure: string;
  issuer_identifier: string;
  currency: string;
  term: string;
  launch_date: string;
  completion_date: string;
  notional: number;
  division: number;
  minimum: number;
  allow_professional: boolean;
  allow_accredited: boolean;
  allow_retail: boolean;
  coupon_frequency: string;
  coupon_type: string;
  coupon_rate: string;
  coupon_reference_rate: string;
  redemption: string;
  optionality: string;
  collateral: string;
  platform_cost: string;
  advisor_cost: string;
  documents: ProductDocument[];
}

export interface MintFixedIncomeBond {
  fixed_income_bond_id: string;
}

export interface PreIssuanceFixedIncomeBond {
  as_issuer: FixedIncomeBond[];
  as_product_provider: FixedIncomeBond[];
  as_both: FixedIncomeBond[];
  as_none: FixedIncomeBond[];
}

export interface RegistrationFieldExists {
  email_exists: boolean;
  username_exists: boolean;
  mobile_number_exists: boolean;
}

export interface AvailableIssuer {
  issuer_identifier: string;
  display_name: string;
}

export interface DocumentPreview {
  id: string;
  base64img?: string;
  file?: string;
}

export interface PersonOfSignificantControl {
  id: string;
  name: string;
  email_address: string;
  verified: boolean;
}

export interface BeneficialOwners {
  id: string;
  name: string;
  email_address: string;
}

export interface Signatories {
  id: string;
  name: string;
  email_address: string;
  type: string;
}
