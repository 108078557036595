import { Grid, Stack } from "@mui/material";
import CMSDashboardBox from "./Admin/CMSDashboardBox";
import TokenisationAssetSummaryBox from "./Admin/TokenisationAssetsSummaryBox";
import SettingsBox from "./Common/SettingsBox";
import CustodyWalletBox from "./Admin/CustodyWalletBox";
import {
  FixedIncomeBond,
  PreIssuanceFixedIncomeBond,
} from "../../utils/types/interfaces";
import { useState } from "react";
import {
  TokenDetail,
  getTokenDetail,
} from "../../utils/mockup/tokenisation/getTokenDetail";
import TokenDetailsView from "../TokenisationPlatform/TokenDetailsView";

export interface AdminViewProps {
  dataGetTokenInProcess: PreIssuanceFixedIncomeBond | null;
  loadedGetTokenInProcess: boolean;
}

const AdminView = (props: AdminViewProps) => {
  const [tokenDetails, setTokenDetails] = useState<TokenDetail | undefined>(
    undefined
  );

  const handleMockupTokenDetailsOnClick = (token_id: string) => {
    console.log(token_id);
    const data = getTokenDetail(token_id);
    setTokenDetails(data);
  };

  const handleTokenDetailsOnClick = (token: FixedIncomeBond) => {
    const data: TokenDetail = {
      id: "" + token.name + token.issuer_id + token.product_provider_id,
      asset: token.name,
      price: token.division,
      nominal: token.notional,
      interest: token.coupon_rate,
      type: token.coupon_type,
      term: token.term,
      issuer: token.issuer_id,
      prospectus_hash: token.kiid_sha256_hash,
      term_sheet_hash: token.offer_document_sha256_hash,
      in_process: true,
      mockup: false,
    };
    setTokenDetails(data);
  };

  const handleOnClickBackButtonInTokenDetailsView = () => {
    setTokenDetails(undefined);
  };

  return (
    <>
      {tokenDetails === undefined ? (
        <Grid container width={"100%"} columnSpacing={2}>
          <Grid item xs={12} sm={4}>
            <Stack direction={"column"} spacing={2}>
              <CMSDashboardBox />
              <SettingsBox />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Stack direction={"column"} spacing={2}>
              <TokenisationAssetSummaryBox
                dataGetTokenInProcess={props.dataGetTokenInProcess}
                loadedGetTokenInProcess={props.loadedGetTokenInProcess}
                onClickDetailsMockup={handleMockupTokenDetailsOnClick}
                onClickDetails={handleTokenDetailsOnClick}
              />
              <CustodyWalletBox />
            </Stack>
          </Grid>
        </Grid>
      ) : (
        <TokenDetailsView
          tokenDetails={tokenDetails}
          userInfo={null}
          hyDidInfo={null}
          onClickBackButton={handleOnClickBackButtonInTokenDetailsView}
        />
      )}
    </>
  );
};

export default AdminView;
