import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, IconButton, Stack, Typography } from "@mui/material";
import arrow_left_circle from "../../assets/images/arrow-left-circle.svg";
import { passwordRegex } from "../../utils/types/regexes";
import { changePassword } from "../../routes/hynetwork/changePassword";
import CreateAccountLink from "../../components/Intro/CreateAccountLink";
import { LoginContext } from "../../context/LoginContextProvider";
import CustomInputPassword from "../../components/Base/CustomInputPassword";
import CustomButton from "../../components/Base/CustomButton";

function ChangePassword() {
  const navigate = useNavigate();
  const handleClickBack = () => {
    navigate("/auth/login");
  };

  const [oldPasswordText, setOldPasswordText] = useState("");
  const [oldPasswordTextError, setOldPasswordTextError] = useState(false);
  const [newPasswordText, setNewPasswordText] = useState("");
  const [newPasswordTextError, setNewPasswordTextError] = useState(false);
  const [confirmPasswordText, setConfirmPasswordText] = useState("");
  const [confirmPasswordTextError, setConfirmPasswordTextError] =
    useState(false);

  const { userIdentifier } = useContext(LoginContext);

  useEffect(() => {
    if (userIdentifier == null || userIdentifier === "") {
      navigate("/auth/login");
    }
  });

  const handleOldPasswordChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setOldPasswordText(event.target.value);
  };

  const handleNewPasswordChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setNewPasswordText(event.target.value);
  };

  const handlePasswordConfirmChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (event) => {
    setConfirmPasswordText(event.target.value);
  };

  const handleClickChangePassword: React.FormEventHandler<HTMLFormElement> = (
    event
  ) => {
    event.preventDefault();
    if (passwordRegex.test(newPasswordText)) {
      if (newPasswordText === confirmPasswordText) {
        changePassword({
          identifier: userIdentifier,
          old_password: oldPasswordText,
          new_password: newPasswordText,
          confirm_new_password: confirmPasswordText,
        })
          .then(() => {
            navigate("/auth/login");
          })
          .catch((err) => {
            if (err.message === "UnprocessableEntity") {
              setConfirmPasswordTextError(true);
            }
            if (err.response.data.message.includes("credentials")) {
              setOldPasswordTextError(true);
              setNewPasswordTextError(true);
              setConfirmPasswordTextError(true);
            } else {
              navigate("/auth/login");
            }
          });
      } else {
        setConfirmPasswordTextError(true);
      }
    } else {
      setNewPasswordTextError(true);
    }
  };

  return (
    <>
      <form onSubmit={handleClickChangePassword}>
        <Stack spacing={4} mb="32px" width="416px">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <IconButton
                sx={{ position: "absolute" }}
                onClick={handleClickBack}
              >
                <img src={arrow_left_circle} alt="" />
              </IconButton>
              <Typography variant="h4" flexGrow="1" textAlign="center">
                Forgot password
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" textAlign="center">
                Please create a new password
              </Typography>
            </Grid>
          </Grid>
          <CustomInputPassword
            enableDescription={true}
            description="Old password"
            value={oldPasswordText}
            onChange={handleOldPasswordChange}
            error={oldPasswordTextError}
            errorText=""
          />
          <CustomInputPassword
            enableDescription={true}
            description="New password"
            value={newPasswordText}
            onChange={handleNewPasswordChange}
            error={newPasswordTextError}
            errorText="Password must be at least 8 characters long. A combination of at least one uppercase letter, one lowercase letter, and one number or symbol"
          />
          <CustomInputPassword
            enableDescription={true}
            description="Confirm new password"
            value={confirmPasswordText}
            onChange={handlePasswordConfirmChange}
            error={confirmPasswordTextError}
            errorText="Passwords don’t match"
          />
          <CustomButton
            variant="contained"
            fullWidth={true}
            type="submit"
            disabled={
              oldPasswordText === "" ||
              newPasswordText === "" ||
              confirmPasswordText === "" ||
              userIdentifier === ""
            }
          >
            <Typography paddingY="12px" fontWeight="500">
              Send
            </Typography>
          </CustomButton>
        </Stack>
      </form>
      <CreateAccountLink />
    </>
  );
}

export default ChangePassword;
