import { Icon, Stack, Typography } from "@mui/material";
import DefaultCard from "../../Base/DefaultCard";
import CustomRoundedRow from "../../Base/CustomRoundedRow";
import { ReactComponent as FileTextIcon } from "../../../assets/images/file-text.svg";
import { ReactComponent as FolderIcon } from "../../../assets/images/folder.svg";
import { ReactComponent as ShieldIcon } from "../../../assets/images/shield.svg";
import { ReactComponent as UserIcon } from "../../../assets/images/user.svg";

const SettingsBox = () => {
  return (
    <DefaultCard title="Settings">
      <Stack direction="column" spacing={1} width="100%">
        <CustomRoundedRow>
          <Typography variant="h6" color="black" boxSizing="border-box">
            {"Directory"}
          </Typography>
          <Icon>
            <FolderIcon />
          </Icon>
        </CustomRoundedRow>
        <CustomRoundedRow>
          <Typography variant="h6" color="black" boxSizing="border-box">
            {"Security"}
          </Typography>
          <Icon>
            <ShieldIcon />
          </Icon>
        </CustomRoundedRow>
        <CustomRoundedRow>
          <Typography variant="h6" color="black" boxSizing="border-box">
            {"Reporting"}
          </Typography>
          <Icon>
            <FileTextIcon />
          </Icon>
        </CustomRoundedRow>
        <CustomRoundedRow>
          <Typography variant="h6" color="black" boxSizing="border-box">
            {"Account"}
          </Typography>
          <Icon>
            <UserIcon />
          </Icon>
        </CustomRoundedRow>
      </Stack>
    </DefaultCard>
  );
};

export default SettingsBox;
