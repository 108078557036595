import { Box } from "@mui/material";
import { DocumentPreview } from "../../../../utils/types/interfaces";

export interface PdfPreviewBoxProps {
  document: DocumentPreview;
  boxWidth?: string;
  boxHeight?: string;
}

const PdfPreviewBox = (props: PdfPreviewBoxProps) => {
  return (
    <Box
      width={props.boxWidth ? props.boxWidth : "345px"}
      height={props.boxHeight ? props.boxHeight : "470px"}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {props.document.file ? (
        <iframe
          src={`data:application/pdf;base64,${props.document.file}#view=fitH`}
          title="testPdf"
          height="100%"
          width="100%"
        />
      ) : props.document.base64img ? (
        <img
          width="100%"
          height="100%"
          id={props.document.id}
          src={props.document.base64img}
          alt={props.document.id}
        ></img>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default PdfPreviewBox;
