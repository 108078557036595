import { FileUploader } from "react-drag-drop-files";

import "../../../../style/digital_id.css";
import { PropsWithChildren } from "react";

export interface DragAndDropAreaProps {
  handleChange: (file: File) => void;
  fileTypes?: string[];
}

const DragAndDropArea = (props: PropsWithChildren<DragAndDropAreaProps>) => {
  return (
    <FileUploader
      handleChange={props.handleChange}
      name="file"
      types={props.fileTypes ? props.fileTypes : ["PDF"]}
      classes={"drop_area drop_zone fileUploader"}
      hoverTitle={""}
      children={props.children}
    />
  );
};

export default DragAndDropArea;
