import { Button, ButtonProps, styled } from "@mui/material";

const CustomStartButton = styled(Button)<ButtonProps>(({ theme }) => ({
  padding: "6px 8px",
  borderRadius: "8px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  maxWidth: "128px",
}));

const StartButton = (props: ButtonProps) => {
  return <CustomStartButton {...props}>Start</CustomStartButton>;
};

export default StartButton;
