import {
  CircularProgress,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { UserKind } from "../../utils/types/enums";
import DefaultCard from "../Base/DefaultCard";
import DetailsList from "./Details/DetailsList";
import DocumentsList from "./Documents/DocumentsList";
import CorporateBackgroundList from "./CorporateBackground/CorporateBackgroundList";
import { MapEntityDocumentLight } from "../../utils/types/types";
import { UserDocuments, UserInfo } from "../../utils/types/interfaces";

export interface DigitalIDViewProps {
  handleDocumentOnClick: any;
  handleDetailsOnClickStart: any;
  handleCorporateBackgroundOnClick: any;
  dataGetDoc: UserDocuments | null;
  loadedGetDoc: boolean;
  dataGetUserInfo: UserInfo | null;
}

const DigitalIDView = (props: DigitalIDViewProps) => {
  const documents: MapEntityDocumentLight = {};
  if (props.dataGetDoc !== null) {
    props.dataGetDoc.documents.forEach((obj) => (documents[obj.kind] = obj));
  }

  return (
    <Stack direction="column" spacing={4}>
      <Typography variant="h4" color="black">
        {"Digital ID"}
      </Typography>
      {props.dataGetUserInfo !== null ? (
        <>
          {props.dataGetUserInfo.kind === UserKind.INDIVIDUAL ? (
            <>
              <DefaultCard title={"Details"}>
                <DetailsList userCategory={props.dataGetUserInfo.category} onClick={props.handleDetailsOnClickStart} />
              </DefaultCard>
              <DefaultCard title={"Documents"}>
                {props.loadedGetDoc ? (
                  <DocumentsList
                    userCategory={props.dataGetUserInfo.category}
                    userKind={props.dataGetUserInfo.kind}
                    documents={documents}
                    onClick={props.handleDocumentOnClick}
                  />
                ) : (
                  <CircularProgress />
                )}
              </DefaultCard>
            </>
          ) : (
            <>
              <DefaultCard title={"Documents"}>
                {props.loadedGetDoc ? (
                  <DocumentsList
                    userCategory={props.dataGetUserInfo.category}
                    userKind={props.dataGetUserInfo.kind}
                    documents={documents}
                    onClick={props.handleDocumentOnClick}
                  />
                ) : (
                  <CircularProgress />
                )}
              </DefaultCard>
              <DefaultCard title={"Corporate background"}>
                <CorporateBackgroundList
                  onClick={props.handleCorporateBackgroundOnClick}
                />
              </DefaultCard>
            </>
          )}
        </>
      ) : (
        <>
          <DefaultCard>
            <LinearProgress sx={{ width: "100%" }} />
          </DefaultCard>

          <DefaultCard>
            <LinearProgress sx={{ width: "100%" }} />
          </DefaultCard>
        </>
      )}
    </Stack>
  );
};

export default DigitalIDView;
