import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import theme from "../../../../theme";
import PdfPreviewBox from "./PdfPreviewBox";
import DragAndDropArea from "./DragAndDropArea";
import UploadFileBox from "./UploadFileBox";
import { useEffect, useState } from "react";
import { ReactComponent as UpdateIcon } from "../../../../assets/images/refresh-cw.svg";
import { pdfToDataUrl } from "../../../../utils/functions/pdfToDataURL";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import { EntityDocument } from "../../../../utils/types/interfaces";
import {
  getUserDocument,
  postUserDocument,
  updateUserDocument,
} from "../../../../routes/walletid/hydid/document";
import { DocumentExtension, DocumentType } from "../../../../utils/types/enums";
import { hash } from "../../../../utils/functions/hash";
import DefaultCard from "../../../Base/DefaultCard";
import { bytesToBase64 } from "../../../../utils/functions/bytesToBase64";
import { base64ToBytes } from "../../../../utils/functions/base64ToBytes";
import { dataURLtoBase64 } from "../../../../utils/functions/dataURLtoBase64";

const PDFJS = require("pdfjs-dist");
PDFJS.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${PDFJS.version}/build/pdf.worker.min.js`;

export interface ManageDocumentProps {
  title?: string;
  document_id?: string;
  document_type: DocumentType;
  boxWidth?: string;
  boxHeight?: string;
  onSave?: (param: DocumentType) => void;
}

const ManageDocument = (props: ManageDocumentProps) => {
  const [file, setFile] = useState<ArrayBuffer | undefined>(undefined);
  const [fileB64, setFileB64] = useState<string | undefined>(undefined);
  const [oldFile, setOldFile] = useState<EntityDocument | undefined>(undefined);
  const [oldFileLoaded, setOldFileLoaded] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleCloseSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowError(false);
  };

  useEffect(() => {
    if (props.document_id !== undefined) {
      getUserDocument(props.document_id)
        .then((value) => {
          setOldFile(value);
        })
        .finally(() => setOldFileLoaded(true));
    }
  }, [props.document_id]);

  const handleChange = (mFile: File) => {
    mFile.arrayBuffer().then((value: ArrayBuffer) => {
      setFile(value);
      pdfToDataUrl(value, setFileB64);
    });
  };

  const handleUpdateButton = (event: any) => {
    if (event && event.target && event.target.files[0] !== undefined) {
      handleChange(event.target.files[0]);
    }
  };

  const handleOnClickCancelButton = (event: any) => {
    event.preventDefault();
    setFile(undefined);
    setFileB64(undefined);
  };

  const updateStateAfterSave = (new_doc: EntityDocument) => {
    setFile(undefined);
    setFileB64(undefined);
    setOldFile(new_doc);
    if (props.onSave !== undefined) {
      props.onSave(new_doc.kind);
    }
  };

  const handleOnClickSaveButton = (event: any) => {
    event.preventDefault();
    if (file) {
      let new_document: EntityDocument = {
        bytes: bytesToBase64(file),
        kind: props.document_type,
        extension: DocumentExtension.PDF,
        img: dataURLtoBase64(fileB64),
      };

      const post_doc = () =>
        postUserDocument(new_document)
          .then((value: string) => {
            new_document.id = value;
            updateStateAfterSave(new_document);
          })
          .catch((err: any) => {
            //TODO: handle errors
            if (err.response && err.response.status === 500) {
              //High probability that document exists in the database, i.e., it is not unique
              setShowError(true);
            }
          });

      if (oldFile === undefined) {
        // post new file
        post_doc();
      } else if (oldFile && oldFile.sha256_hash) {
        // update document
        updateUserDocument({
          old_document: {
            sha256_hash: oldFile.sha256_hash,
            kind: oldFile.kind,
            extension: oldFile.extension,
            img: oldFile.img,
          },
          new_document,
        })
          .then((value: string | null | undefined) => {
            if (value !== null && value !== undefined && value.length > 0) {
              //value updated successfully
              new_document.id = value;
              hash(base64ToBytes(new_document.bytes)).then(
                (value: ArrayBuffer) => {
                  new_document.sha256_hash = bytesToBase64(value);
                }
              );
              updateStateAfterSave(new_document);
            } else {
              //old doc does not exist
              post_doc();
            }
          })
          .catch((err: any) => {
            //TODO: handle errors
            if (err.response && err.response.status === 500) {
              //High probability that document exists in the database, i.e., it is not unique
              setShowError(true);
            }
          });
      }
    }
  };

  const buttonsLogic =
    oldFile && file === undefined ? (
      <>
        <Divider sx={{ width: "100%" }} />
        <Box width={"100%"}>
          <Button
            variant="outlined"
            color="primary"
            endIcon={<UpdateIcon />}
            component="label"
          >
            Update
            <input
              type="file"
              accept=".pdf"
              hidden
              onChange={handleUpdateButton}
            />
          </Button>
        </Box>
      </>
    ) : file ? (
      <>
        <Divider sx={{ width: "100%" }} />
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            color="primary"
            endIcon={<ClearIcon />}
            component="label"
            onClick={handleOnClickCancelButton}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            color="primary"
            endIcon={<DoneIcon />}
            component="label"
            onClick={handleOnClickSaveButton}
          >
            Save
          </Button>
        </Stack>
      </>
    ) : (
      <></>
    );

  return (
    <>
      <DefaultCard
        width={props.boxWidth ? props.boxWidth : "535px !important"}
        height={props.boxHeight ? props.boxHeight : "680px !important"}
      >
        {props.title && (
          <Typography variant="h6" lineHeight="27px" color="#1E1E1E">
            {props.title}
          </Typography>
        )}
        <Stack direction={"column"} width={"100%"} height={"100%"} spacing={4}>
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="8px"
            border={`4px solid ${theme.palette.grey.A200}`}
            boxSizing="border-box"
            sx={{ backgroundColor: `${theme.palette.grey.A200}` }}
          >
            {file !== undefined && file.byteLength > 0 ? (
              <PdfPreviewBox
                document={{
                  id: "uploaded",
                  base64img: fileB64,
                  file: bytesToBase64(file),
                }}
              />
            ) : oldFile !== undefined ? (
              <PdfPreviewBox document={{ id: "old", file: oldFile.bytes }} />
            ) : props.document_id !== undefined && !oldFileLoaded ? (
              <CircularProgress />
            ) : (
              <DragAndDropArea handleChange={handleChange}>
                <UploadFileBox />
              </DragAndDropArea>
            )}
          </Box>
          {buttonsLogic}
        </Stack>
      </DefaultCard>
      <Snackbar
        open={showError}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          <Typography variant="body1">
            Error! Exact same document already exists in HyNetwork.
          </Typography>
        </Alert>
      </Snackbar>
    </>
  );
};

export default ManageDocument;
