import React from "react";
import { Box, Divider, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

function CreateAccountLink() {
  return (
    <>
      <Divider />
      <Box
        width="fit-content"
        display="flex"
        mt="30px"
        mx="auto"
        alignItems="center"
      >
        <Typography color="text.secondary">Don’t have an account?</Typography>
        <Link
          component={RouterLink}
          to={"/auth/signup"}
          underline="hover"
          color="secondary.main"
        >
          <Typography ml="4px">Create one</Typography>
        </Link>
      </Box>
    </>
  );
}

export default CreateAccountLink;
