import { Box, Typography } from "@mui/material";
import theme from "../../../../theme";

import { ReactComponent as UploadIcon } from "../../../../assets/images/upload.svg";

export interface UploadFileBoxProps {
  boxWidth?: string;
  boxHeight?: string;
}

const UploadFileBox = (props: UploadFileBoxProps) => {
  return (
    <Box
      width={props.boxWidth ? props.boxWidth : "345px"}
      height={props.boxHeight ? props.boxHeight : "120px"}
      display="flex"
      flexDirection="column"
      component="div"
      justifyContent="space-around"
      alignItems="center"
      padding="16px"
      border={`1px dashed ${theme.palette.primary.main}`}
      borderRadius="16px"
      boxShadow={theme.shadows[4]}
      boxSizing="border-box"
      sx={{ backgroundColor: "rgba(8, 43, 84, 0.08)" }}
    >
      <UploadIcon />
      <Typography
        variant="body2"
        fontWeight="600"
        textAlign="center"
        color={theme.palette.primary.main}
        marginBottom="4px"
      >
        Drag and drop or browse file
      </Typography>
    </Box>
  );
};

export default UploadFileBox;
