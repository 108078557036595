import React from "react";
import {
  RouterProvider,
  createBrowserRouter,
  redirect,
} from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import theme from "./theme";
import RootLayout from "./pages/Root";
import Signup from "./pages/Auth/Signup";
import Activation from "./pages/Auth/Activation";
import NotFound from "./components/Base/NotFound";
import Login from "./pages/Auth/Login";
import ResetPassword from "./pages/Auth/ResetPassword";
import ChangePassword from "./pages/Auth/ChangePassword";
import VerificatioKYC from "./pages/Auth/VerificationKYC";
import VerificationKYB from "./pages/Auth/VerificationKYB";
import QuestionnaireRoot from "./pages/QuestionnaireRoot";
import Summary from "./pages/Questionnaire/Summary";
import Ownership from "./pages/Questionnaire/Ownership";
import HomeRoot from "./pages/HomeRoot";
import Dashboard from "./pages/Home/Dashboard";
import DigitalID from "./pages/Home/DigitalID";
import UserDirectory from "./pages/Home/UserDirectory";
import DigitalWallet from "./pages/Home/DigitalWallet";
import TokenisationPlatform from "./pages/Home/TokenisationPlatform";
import AuthRoot from "./pages/AuthRoot";
import { getUserInfo } from "./routes/hynetwork/getUser";
import { UserOnboardingStatus } from "./utils/types/enums";

const authLoader = () => {
  return getUserInfo()
    .then((userInfo) => {
      if (
        userInfo.onboarding_status !== UserOnboardingStatus.Onboarded &&
        userInfo.onboarding_status !== UserOnboardingStatus.NotRequired
      ) {
        throw redirect("/auth/login");
      }
      return userInfo;
    })
    .catch(() => {
      throw redirect("/auth/login");
    });
};

const authLoaderQuestionnaire = () => {
  return getUserInfo()
    .then((userInfo) => {
      console.log(userInfo.onboarding_status);
      if (
        userInfo.onboarding_status === UserOnboardingStatus.Onboarded ||
        userInfo.onboarding_status === UserOnboardingStatus.NotRequired
      ) {
        throw redirect("/");
      }
      return userInfo;
    })
    .catch(() => {
      throw redirect("/");
    });
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <NotFound />,
    children: [
      {
        element: <HomeRoot />,
        loader: authLoader,
        children: [
          {
            index: true,
            element: <Dashboard />,
            loader: authLoader,
          },
          {
            path: "digital-id",
            element: <DigitalID />,
            loader: authLoader,
          },
          {
            path: "user-directory",
            element: <UserDirectory />,
            loader: authLoader,
          },
          {
            path: "digital-wallet",
            element: <DigitalWallet />,
            loader: authLoader,
          },
          {
            path: "tokenisation-platform",
            element: <TokenisationPlatform />,
            loader: authLoader,
          },
        ],
      },
      {
        path: "auth",
        element: <AuthRoot />,
        children: [
          {
            index: true,
            path: "login",
            element: <Login />,
          },
          {
            path: "signup",
            element: <Signup />,
          },
          {
            path: "activation",
            element: <Activation />,
          },
          {
            path: "kyc",
            element: <VerificatioKYC />,
          },
          {
            path: "kyb",
            element: <VerificationKYB />,
          },
          {
            path: "reset-password",
            element: <ResetPassword />,
          },
          {
            path: "change-password",
            element: <ChangePassword />,
          },
        ],
      },
      {
        path: "questionnaire",
        element: <QuestionnaireRoot />,
        loader: authLoaderQuestionnaire,
        children: [
          {
            path: "summary",
            element: <Summary />,
            loader: authLoaderQuestionnaire,
          },
          {
            path: "ownership",
            element: <Ownership />,
            loader: authLoaderQuestionnaire,
          },
        ],
      },
    ],
  },
]);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
