import { Stack, Typography } from "@mui/material";
import { DocumentType, UserCategory } from "../../../utils/types/enums";
import CustomRoundedRow from "../../Base/CustomRoundedRow";
import CustomMark, { CustomMarkColor, CustomMarkTextColor } from "../../Base/CustomMark";
import StartButton from "../StartButton";

export interface DetailsListProps {
  userCategory: UserCategory;
  onClick: (document_type: DocumentType) => void;
}

const DetailsList = (props: DetailsListProps) => {
  return (
    <Stack direction="column" spacing={1} width="100%">
      <CustomRoundedRow>
        <Typography variant="h6" color="black">
          {"ID"}
        </Typography>
        <CustomMark
          markColor={CustomMarkColor.Green}
          markTextColor={CustomMarkTextColor.Green}
          disabled={true}
          text="Completed"
        />
      </CustomRoundedRow>
      <CustomRoundedRow>
        <Typography variant="h6" color="black">
          {"KYC"}
        </Typography>
        <CustomMark
          markColor={CustomMarkColor.Green}
          markTextColor={CustomMarkTextColor.Green}
          disabled={true}
          text="Completed"
        />
      </CustomRoundedRow>
      <CustomRoundedRow>
        <Typography variant="h6" color="black">
          {"Accredited Status"}
        </Typography>
        {props.userCategory === UserCategory.ACCREDITED ? (
          <CustomMark
            markColor={CustomMarkColor.Green}
            markTextColor={CustomMarkTextColor.Green}
            disabled={true}
            text="Completed"
          />
        ) : (
          <StartButton
            variant="outlined"
            color="primary"
            onClick={() => {
              props.onClick(DocumentType.ProofOfReserves);
            }}
          />
        )}
      </CustomRoundedRow>
    </Stack>
  );
};

export default DetailsList;
