import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { UserKind } from "../../utils/types/enums";
import CustomTypeButton from "../../components/Intro/CustomTypeButton";
import SignupForm from "../../components/Intro/SignupForm";
import { Box, Divider, Link, Stack, Typography } from "@mui/material";

function Signup() {
  const [accountType, setAccountType] = useState<UserKind>(UserKind.INDIVIDUAL);
  const [nextPhase, setNextPhase] = useState(false);

  const setTypeIndividual: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    setAccountType(UserKind.INDIVIDUAL);
    setNextPhase(true);
  };

  const setTypeInstitution: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    setAccountType(UserKind.INSTITUTION);
    setNextPhase(true);
  };

  const handleClickBack: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    setNextPhase(false);
  };

  const phase_1 = (
    <>
      <Typography width="fit-content" marginX="auto" variant="h4" mb="8px">
        Welcome to HyLabs
      </Typography>
      <Typography width="fit-content" marginX="auto" variant="body1" mb="32px">
        How do you want to register?
      </Typography>
      <Stack spacing={2} mb="32px">
        <CustomTypeButton onClick={setTypeIndividual} text="Individual" />
        <CustomTypeButton onClick={setTypeInstitution} text="Institution" />
      </Stack>
    </>
  );

  return (
    <Box width="416px">
      {!nextPhase ? (
        phase_1
      ) : (
        <SignupForm handleClickBack={handleClickBack} userKind={accountType} />
      )}
      <Divider />
      <Box
        width="fit-content"
        display="flex"
        mt="30px"
        mx="auto"
        alignItems="center"
      >
        <Typography color="text.secondary">Already have an account?</Typography>
        <Link
          component={RouterLink}
          to={"/auth/login"}
          underline="hover"
          color="secondary.main"
        >
          <Typography ml="4px">Log in</Typography>
        </Link>
      </Box>
    </Box>
  );
}

export default Signup;
