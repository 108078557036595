import { Button, Stack, Typography } from "@mui/material";
import { CorporateBackground } from "../../../utils/types/enums";
import CustomRoundedRow from "../../Base/CustomRoundedRow";
import { ReactComponent as DoneIcon } from "../../../assets/images/green-check-circle.svg";
import { ReactComponent as UpdateIcon } from "../../../assets/images/refresh-cw.svg";

const expectedCorporateBackground = [
  CorporateBackground.BeneficialOwners,
  CorporateBackground.PersonOfSignificantControl,
  CorporateBackground.Signatories,
];

export interface CorporateBackgroundListProps {
  onClick: any;
}

const CorporateBackgroundList = (props: CorporateBackgroundListProps) => {
  return (
    <Stack direction="column" spacing={1} width="100%">
      {expectedCorporateBackground.map((value: CorporateBackground) => {
        return (
          <CustomRoundedRow key={value}>
            <Stack direction="row" spacing={1}>
              <DoneIcon />
              <Typography variant="h6" color="black" boxSizing="border-box">
                {value}
              </Typography>
            </Stack>
            <Button
              variant="outlined"
              color="primary"
              endIcon={<UpdateIcon />}
              component="label"
              onClick={() => props.onClick(value)}
            >
              Update
            </Button>
          </CustomRoundedRow>
        );
      })}
    </Stack>
  );
};

export default CorporateBackgroundList;
