import { Stack, Typography } from "@mui/material";
import CustomCheckbox from "../Base/CustomCheckbox";
import CustomInputField from "../Base/CustomInputField";

export interface OwnershipStructureProps {
  publiclyTraded: boolean;
  handlePubliclyTradedChange: any;
  exchange: string;
  handleExchangeChange: any;
  ticker: string;
  handleTickerChange: any;
  memberOwned: boolean;
  handleMemberOwnedChange: any;
  stateOwned: boolean;
  handleStateOwnedChange: any;
  privatelyOwned: boolean;
  handlePrivatelyOwnedChange: any;
  beneficialOwner: string;
  handleBeneficialOwnerChange: any;
  dateOfBirth: string;
  handleDateOfBirthChange: any;
}

const OwnershipStructure = (props: OwnershipStructureProps): JSX.Element => {
  return (
    <>
      <CustomCheckbox
        value={props.publiclyTraded}
        onChange={props.handlePubliclyTradedChange}
        text="Publicly Traded (25% of shares traded publicly)"
      />
      <Stack spacing={2} width="100%" pl="48px">
        <Typography>If yes, select exchange and ticker</Typography>
        <Stack spacing={2} direction="row" width="50%">
          <CustomInputField
            enableDescription={true}
            description="Exchange"
            descriptionTextColor="text.main"
            placeholder=""
            value={props.exchange}
            mandatory={true}
            type="text"
            disabled={!props.publiclyTraded}
            fullWidth={false}
            className="ownership-input"
            onChange={props.handleExchangeChange}
            error={false}
            errorText="Please enter an Exchange"
          />
          <CustomInputField
            enableDescription={true}
            description="Ticker"
            descriptionTextColor="text.main"
            placeholder=""
            value={props.ticker}
            mandatory={true}
            type="text"
            disabled={!props.publiclyTraded}
            fullWidth={false}
            className="ownership-input"
            onChange={props.handleTickerChange}
            error={false}
            errorText="Please enter a Ticker"
          />
        </Stack>
      </Stack>
      <CustomCheckbox
        value={props.memberOwned}
        onChange={props.handleMemberOwnedChange}
        text="Member Owned/Mutual"
      />
      <CustomCheckbox
        value={props.stateOwned}
        onChange={props.handleStateOwnedChange}
        text="Government or State Owned by 25% or more"
      />
      <CustomCheckbox
        value={props.privatelyOwned}
        onChange={props.handlePrivatelyOwnedChange}
        text="Privately Owned"
      />
      <Stack spacing={2} width="100%" pl="48px">
        <Typography>
          If yes, provide details of shareholders or Ultimate Benefical Owners
          with a holding of 10% or more
        </Typography>
        <Stack spacing={2} direction="row" width="50%">
          <CustomInputField
            enableDescription={true}
            description="Beneficial Owner"
            descriptionTextColor="text.main"
            placeholder=""
            value={props.beneficialOwner}
            mandatory={true}
            type="text"
            disabled={!props.privatelyOwned}
            fullWidth={false}
            className="ownership-input"
            onChange={props.handleBeneficialOwnerChange}
            error={false}
            errorText="Please enter the Beneficial Owner"
          />
          <CustomInputField
            enableDescription={true}
            description="Date of Birth"
            descriptionTextColor="text.main"
            placeholder=""
            value={props.dateOfBirth}
            mandatory={true}
            type="date"
            disabled={!props.privatelyOwned}
            fullWidth={false}
            className="ownership-input"
            onChange={props.handleDateOfBirthChange}
            error={false}
            errorText="Please enter the Date of Birth"
          />
        </Stack>
      </Stack>
    </>
  );
};

export default OwnershipStructure;
