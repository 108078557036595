import { Box, BoxProps, Typography, styled } from "@mui/material";
import { PropsWithChildren } from "react";

export interface DefaultCardProps {
  title?: string;
}

const CustomCard = styled(Box)<BoxProps>(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  component: "div",
  justifyContent: "center",
  alignItems: "flex-start",
  padding: "32px",
  border: "1px solid #F5F5F5",
  borderRadius: "16px",
  boxShadow: `${theme.shadows[4]}`,
  boxSizing: "border-box",
  backgroundColor: "white",
}));

const DefaultCard = (props: PropsWithChildren<DefaultCardProps & BoxProps>) => {
  return (
    <CustomCard {...props}>
      {props.title && (
        <Typography variant="h5" color="black" marginBottom="32px">
          {props.title}
        </Typography>
      )}
      {props.children}
    </CustomCard>
  );
};

export default DefaultCard;
