import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomInputField from "../Base/CustomInputField";
import CustomPhoneField from "./CustomPhoneField";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { emailRegex, passwordRegex } from "../../utils/types/regexes";
import { registration } from "../../routes/hynetwork/registration";
import { UserKind } from "../../utils/types/enums";
import { LoginContext } from "../../context/LoginContextProvider";
import arrow_left_circle from "../../assets/images/arrow-left-circle.svg";
import { RegistrationFieldExists } from "../../utils/types/interfaces";
import CustomInputPassword from "../Base/CustomInputPassword";
import CustomButton from "../Base/CustomButton";

interface SignupFormProps {
  handleClickBack: React.MouseEventHandler<HTMLButtonElement>;
  userKind: UserKind;
}

function SignupForm(props: SignupFormProps) {
  const navigate = useNavigate();

  const { setUserIdentifier } = useContext(LoginContext);

  const [usernameText, setUsernameText] = useState("");
  const [usernameTextError, setUsernameTextError] = useState(false);
  const [emailText, setEmailText] = useState("");
  const [emailTextError, setEmailTextError] = useState(false);
  const [emailTextErrorMsg, setEmailTextErrorMsg] = useState(
    "Please enter a valid email address"
  );
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [phoneErrorMsg, setPhoneErrorMsg] = useState(
    "Please enter a valid phone number"
  );
  const [passwordText, setPasswordText] = useState("");
  const [passwordTextError, setPasswordTextError] = useState(false);
  const [confirmPasswordText, setConfirmPasswordText] = useState("");
  const [confirmPasswordTextError, setConfirmPasswordTextError] =
    useState(false);

  const handleUsernameChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setUsernameText(event.target.value);
  };

  const handleEmailChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setEmailText(event.target.value);
  };

  const handlePhoneChange = (event: string) => {
    setPhone("+" + event);
  };

  const handlePasswordChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setPasswordText(event.target.value);
  };

  const handlePasswordConfirmChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (event) => {
    setConfirmPasswordText(event.target.value);
  };

  const handleSignup = async () => {
    await registration({
      username: usernameText,
      email: emailText,
      mobile_number: phone,
      password: passwordText,
      kind: props.userKind,
    })
      .then(() => {
        setUserIdentifier?.(emailText.toString());
        navigate("/auth/login");
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 403) {
          if (
            err.response.data.message !== undefined &&
            err.response.data.message.includes("email_exists")
          ) {
            const error_body: RegistrationFieldExists | undefined = JSON.parse(
              err.response.data.message
            );
            console.log(error_body);
            if (error_body !== undefined) {
              if (error_body.email_exists) {
                setEmailTextErrorMsg("This emal is already taken");
                setEmailTextError(true);
              }
              if (error_body.username_exists) {
                setUsernameTextError(true);
              }
              if (error_body.mobile_number_exists) {
                setPhoneErrorMsg("This number is already taken");
                setPhoneError(true);
              }
            }
          }
        }
        if (err.response.status === 422) {
          if (
            err.response.data.message !== undefined &&
            err.response.data.message.includes("email")
          ) {
            setEmailTextErrorMsg("Please enter a valid email address");
            setEmailTextError(true);
          } else if (
            err.response.data.message !== undefined &&
            err.response.data.message.includes("mobile_number")
          ) {
            setPhoneErrorMsg("Please enter a valid phone number");
            setPhoneError(true);
          }
        }
        // if (err.response.status === 404) {
        //   setEmailTextError(true);
        //   setUsernameTextError(true);
        // }
      });
  };

  const handleClickSignup: React.FormEventHandler<HTMLFormElement> = (
    event
  ) => {
    event.preventDefault();
    if (emailRegex.test(emailText)) {
      setEmailTextError(false);
      if (passwordRegex.test(passwordText)) {
        setPasswordTextError(false);
        if (passwordText === confirmPasswordText) {
          setConfirmPasswordTextError(false);
          handleSignup();
        } else {
          setConfirmPasswordTextError(true);
        }
      } else {
        setPasswordTextError(true);
      }
    } else {
      setEmailTextErrorMsg("Please enter a valid email address");
      setEmailTextError(true);
    }
  };

  return (
    <>
      <Box display="flex" mb="32px">
        <IconButton
          sx={{ position: "absolute" }}
          onClick={props.handleClickBack}
        >
          <img src={arrow_left_circle} alt="" />
        </IconButton>
        <Typography variant="h4" flexGrow="1" textAlign="center">
          Sign up
        </Typography>
      </Box>
      <form onSubmit={handleClickSignup}>
        <Stack spacing={3} mb="32px">
          <CustomInputField
            enableDescription={true}
            description="Username"
            descriptionTextColor="text.secondary"
            placeholder="Choose your username"
            value={usernameText}
            mandatory={true}
            type="text"
            disabled={false}
            fullWidth={true}
            onChange={handleUsernameChange}
            error={usernameTextError}
            errorText="This username is already taken."
          />
          <CustomInputField
            enableDescription={true}
            description="Email"
            descriptionTextColor="text.secondary"
            placeholder="Add your email address"
            value={emailText}
            mandatory={true}
            type="text"
            disabled={false}
            fullWidth={true}
            onChange={handleEmailChange}
            error={emailTextError}
            errorText={emailTextErrorMsg}
          />

          <CustomPhoneField
            value={phone}
            onChange={handlePhoneChange}
            isValid={!phoneError}
            errorText={phoneErrorMsg}
          />

          <CustomInputPassword
            enableDescription={true}
            description="Create a password"
            value={passwordText}
            onChange={handlePasswordChange}
            error={passwordTextError}
            errorText="Password must be at least 8 characters long. A combination of at least one uppercase letter, one lowercase letter, and one number or symbol"
          />
          <CustomInputPassword
            enableDescription={true}
            description="Confirm password"
            value={confirmPasswordText}
            onChange={handlePasswordConfirmChange}
            error={confirmPasswordTextError}
            errorText="Passwords don’t match"
          />
          <CustomButton
            variant="contained"
            fullWidth={true}
            type="submit"
            disabled={
              usernameText === "" ||
              emailText === "" ||
              phone === "" ||
              passwordText === "" ||
              confirmPasswordText === ""
            }
          >
            <Typography paddingY="12px" fontWeight="500">
              Create Account
            </Typography>
          </CustomButton>
        </Stack>
      </form>
    </>
  );
}

export default SignupForm;
