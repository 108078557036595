import { Button, IconButton, Stack, Typography } from "@mui/material";
import { UserInfo } from "../../utils/types/interfaces";
import DefaultCard from "../Base/DefaultCard";
import { UserCategory } from "../../utils/types/enums";
import CustomRoundedRow from "../Base/CustomRoundedRow";
import { getTokensIssued } from "../../utils/mockup/tokenisation/getTokensIssued";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { getTokensInProcess } from "../../utils/mockup/tokenisation/getTokensInProcess";
import { getPreIssuanceFixedIncomeBond } from "../../routes/hytokenisation/fibt/fixedIncomeBondToken";
import { useRequestWithoutParams } from "../../hooks/useAxiosRequest";

export interface TokenisationPlatformViewProps {
  dataGetUserInfo: UserInfo | null;
  onClickTokenIssued: any;
  onClickTokenInProcess: any;
  onClickTokenAvailable: any;
  onClickNewIssuance: any;
}

const TokenisationPlatformView = (props: TokenisationPlatformViewProps) => {
  const {
    //cancel: cancelGetTokenInProcess,
    data: dataGetTokenInProcess,
    //error: errorGetTokenInProcess,
    //loaded: loadedGetTokenInProcess,
  } = useRequestWithoutParams(getPreIssuanceFixedIncomeBond);

  const userCategory = props.dataGetUserInfo?.category;
  const professional = userCategory === UserCategory.PROFESSIONAL;
  const disabled =
    userCategory === undefined || userCategory === UserCategory.RETAIL;

  const tokenIssued = getTokensIssued();
  const tokenAvailable = getTokensInProcess();
  const tokenInProcess =
    dataGetTokenInProcess !== null
      ? [
          ...dataGetTokenInProcess.as_both,
          ...dataGetTokenInProcess.as_issuer,
          ...dataGetTokenInProcess.as_product_provider,
          ...dataGetTokenInProcess.as_none,
        ]
      : [];
  return (
    <Stack direction="column" spacing={4}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h4" color="black">
          {"Tokenisation Platform"}
        </Typography>
        {professional && (
          <Button
            variant="contained"
            color="primary"
            component="label"
            onClick={props.onClickNewIssuance}
          >
            {"Issue new token"}
          </Button>
        )}
      </Stack>
      <DefaultCard
        title={professional ? "Tokens in Process" : "Tokens available"}
      >
        <Stack
          direction="column"
          spacing={1}
          width="100%"
          maxHeight={"180px"}
          overflow={"auto"}
        >
          {/* Tokens in pre-issuance status - mockup data */}
          {tokenAvailable.map((value) => {
            return (
              <CustomRoundedRow disabled={disabled} key={value.id}>
                <Typography variant="body1">{value.asset}</Typography>
                <IconButton
                  color="primary"
                  aria-label="token details"
                  onClick={() => props.onClickTokenAvailable(value.id)}
                >
                  <ChevronRightRoundedIcon />
                </IconButton>
              </CustomRoundedRow>
            );
          })}
          {/* Tokens in pre-issuance status */}
          {tokenInProcess.map((value) => {
            return (
              <CustomRoundedRow
                disabled={disabled}
                key={
                  "" + value.name + value.issuer_id + value.product_provider_id
                }
              >
                <Typography variant="body1">{value.name}</Typography>
                <IconButton
                  color="primary"
                  aria-label="token details"
                  onClick={() => props.onClickTokenInProcess(value)}
                >
                  <ChevronRightRoundedIcon />
                </IconButton>
              </CustomRoundedRow>
            );
          })}
        </Stack>
      </DefaultCard>
      <DefaultCard title="Tokens issued">
        {/* Tokens in issued status - mockup data */}
        <Stack
          direction="column"
          spacing={1}
          width="100%"
          maxHeight={"180px"}
          overflow={"auto"}
        >
          {tokenIssued.map((value) => {
            return (
              <CustomRoundedRow disabled={disabled} key={value.id}>
                <Typography variant="body1">{value.asset}</Typography>
                <IconButton
                  color="primary"
                  aria-label="token details"
                  onClick={() => props.onClickTokenIssued(value.id)}
                >
                  <ChevronRightRoundedIcon />
                </IconButton>
              </CustomRoundedRow>
            );
          })}
        </Stack>
      </DefaultCard>
    </Stack>
  );
};

export default TokenisationPlatformView;
