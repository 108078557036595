import axios, { AxiosResponse } from "axios";
import { HyLabsResponse } from "../../../utils/types/interfaces";
import {
  HYNETWORK_AXIOS_CONFIG_JSON_CONTENT,
  HyLabsResponseDataUserId,
} from "../../util";

export function upgradeAccreditedStatus(): Promise<HyLabsResponseDataUserId> {
  return axios
    .post(
      `/hy-did/accredited-status`,
      undefined,
      HYNETWORK_AXIOS_CONFIG_JSON_CONTENT
    )
    .then((response: AxiosResponse): HyLabsResponseDataUserId => {
      const hynetwork_response: HyLabsResponse = response.data;
      const user_id: HyLabsResponseDataUserId = hynetwork_response.data;
      return user_id;
    })
    .catch((err) => {
      throw err;
    });
}
