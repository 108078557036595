import { Button, Stack, Typography } from "@mui/material";
import CustomRoundedRow from "../../Base/CustomRoundedRow";
import { ReactComponent as DoneIcon } from "../../../assets/images/green-check-circle.svg";
import { ReactComponent as UploadIcon } from "../../../assets/images/white-upload.svg";
import { ReactComponent as UpdateIcon } from "../../../assets/images/refresh-cw.svg";
import { ReactComponent as MissingIcon } from "../../../assets/images/gray-minus-circle.svg";
import { ProductDocument } from "../../../utils/types/interfaces";

export interface UploadProductDocumentRowProps {
  title: string;
  file?: ProductDocument;
  handleFileChange: (event: any) => void;
}

const UploadProductDocumentRow = (props: UploadProductDocumentRowProps) => {
  return (
    <CustomRoundedRow disabled={props.file === undefined}>
      <Stack direction="row" spacing={1}>
        {props.file === undefined ? <MissingIcon /> : <DoneIcon />}
        <Typography variant="h6" color="black" boxSizing="border-box">
          {props.title}
        </Typography>
      </Stack>
      <Button
        variant={props.file === undefined ? "contained" : "outlined"}
        color="primary"
        endIcon={
          props.file === undefined ? (
            <UploadIcon color="white" />
          ) : (
            <UpdateIcon />
          )
        }
        component="label"
      >
        {props.file === undefined ? "Upload" : "Update"}
        <input
          type="file"
          accept=".pdf"
          hidden
          onChange={props.handleFileChange}
        />
      </Button>
    </CustomRoundedRow>
  );
};

export default UploadProductDocumentRow;
