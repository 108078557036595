import axios, { AxiosResponse } from "axios";
import { HyLabsResponse, UserIdentifier } from "../../utils/types/interfaces";
import { HYNETWORK_AXIOS_CONFIG_NO_CREDENTIAL_JSON_CONTENT } from "../util";

export function resetPassword(data: UserIdentifier): Promise<{}> {
  return axios
    .post(
      "/hy-network/reset/password",
      data,
      HYNETWORK_AXIOS_CONFIG_NO_CREDENTIAL_JSON_CONTENT
    )
    .then((response: AxiosResponse) => {
      const hynetwork_response: HyLabsResponse = response.data;
      return hynetwork_response.data;
    })
    .catch((err) => {
      throw err;
    });
}
