import axios, { AxiosResponse } from "axios";
import {
  GetUserOnboardingStatus,
  HyLabsResponse,
  Individual,
  Institution,
  ProfessionalInstitution,
} from "../../utils/types/interfaces";
import {
  HyLabsResponseDataUserId,
  HYNETWORK_AXIOS_CONFIG_JSON_CONTENT,
} from "../util";

export function getUserOnboardingStatus(): Promise<GetUserOnboardingStatus> {
  return axios
    .get("/hy-network/onboarding", HYNETWORK_AXIOS_CONFIG_JSON_CONTENT)
    .then((response: AxiosResponse): GetUserOnboardingStatus => {
      const hynetwork_response: HyLabsResponse = response.data;
      const status_response: GetUserOnboardingStatus = hynetwork_response.data;
      return status_response;
    })
    .catch((err) => {
      throw err;
    });
}

export function onboardIndividual(
  data: Individual
): Promise<HyLabsResponseDataUserId> {
  return axios
    .post(
      "/hy-network/onboarding/individual",
      data,
      HYNETWORK_AXIOS_CONFIG_JSON_CONTENT
    )
    .then((response: AxiosResponse): HyLabsResponseDataUserId => {
      const hynetwork_response: HyLabsResponse = response.data;
      const user_id_response: HyLabsResponseDataUserId =
        hynetwork_response.data;
      return user_id_response;
    })
    .catch((err) => {
      throw err;
    });
}

export function onboardInstitution(
  data: Institution
): Promise<HyLabsResponseDataUserId> {
  return axios
    .post(
      "/hy-network/onboarding/institution",
      data,
      HYNETWORK_AXIOS_CONFIG_JSON_CONTENT
    )
    .then((response: AxiosResponse): HyLabsResponseDataUserId => {
      const hynetwork_response: HyLabsResponse = response.data;
      const user_id_response: HyLabsResponseDataUserId =
        hynetwork_response.data;
      return user_id_response;
    })
    .catch((err) => {
      throw err;
    });
}

export function onboardProfessionalInstitution(
  data: ProfessionalInstitution
): Promise<HyLabsResponseDataUserId> {
  return axios
    .post(
      "/hy-network/onboarding/professional-institution",
      data,
      HYNETWORK_AXIOS_CONFIG_JSON_CONTENT
    )
    .then((response: AxiosResponse): HyLabsResponseDataUserId => {
      const hynetwork_response: HyLabsResponse = response.data;
      const user_id_response: HyLabsResponseDataUserId =
        hynetwork_response.data;
      return user_id_response;
    })
    .catch((err) => {
      throw err;
    });
}
