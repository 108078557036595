import React, { useState } from "react";
import CustomInputField from "../../components/Base/CustomInputField";
import { Alert, Checkbox, Grid, Snackbar, Stack, Typography } from "@mui/material";
import { onboardIndividual } from "../../routes/hynetwork/onboarding";
import { EntityDocument } from "../../utils/types/interfaces";
import { DocumentExtension, DocumentType } from "../../utils/types/enums";
import UploadFile from "../../components/Intro/Activation/UploadFile";
import CustomButton from "../../components/Base/CustomButton";
import { useNavigate } from "react-router-dom";
import { useRequestWithoutParams } from "../../hooks/useAxiosRequest";
import { getUserInfo } from "../../routes/hynetwork/getUser";
import { pdfToImgBase64 } from "../../utils/functions/pdfToImgBase64";
import { bytesToBase64 } from "../../utils/functions/bytesToBase64";

function VerificationKYC() {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");

  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [postCode, setPostCode] = useState("");
  const [country, setCountry] = useState("");

  const [passportFile, setPassportFile] = useState<EntityDocument | undefined>(
    undefined
  );
  const [addressFile, setAddressFile] = useState<EntityDocument | undefined>(
    undefined
  );

  const [agreed, setAgreed] = useState(false);

  const [showError, setShowError] = useState(false);

  const handleCloseSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowError(false);
  };

  const {
    //cancel: cancelGetUserInfo,
    data: dataGetUserInfo,
    error: errorGetUserInfo,
    //loaded: loadedGetUserInfo,
  } = useRequestWithoutParams(getUserInfo);
  if (errorGetUserInfo !== null) {
    navigate("/auth/login");
  }

  //DISCLAIMER: some are example data
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    const form_data = {
      first_name: firstName,
      last_name: lastName,
      phone_number: dataGetUserInfo!.mobile_number,
      agree_on_checks: agreed,
      income: 2000,
      one_off_income: 2000,
      expenses: 2000,
      one_off_expenses: 2000,
      assets: {
        cash: 1,
        investments: 1,
        property: 1,
        pension: 1,
        other: 1,
      },
      liabilities: {
        credit_cards: 1,
        loans: 1,
        mortgage: 1,
        other: 1,
      },
      pep: true,
      accredited_status: false,
      external_api: {
        external_id: "id",
        sanction_check: true,
        api_provider: 12,
      },
      documents: [passportFile!, addressFile!],
    };
    onboardIndividual(form_data)
      .then(() => {
        navigate("/");
      })
      .catch((err) => {
        if (err.response && err.response.status === 500) {
          //High probability that document exists in the database, i.e., it is not unique
          setShowError(true);
        }
      });
  };

  const handleFirstNameChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setLastName(event.target.value);
  };

  const handleDateOfBirthChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setDateOfBirth(event.target.value);
  };

  const handleAddress1Change: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setAddress1(event.target.value);
  };

  const handleAddress2Change: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setAddress2(event.target.value);
  };

  const handlePostCodeChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setPostCode(event.target.value);
  };

  const handleCountryChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setCountry(event.target.value);
  };

  const handlePassportButton = (event: any) => {
    if (event && event.target && event.target.files[0] !== undefined) {
      handlePassportChange(event.target.files[0]);
    }
  };

  const handlePassportChange = (mFile: File) => {
    mFile.arrayBuffer().then((value: ArrayBuffer) => {
      pdfToImgBase64(value)
        .then((img) => {
          setPassportFile({
            bytes: bytesToBase64(value),
            kind: DocumentType.Id,
            extension: DocumentExtension.PDF,
            img: img,
          });
        })
        .catch((err) => console.log(err));
    });
  };

  const handleAddressButton = (event: any) => {
    if (event && event.target && event.target.files[0] !== undefined) {
      handleAddressChange(event.target.files[0]);
    }
  };

  const handleAddressChange = (mFile: File) => {
    mFile.arrayBuffer().then((value: ArrayBuffer) => {
      pdfToImgBase64(value).then((img) => {
        setAddressFile({
          bytes: bytesToBase64(value),
          kind: DocumentType.Address,
          extension: DocumentExtension.PDF,
          img: img,
        });
      });
    });
  };

  const handleAgreedChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setAgreed(event.target.checked);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Stack spacing={4} alignItems="end">
          <Grid container spacing={2} width="869px">
            <Grid item xs={12} sm={6}>
              <CustomInputField
                enableDescription={true}
                description="First name"
                descriptionTextColor="text.secondary"
                placeholder=""
                value={firstName}
                mandatory={true}
                type="text"
                disabled={false}
                fullWidth={true}
                onChange={handleFirstNameChange}
                error={false}
                errorText=""
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomInputField
                enableDescription={true}
                description="Address line 1"
                descriptionTextColor="text.secondary"
                placeholder=""
                value={address1}
                mandatory={true}
                type="text"
                disabled={false}
                fullWidth={true}
                onChange={handleAddress1Change}
                error={false}
                errorText=""
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomInputField
                enableDescription={true}
                description="Last name"
                descriptionTextColor="text.secondary"
                placeholder=""
                value={lastName}
                mandatory={true}
                type="text"
                disabled={false}
                fullWidth={true}
                onChange={handleLastNameChange}
                error={false}
                errorText=""
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomInputField
                enableDescription={true}
                description="Address line 2"
                descriptionTextColor="text.secondary"
                placeholder=""
                value={address2}
                mandatory={true}
                type="text"
                disabled={false}
                fullWidth={true}
                onChange={handleAddress2Change}
                error={false}
                errorText=""
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomInputField
                enableDescription={true}
                description="Date of birth"
                descriptionTextColor="text.secondary"
                placeholder="dd/mm/yyyy"
                value={dateOfBirth}
                mandatory={true}
                type="date"
                disabled={false}
                fullWidth={true}
                onChange={handleDateOfBirthChange}
                error={false}
                errorText="Please enter a valid date of birth: dd/mm/yyyy"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomInputField
                enableDescription={true}
                description="Post code"
                descriptionTextColor="text.secondary"
                placeholder=""
                value={postCode}
                mandatory={true}
                type="text"
                disabled={false}
                fullWidth={true}
                onChange={handlePostCodeChange}
                error={false}
                errorText="Please enter a valid post code"
              />
            </Grid>
            <Grid item xs={12} sm={6} />
            <Grid item xs={12} sm={6}>
              <CustomInputField
                enableDescription={true}
                description="Country"
                descriptionTextColor="text.secondary"
                placeholder=""
                value={country}
                mandatory={true}
                type="text"
                disabled={false}
                fullWidth={true}
                onChange={handleCountryChange}
                error={false}
                errorText=""
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <UploadFile
                file={passportFile}
                onChange={handlePassportButton}
                description="Passport"
              />
            </Grid>
            <Grid item xs={12}>
              <UploadFile
                file={addressFile}
                onChange={handleAddressButton}
                description="Address"
              />
            </Grid>
          </Grid>
          <Stack direction="row" spacing={2} alignItems="center" width="100%">
            <Checkbox value={agreed} onChange={handleAgreedChange} />
            <Typography>Agree to KYC checks</Typography>
          </Stack>
          <CustomButton
            variant="contained"
            type="submit"
            disabled={
              firstName === "" ||
              lastName === "" ||
              dateOfBirth === "" ||
              address1 === "" ||
              address2 === "" ||
              country === "" ||
              postCode === "" ||
              !agreed ||
              passportFile === undefined ||
              addressFile === undefined
            }
          >
            <Typography
              paddingY="12px"
              paddingX="27.5px"
              fontWeight="500"
              fontSize="18px"
            >
              Submit
            </Typography>
          </CustomButton>
        </Stack>
      </form>
      <Snackbar
        open={showError}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          <Typography variant="body1">
            Error! Exact same document already exists in HyNetwork.
          </Typography>
        </Alert>
      </Snackbar>
    </>
  );
}

export default VerificationKYC;
