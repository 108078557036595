import { Stack, Typography } from "@mui/material";
import { getRequests } from "../../../utils/mockup/admin/getRequests";
import DefaultCard from "../../Base/DefaultCard";
import CustomRoundedRow, {
  CustomRoundedRowColor,
} from "../../Base/CustomRoundedRow";

const CMSDashboardBox = () => {
  const requests = getRequests();
  return (
    <DefaultCard title="CMS Dashboard">
      <Typography variant="h6" gutterBottom>
        Requests
      </Typography>
      <Stack direction={"column"} spacing={1} width="100%">
        {requests.map((value) => {
          return (
            <CustomRoundedRow
              key={value.id}
              rowColor={
                value.severity === 0
                  ? CustomRoundedRowColor.Green
                  : CustomRoundedRowColor.Yellow
              }
            >
              <Typography variant="h6">{value.text}</Typography>
            </CustomRoundedRow>
          );
        })}
      </Stack>
    </DefaultCard>
  );
};

export default CMSDashboardBox;
