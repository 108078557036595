import axios, { AxiosResponse } from "axios";
import {
  DeleteDocument,
  EntityDocument,
  HyLabsResponse,
  QueryExpiringIn,
  UpdateDocument,
  UserDocuments,
} from "../../../utils/types/interfaces";
import { HYNETWORK_AXIOS_CONFIG_JSON_CONTENT } from "../../util";

export function getUserDocumentsLight(user_id: string): Promise<UserDocuments> {
  return axios
    .get(`/hy-did/documents/${user_id}`, HYNETWORK_AXIOS_CONFIG_JSON_CONTENT)
    .then((response: AxiosResponse): UserDocuments => {
      const hynetwork_response: HyLabsResponse = response.data;
      const documents: UserDocuments = hynetwork_response.data;
      return documents;
    })
    .catch((err) => {
      throw err;
    });
}

export function getUserDocument(document_id: string): Promise<EntityDocument> {
  return axios
    .get(
      `/hy-did/documents/d/${document_id}`,
      HYNETWORK_AXIOS_CONFIG_JSON_CONTENT
    )
    .then((response: AxiosResponse): EntityDocument => {
      const hynetwork_response: HyLabsResponse = response.data;
      const document: EntityDocument = hynetwork_response.data;
      return document;
    })
    .catch((err) => {
      throw err;
    });
}

export function postUserDocument(
  data: EntityDocument
): Promise<string> {
  return axios
    .post(`/hy-did/documents`, data, HYNETWORK_AXIOS_CONFIG_JSON_CONTENT)
    .then((response: AxiosResponse): string => {
      const hynetwork_response: HyLabsResponse = response.data;
      const document: string = hynetwork_response.data;
      return document;
    })
    .catch((err) => {
      throw err;
    });
}

export function updateUserDocument(
  data: UpdateDocument
): Promise<string | null | undefined> {
  return axios
    .put(`/hy-did/documents`, data, HYNETWORK_AXIOS_CONFIG_JSON_CONTENT)
    .then((response: AxiosResponse): string | null | undefined => {
      const hynetwork_response: HyLabsResponse = response.data;
      const document_id: string | null | undefined = hynetwork_response.data;
      return document_id;
    })
    .catch((err) => {
      throw err;
    });
}

export function deleteUserDocument(
  data: DeleteDocument
): Promise<EntityDocument> {
  const deleteConfig = {
    ...HYNETWORK_AXIOS_CONFIG_JSON_CONTENT,
    data: data,
    method: "delete",
    url: "/hy-did/documents",
  };
  return axios(deleteConfig)
    .then((response: AxiosResponse): EntityDocument => {
      const hynetwork_response: HyLabsResponse = response.data;
      const document: EntityDocument = hynetwork_response.data;
      return document;
    })
    .catch((err) => {
      throw err;
    });
}

export function getExpiringUserDocumentsLight(
  query?: QueryExpiringIn
): Promise<UserDocuments> {
  return axios
    .get(
      `/hy-did/documents/expire?in-days${
        query !== null &&
        query !== undefined &&
        query.in_days >= 0 &&
        query.in_days <= 65535
          ? query.in_days
          : 0
      }`,
      HYNETWORK_AXIOS_CONFIG_JSON_CONTENT
    )
    .then((response: AxiosResponse): UserDocuments => {
      const hynetwork_response: HyLabsResponse = response.data;
      const document: UserDocuments = hynetwork_response.data;
      return document;
    })
    .catch((err) => {
      throw err;
    });
}
