import { useEffect, useRef, useState } from "react";

const useRequestWithParams = <T, P>(
  axiosRequest: (data: P) => Promise<T>,
  requestParam: P
) => {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<any | null>(null);
  const [loaded, setLoaded] = useState(false);
  const controllerRef = useRef(new AbortController());

  const cancel = () => {
    controllerRef.current.abort();
  };

  useEffect(() => {
    axiosRequest(requestParam)
      .then((result: T) => setData(result))
      .catch((error: any) => setError(error))
      .finally(() => setLoaded(true));
  }, [axiosRequest, requestParam]);

  return { cancel, data, error, loaded };
};

export interface UseRequestWithoutParamsParams {
  requireTimeout: boolean;
}

const useRequestWithoutParams = <T>(
  axiosRequest: () => Promise<T>,
  props?: UseRequestWithoutParamsParams
) => {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<any | null>(null);
  const [loaded, setLoaded] = useState(false);
  const controllerRef = useRef(new AbortController());

  const cancel = () => {
    controllerRef.current.abort();
  };

  useEffect(() => {
    axiosRequest()
      .then((result: T) => setData(result))
      .catch((error: any) => setError(error))
      .finally(() => {
        if (props && props.requireTimeout) {
          setTimeout(async function () {
            setLoaded(true);
          }, 500);
        } else {
          setLoaded(true);
        }
      });
  }, [axiosRequest, props]);

  return { cancel, data, error, loaded };
};

export { useRequestWithParams, useRequestWithoutParams };
