import {
  Button,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import { ReactComponent as UpdateIcon } from "../../../assets/images/refresh-cw.svg";
import CustomTable from "../../Base/CustomTable";
import { Signatories } from "../../../utils/types/interfaces";

export interface SignatoriesTableProps {
  data: Signatories[];
}

const SignatoriesTable = (props: SignatoriesTableProps) => {
  return (
    <>
      <CustomTable stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email Address</TableCell>
            <TableCell>Type</TableCell>
            <TableCell style={{ textAlign: "right" }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((value) => {
            return (
              <TableRow key={value.id}>
                <TableCell>{value.name}</TableCell>
                <TableCell>{value.email_address}</TableCell>
                <TableCell>{value.type}</TableCell>
                <TableCell style={{ textAlign: "right" }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    endIcon={<UpdateIcon />}
                    component="label"
                  >
                    Update
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </CustomTable>
    </>
  );
};

export default SignatoriesTable;
