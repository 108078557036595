import {
  Button,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CustomTable from "../../Base/CustomTable";
import { FiatAsset } from "../../../utils/mockup/wallet/getFiatAssets";
import { currencyFormatter } from "../../../utils/functions/currencyFormatter";

export interface FiatBoxProps {
  fiatAssets: FiatAsset[];
}

const FiatBox = (props: FiatBoxProps) => {
  const totalFiatAssets = props.fiatAssets.reduce((a: number, b: FiatAsset) => {
    return a + b.value;
  }, 0);
  return (
    <Stack direction="column" spacing={4} width="100%">
      <CustomTable>
        <TableHead>
          <TableRow>
            <TableCell>Asset</TableCell>
            <TableCell align="right">Available</TableCell>
            <TableCell align="right">Pledged</TableCell>
            <TableCell align="right">Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.fiatAssets.map((value) => {
            return (
              <TableRow key={value.id}>
                <TableCell>{value.asset}</TableCell>
                <TableCell align="right">
                  {currencyFormatter(value.available, value.asset)}
                </TableCell>
                <TableCell align="right">
                  {currencyFormatter(value.pledged, value.asset)}
                </TableCell>
                <TableCell align="right">
                  {currencyFormatter(value.value, value.asset)}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </CustomTable>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="end"
        alignItems="center"
      >
        <Typography variant="h5" color="black">
          {`Total fiat: ${currencyFormatter(totalFiatAssets)}`}
        </Typography>
        <Button variant="contained" color="primary" component="label">
          Deposit
        </Button>
        <Button variant="contained" color="primary" component="label">
          Withdraw
        </Button>
      </Stack>
    </Stack>
  );
};

export default FiatBox;
