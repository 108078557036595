import { Link, Stack, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import React from "react";
import theme from "../../theme";

function QuestionnaireFooter() {
  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="center"
      alignItems="center"
      height="72px"
      width="100%"
      borderTop={1}
      borderColor={theme.palette.grey.A200}
      position="absolute"
      bottom="0"
    >
      <Link
        component={RouterLink}
        to={"https://hylabs.io/terms/"}
        target={"_blank"}
        underline="hover"
        color={theme.palette.secondary.main}
      >
        <Typography>Terms & Conditions</Typography>
      </Link>
      <Link
        component={RouterLink}
        to={"https://hylabs.io"}
        target={"_blank"}
        underline="hover"
        color={theme.palette.secondary.main}
      >
        <Typography>Home page</Typography>
      </Link>
      <Link
        component={RouterLink}
        to={"mailto:contact@hylabs.io?subject=HyLabs%20Network%20Info"}
        target={"_blank"}
        underline="hover"
        color={theme.palette.secondary.main}
      >
        <Typography>Get in touch</Typography>
      </Link>
    </Stack>
  );
}

export default QuestionnaireFooter;
