import React from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { InputProps, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import theme from "../../theme";

interface CustomInputFieldProps {
  enableDescription: boolean;
  description: string;
  descriptionTextColor: string;
  placeholder: string;
  value: string | number;
  mandatory: boolean;
  type: string;
  className?: string;
  InputProps?: Partial<InputProps>;
  inputProps?: Partial<InputProps>;
  pattern?: string;
  disabled: boolean;
  fullWidth: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  error: boolean;
  errorText: string;
}

const CustomStyleTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.grey.A200,
      borderRadius: "8px",
    },
  },
}));

function CustomInputField(props: CustomInputFieldProps) {
  return (
    <Stack spacing={0.5}>
      {props.enableDescription && (
        <Typography color={props.descriptionTextColor} variant="body2">
          {props.description}
          {props.mandatory && <sup>*</sup>}
        </Typography>
      )}
      <CustomStyleTextField
        key={props.description}
        type={props.type}
        disabled={props.disabled}
        placeholder={props.placeholder}
        value={props.value}
        error={props.error}
        onChange={props.onChange}
        fullWidth={props.fullWidth}
        className={props.className ? props.className : ""}
        InputProps={props.InputProps ? props.InputProps : undefined}
        inputProps={
          props.inputProps
            ? { inputProps: props.inputProps, pattern: props.pattern }
            : {}
        }
        sx={
          props.disabled
            ? { backgroundColor: "#EBEBEB", color: "red !important" }
            : {}
        }
      />
      <Typography variant="body2" color={theme.palette.error.main}>
        {props.error ? props.errorText : ""}
      </Typography>
    </Stack>
  );
}

export default CustomInputField;
