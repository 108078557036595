import React, { ReactElement } from "react";
import { NavLink, useLoaderData } from "react-router-dom";
import Hylabs_blue from "../../assets/images/Hylabs_blue.svg";
import { ReactComponent as DashboardIcon } from "../../assets/images/dashboard.svg";
import { ReactComponent as UserIcon } from "../../assets/images/user_blue.svg";
import { ReactComponent as UsersIcon } from "../../assets/images/users.svg";
import { ReactComponent as WalletIcon } from "../../assets/images/wallet.svg";
import { ReactComponent as HexagonIcon } from "../../assets/images/hexagon.svg";
import { Box, Icon, Stack, Typography } from "@mui/material";
import theme from "../../theme";
import { UserKind } from "../../utils/types/enums";
import { UserInfo } from "../../utils/types/interfaces";

interface NavItem {
  path: string;
  label: string;
  icon: ReactElement<any, any>;
}

const navListItemsUser: NavItem[] = [
  {
    path: "",
    label: "Dashboard",
    icon: <DashboardIcon />,
  },
  {
    path: "/digital-id",
    label: "Digital ID",
    icon: <UserIcon />,
  },
  {
    path: "/digital-wallet",
    label: "Digital Wallet",
    icon: <WalletIcon />,
  },
  {
    path: "/tokenisation-platform",
    label: "Tokenisation Platform",
    icon: <HexagonIcon />,
  },
];

const navListItemsAdmin: NavItem[] = [
  {
    path: "",
    label: "Dashboard",
    icon: <DashboardIcon />,
  },
  {
    path: "/user-directory",
    label: "User directory",
    icon: <UsersIcon />,
  },
];

interface NavListProps {
  navListItems: NavItem[];
}

function NavList(props: NavListProps) {
  return (
    <Stack direction="row" spacing={3} justifyContent="center" my="auto">
      {props.navListItems.map(({ path, label, icon }) => (
        <NavLink
          key={path}
          to={path}
          style={{
            textDecoration: "none",
          }}
          className={({ isActive }) => (isActive ? "" : "opacity-60")}
        >
          <Box display="flex">
            <Icon>{icon}</Icon>
            <Typography
              minWidth="fit-content"
              fontWeight="500"
              color={theme.palette.primary.main}
              ml="8px"
            >
              {label}
            </Typography>
          </Box>
        </NavLink>
      ))}
    </Stack>
  );
}

function Header() {
  const userInfo = useLoaderData() as UserInfo;
  return (
    <Stack direction="row" justifyContent="start" py="20px" px="64px">
      <Box mr="auto" width="fit-content">
        <img src={Hylabs_blue} alt="HyLabs Logo" />
      </Box>
      <NavList
        navListItems={
          userInfo
            ? userInfo.kind === UserKind.ADMIN
              ? navListItemsAdmin
              : navListItemsUser
            : []
        }
      />
    </Stack>
  );
}

export default Header;
