import React from "react";
import ReactPhoneInput from "react-phone-input-material-ui";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import "react-phone-input-material-ui/lib/style.css";
import { styled } from "@mui/material/styles";
import { Stack, Typography } from "@mui/material";
import theme from "../../theme";

interface CustomPhoneFieldProps {
  value: string;
  defaultCountry?: string;
  classes?: string;
  onChange: (s: string) => void;
  isValid: boolean;
  errorText: string;
}

const CustomStyleTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.grey.A200,
      borderRadius: "8px",
      height: "56px",
    },
  },
}));

function CustomPhoneField(props: CustomPhoneFieldProps) {
  return (
    <Stack>
        <ReactPhoneInput
          value={props.value}
          country={"gb"}
          onChange={props.onChange}
          component={CustomStyleTextField}
          dropdownClass="customPhoneFieldDropDown"
          isValid={props.isValid}
        />
        <Typography variant="body2" color={theme.palette.error.main}>{!props.isValid ? props.errorText : ''}</Typography>
    </Stack>
  );
}

export default CustomPhoneField;
