import React, { useContext, useState } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import CustomInputField from "../../components/Base/CustomInputField";
import CustomButton from "../../components/Base/CustomButton";
import CustomCheckbox from "../../components/Base/CustomCheckbox";
import { onboardInstitution } from "../../routes/hynetwork/onboarding";
import { AssetClassExperience } from "../../utils/types/enums";
import { useRequestWithoutParams } from "../../hooks/useAxiosRequest";
import { useNavigate } from "react-router-dom";
import { getUserInfo } from "../../routes/hynetwork/getUser";
import {
  KYBContext,
  KYB_CONTEXT_DELIMITER,
} from "../../context/KYBContextProvider";
import { Institution } from "../../utils/types/interfaces";

function VerificationKYB() {
  const navigate = useNavigate();

  const { setInstitution } = useContext(KYBContext);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [position, setPosition] = useState("");

  const [companyName, setCompanyName] = useState("");
  const [incNumber, setIncNumber] = useState("");
  const [incCountry, setIncCountry] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [postCode, setPostCode] = useState("");

  const [regulated, setRegulated] = useState(false);
  const [agreed, setAgreed] = useState(false);

  const {
    //cancel: cancelGetUserInfo,
    data: dataGetUserInfo,
    error: errorGetUserInfo,
    //loaded: loadedGetUserInfo,
  } = useRequestWithoutParams(getUserInfo);
  if (errorGetUserInfo !== null) {
    navigate("/auth/login");
  }

  //DISCLAIMER: some are example data
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    const form_data: Institution = {
      first_name: firstName,
      last_name: lastName,
      phone_number: dataGetUserInfo!.mobile_number,
      position: position,
      company_name: companyName,
      company_registration_number: incNumber,
      registered_address:
        address1 + ", " + address2 + ", " + incCountry + ", " + postCode,
      incorporation_date: "1980-01-01 T00:00:00Z",
      pep: true,
      revenue: 1,
      profit: 1,
      accredited_status: regulated,
      assets: {
        cash: 1,
        investments: 1,
        property: 1,
        pension: 1,
        other: 1,
      },
      liabilities: {
        credit_cards: 1,
        loans: 1,
        mortgage: 1,
        other: 1,
      },
      asset_class_experiences: {
        cash: AssetClassExperience.None,
        bonds: AssetClassExperience.None,
        equity: AssetClassExperience.None,
        private_equity: AssetClassExperience.None,
        hedge_funds: AssetClassExperience.None,
        digital_assets: AssetClassExperience.None,
        derivatives: AssetClassExperience.None,
      },
      external_api: {
        external_id: "id",
        sanction_check: true,
        api_provider: 12,
      },
      documents: [],
    };
    if (!regulated) {
      onboardInstitution(form_data)
        .then(() => {
          //display send successfully
          navigate("/");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      form_data.registered_address =
        address1 +
        KYB_CONTEXT_DELIMITER +
        address2 +
        KYB_CONTEXT_DELIMITER +
        incCountry +
        KYB_CONTEXT_DELIMITER +
        postCode;
      setInstitution?.(form_data);
      navigate("/questionnaire/summary");
    }
  };

  const handleFirstNameChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setLastName(event.target.value);
  };

  const handleDateOfBirthChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setDateOfBirth(event.target.value);
  };

  const handlePositionChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setPosition(event.target.value);
  };

  const handleCompanyNameChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setCompanyName(event.target.value);
  };

  const handleIncNumberChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setIncNumber(event.target.value);
  };

  const handleIncCountryChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setIncCountry(event.target.value);
  };

  const handleAddress1Change: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setAddress1(event.target.value);
  };

  const handleAddress2Change: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setAddress2(event.target.value);
  };

  const handlePostCodeChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setPostCode(event.target.value);
  };

  const handleRegulatedChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setRegulated(event.target.checked);
  };

  const handleAgreedChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setAgreed(event.target.checked);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={4} alignItems="end">
        <Grid container spacing={2} width="869px">
          <Grid item xs={12} sm={6}>
            <CustomInputField
              enableDescription={true}
              description="First name"
              descriptionTextColor="text.secondary"
              placeholder=""
              value={firstName}
              mandatory={true}
              type="text"
              disabled={false}
              fullWidth={true}
              onChange={handleFirstNameChange}
              error={false}
              errorText=""
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomInputField
              enableDescription={true}
              description="Company name"
              descriptionTextColor="text.secondary"
              placeholder=""
              value={companyName}
              mandatory={true}
              type="text"
              disabled={false}
              fullWidth={true}
              onChange={handleCompanyNameChange}
              error={false}
              errorText=""
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomInputField
              enableDescription={true}
              description="Last name"
              descriptionTextColor="text.secondary"
              placeholder=""
              value={lastName}
              mandatory={true}
              type="text"
              disabled={false}
              fullWidth={true}
              onChange={handleLastNameChange}
              error={false}
              errorText=""
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomInputField
              enableDescription={true}
              description="Incorporation Number"
              descriptionTextColor="text.secondary"
              placeholder=""
              value={incNumber}
              mandatory={true}
              type="text"
              disabled={false}
              fullWidth={true}
              onChange={handleIncNumberChange}
              error={false}
              errorText=""
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomInputField
              enableDescription={true}
              description="Date of birth"
              descriptionTextColor="text.secondary"
              placeholder="dd/mm/yyyy"
              value={dateOfBirth}
              mandatory={true}
              type="date"
              disabled={false}
              fullWidth={true}
              onChange={handleDateOfBirthChange}
              error={false}
              errorText="Please enter a valid date of birth: dd/mm/yyyy"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomInputField
              enableDescription={true}
              description="Country of Incorportation"
              descriptionTextColor="text.secondary"
              placeholder=""
              value={incCountry}
              mandatory={true}
              type="text"
              disabled={false}
              fullWidth={true}
              onChange={handleIncCountryChange}
              error={false}
              errorText=""
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomInputField
              enableDescription={true}
              description="Position"
              descriptionTextColor="text.secondary"
              placeholder=""
              value={position}
              mandatory={true}
              type="text"
              disabled={false}
              fullWidth={true}
              onChange={handlePositionChange}
              error={false}
              errorText=""
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomInputField
              enableDescription={true}
              description="Address line 1"
              descriptionTextColor="text.secondary"
              placeholder=""
              value={address1}
              mandatory={true}
              type="text"
              disabled={false}
              fullWidth={true}
              onChange={handleAddress1Change}
              error={false}
              errorText=""
            />
          </Grid>
          <Grid item xs={12} sm={6} />
          <Grid item xs={12} sm={6}>
            <CustomInputField
              enableDescription={true}
              description="Address line 2"
              descriptionTextColor="text.secondary"
              placeholder=""
              value={address2}
              mandatory={true}
              type="text"
              disabled={false}
              fullWidth={true}
              onChange={handleAddress2Change}
              error={false}
              errorText=""
            />
          </Grid>
          <Grid item xs={12} sm={6} />
          <Grid item xs={12} sm={6}>
            <CustomInputField
              enableDescription={true}
              description="Post code"
              descriptionTextColor="text.secondary"
              placeholder=""
              value={postCode}
              mandatory={true}
              type="text"
              disabled={false}
              fullWidth={true}
              onChange={handlePostCodeChange}
              error={false}
              errorText="Please enter a valid Incorporation Post code"
            />
          </Grid>
        </Grid>
        <CustomCheckbox
          value={regulated}
          onChange={handleRegulatedChange}
          text="Regulated firm"
        />
        <CustomCheckbox
          value={agreed}
          onChange={handleAgreedChange}
          text="Agree to KYB checks"
        />
        <CustomButton
          variant="contained"
          type="submit"
          disabled={
            firstName === "" ||
            lastName === "" ||
            dateOfBirth === "" ||
            position === "" ||
            companyName === "" ||
            incNumber === "" ||
            incCountry === "" ||
            address1 === "" ||
            address2 === "" ||
            postCode === "" ||
            !agreed
          }
        >
          <Typography
            paddingY="12px"
            paddingX="27.5px"
            fontWeight="500"
            fontSize="18px"
          >
            Submit
          </Typography>
        </CustomButton>
      </Stack>
    </form>
  );
}

export default VerificationKYB;
