export interface HistoryLog {
  id: string;
  operation: string;
  date: string;
}

export function getHistoryLogs(): HistoryLog[] {
  return [
    {
      id: "HISTORYLOG1",
      operation: "Logged in",
      date: "29.03.23 at 21:30",
    },
    {
      id: "HISTORYLOG2",
      operation: "Pledged against Bond XYZ",
      date: "29.03.23 at 21:30",
    },
    {
      id: "HISTORYLOG3",
      operation: "Deposited GBP",
      date: "30.03.23 at 08:12",
    },
  ];
}
