import { Box, Button, Stack, Typography } from "@mui/material";
import { UserCategory } from "../../../utils/types/enums";
import DefaultCard from "../../Base/DefaultCard";
import CustomRoundedRow from "../../Base/CustomRoundedRow";
import { PreIssuanceFixedIncomeBond } from "../../../utils/types/interfaces";

import { getTokensIssued } from "../../../utils/mockup/tokenisation/getTokensIssued";
import { getTokensInProcess } from "../../../utils/mockup/tokenisation/getTokensInProcess";

export interface TokenPlatformBoxProps {
  dataGetTokenInProcess: PreIssuanceFixedIncomeBond | null;
  loadedGetTokenInProcess: boolean;
  userCategory: UserCategory;
  handleOnClickView: any;
  handleOnClickDetailsMockup?: any;
  handleOnClickDetails?: any;
}

const TokenPlatformBox = (props: TokenPlatformBoxProps) => {
  const disabled = props.userCategory === UserCategory.RETAIL;

  const tokenIssued = getTokensIssued();
  const tokenAvailable = getTokensInProcess();
  const tokenInProcess =
    props.dataGetTokenInProcess !== null
      ? [
          ...props.dataGetTokenInProcess.as_both,
          ...props.dataGetTokenInProcess.as_issuer,
          ...props.dataGetTokenInProcess.as_product_provider,
          ...props.dataGetTokenInProcess.as_none,
        ]
      : [];

  return (
    <DefaultCard title="Token Platform">
      <Stack width={"100%"} overflow={"auto"} maxHeight={"180px"} spacing={1}>
        {tokenIssued.map((value) => {
          return (
            <CustomRoundedRow key={value.id}>
              <Typography variant="body1">{value.asset}</Typography>
              {!disabled && (
                <Button
                  color="primary"
                  variant="text"
                  onClick={() => props.handleOnClickDetailsMockup(value.id)}
                >
                  Details
                </Button>
              )}
            </CustomRoundedRow>
          );
        })}
        {tokenInProcess.map((value) => {
          return (
            <CustomRoundedRow
              key={
                "" + value.name + value.issuer_id + value.product_provider_id
              }
            >
              <Typography variant="body1">{value.name}</Typography>
              {!disabled && (
                <Button
                  color="primary"
                  variant="text"
                  onClick={() => props.handleOnClickDetails(value)}
                >
                  Details
                </Button>
              )}
            </CustomRoundedRow>
          );
        })}
        {/* Tokens in pre-issuance status - mockup data */}
        {tokenAvailable.map((value) => {
          return (
            <CustomRoundedRow key={value.id}>
              <Typography variant="body1">{value.asset}</Typography>
              {!disabled && (
                <Button
                  color="primary"
                  variant="text"
                  onClick={() => props.handleOnClickDetailsMockup(value.id)}
                >
                  Details
                </Button>
              )}
            </CustomRoundedRow>
          );
        })}
      </Stack>
      <Box
        paddingTop={"32px"}
        display="flex"
        justifyContent={"flex-end"}
        width="100%"
      >
        <Button onClick={props.handleOnClickView} variant="contained">
          <Typography variant="h6">{"View"}</Typography>
        </Button>
      </Box>
    </DefaultCard>
  );
};

export default TokenPlatformBox;
