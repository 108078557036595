import {
  Button,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CustomTable from "../../Base/CustomTable";
import { Asset } from "../../../utils/mockup/dashboard/getAssets";
import DefaultCard from "../../Base/DefaultCard";
import { currencyFormatter } from "../../../utils/functions/currencyFormatter";

export interface DigitalWalletBoxProps {
  assets: Asset[];
  handleOnClickManage: any;
}

const DigitalWalletBox = (props: DigitalWalletBoxProps) => {
  const totalAssets = props.assets.reduce((a: number, b: Asset) => {
    return a + b.value;
  }, 0);

  return (
    <DefaultCard title="Digital Wallet">
      <Stack direction="column" spacing={4} width="100%">
        <TableContainer sx={{ maxHeight: "200px", overflow: "auto" }}>
          <CustomTable stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Asset</TableCell>
                <TableCell style={{ textAlign: "right" }}>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.assets.map((value) => {
                return (
                  <TableRow key={value.id}>
                    <TableCell>{value.asset}</TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                      {currencyFormatter(value.value, value.currency)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </CustomTable>
        </TableContainer>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="end"
          alignItems="center"
        >
          <Typography variant="h5" color="black" textAlign="right">
            {`Total value: ${currencyFormatter(totalAssets)}`}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            component="label"
            onClick={props.handleOnClickManage}
          >
            Manage
          </Button>
        </Stack>
      </Stack>
    </DefaultCard>
  );
};

export default DigitalWalletBox;
