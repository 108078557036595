import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import Header from "../components/Home/Header";
import Footer from "../components/Home/Footer";
import "../style/home.css";
import { LinearProgress } from "@mui/material";

function HomeRoot() {
  return (
    <Box
      id="homeroot-box"
      minWidth="100vw"
      minHeight="100vh"
      className="home-background"
      display="flex"
      flexDirection="column"
    >
      <Suspense fallback={<LinearProgress sx={{ width: "100%" }} />}>
        <Header />
        <Box px="64px" height="auto" minHeight="100%" marginBottom="auto">
          <Box marginBottom="32px" marginTop="32px">
            <Outlet />
          </Box>
        </Box>
      </Suspense>
      <Footer />
    </Box>
  );
}

export default HomeRoot;
