import { LinearProgress } from "@mui/material";
import { useRequestWithoutParams } from "../../hooks/useAxiosRequest";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import UserDetailsView from "../../components/UserDirectory/UserDetailsView";
import { getAllUsers } from "../../routes/admin/getAllUsers";
import { UserInfo } from "../../utils/types/interfaces";
import UserDirectoryView from "../../components/UserDirectory/UserDirectoryView";

const UserDirectory = () => {
  const navigate = useNavigate();

  const [selectedUser, setSelectedUser] = useState<UserInfo | null>(null);

  const {
    //cancel: cancelGetUserInfo,
    data: dataGetAllUsers,
    error: errorGetAllUsers,
    //loaded: loadedAllUsers,
  } = useRequestWithoutParams(getAllUsers);

  if (errorGetAllUsers !== null) {
    navigate("/auth/login");
  }

  const handleOnClickManage = (value: UserInfo) => {
    setSelectedUser(value);
  };

  const handleOnClickBackButton = () => {
    setSelectedUser(null);
  };

  return (
    <>
      {dataGetAllUsers && selectedUser === null ? (
        <UserDirectoryView
          users={dataGetAllUsers}
          onClickManage={handleOnClickManage}
        />
      ) : selectedUser ? (
        <UserDetailsView
          user={selectedUser}
          onClickBackButton={handleOnClickBackButton}
        />
      ) : (
        <LinearProgress />
      )}
    </>
  );
};

export default UserDirectory;
