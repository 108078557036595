export interface TokenInProcess {
    id: string;
    asset: string;
  }
  
  export function getTokensInProcess(): TokenInProcess[] {
    return [
      {
        id: "ASSET4",
        asset: "HyLabs 7Yr Fixed 3.5% Green Bond",
      },
      {
        id: "ASSET5",
        asset: "Runtime 8Yr Variable SONIA+3% Bond",
      },
      {
        id: "ASSET6",
        asset: "HyLabs 15Yr Variable SONIA+3% Bond",
      },
    ];
  }