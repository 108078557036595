import {
  Swiper,
  SwiperSlide,
  SwiperProps,
  SwiperSlideProps,
} from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { EffectCoverflow, Pagination } from "swiper";

import { styled } from "@mui/material/styles";
import { DocumentPreview } from "../../../utils/types/interfaces";

export interface DocumentsSwiperProps {
  documents: DocumentPreview[];
  cardWidth?: string;
  cardHeight?: string;
}

const CustomSwiper = styled(Swiper)<SwiperProps>(({ theme }) => ({
  "--swiper-pagination-bullet-inactive-color": `${theme.palette.grey.A200}`,
  "--swiper-pagination-color": `${theme.palette.secondary.main}`,
  "--swiper-pagination-bullet-inactive-opacity": "1",
  "--swiper-pagination-bullet-border-radius": "7px",
  "--swiper-pagination-bullet-width": "80px",
  "--swiper-pagination-bullet-height": "5px",
  width: "100%",
  paddingBottom: "50px",
}));

const CustomSwiperSlide = styled(SwiperSlide)<SwiperSlideProps>(
  ({ theme }) => ({
    backgroundPosition: "center",

    "& img": {
      display: "block",
      width: "100%",
      height: "100%",
      border: `2px solid ${theme.palette.grey.A200}`,
      borderRadius: "16px",
    },
  })
);

const DocumentsSwiper = (props: DocumentsSwiperProps) => {
  return (
    <CustomSwiper
      effect={"coverflow"}
      rewind={true}
      grabCursor={true}
      centeredSlides={true}
      slidesPerView={"auto"}
      coverflowEffect={{
        rotate: 0,
        stretch: 0,
        depth: 15,
        modifier: 20,
        slideShadows: false,
      }}
      pagination={{
        clickable: true,
      }}
      modules={[EffectCoverflow, Pagination]}
      initialSlide={props.documents.length > 2 ? 1 : 0}
    >
      {props &&
        props.documents.map((value) => {
          return (
            <CustomSwiperSlide
              key={value.id}
              sx={{
                width: props.cardWidth ? props.cardWidth : "220px",
                height: props.cardHeight ? props.cardHeight : "310px",
              }}
            >
              {value.base64img ? (
                <img
                  key={value.id}
                  id={value.id}
                  src={value.base64img}
                  alt={value.id}
                ></img>
              ) : (
                <></>
              )}
            </CustomSwiperSlide>
          );
        })}
    </CustomSwiper>
  );
};

export default DocumentsSwiper;
