import axios, { AxiosResponse } from "axios";
import { HyLabsResponse, Passwords } from "../../utils/types/interfaces";
import {
  HyLabsResponseDataUserId,
  HYNETWORK_AXIOS_CONFIG_JSON_CONTENT,
} from "../util";


export function changePassword(
  data: Passwords
): Promise<HyLabsResponseDataUserId> {
  return axios
    .put("/hy-network/password", data, HYNETWORK_AXIOS_CONFIG_JSON_CONTENT)
    .then((response: AxiosResponse): HyLabsResponseDataUserId => {
      const hynetwork_response: HyLabsResponse = response.data;
      const change_password_response: HyLabsResponseDataUserId =
        hynetwork_response.data;
      return change_password_response;
    })
    .catch((err) => {
      throw err;
    });
}
