import { Table, TableProps, styled } from "@mui/material";

const CustomTable = styled(Table)<TableProps>(({ theme }) => ({
  "& th": {
    backgroundColor: `${theme.palette.grey.A200}`,
    padding: "8px 16px",
    color: "#9A9A9A",
    textTransform: "uppercase",
    fontSize: "12px",
  },
  "& td": {
    padding: "16px",
    fontSize: "16px",
  },
  textAlign: "left",
  fontFamily: "Poppins",
  alignItems: "center",
}));

export default CustomTable;
