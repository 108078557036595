export const HYNETWORK_AXIOS_CONFIG_NO_CREDENTIAL_JSON_CONTENT = {
  baseURL: process.env.REACT_APP_HYNETWORK_URL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
};

export const HYNETWORK_AXIOS_CONFIG_JSON_CONTENT = {
  baseURL: process.env.REACT_APP_HYNETWORK_URL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
  withCredentials: true,
};

// export const HYNETWORK_AXIOS_CONFIG_MULTIPART_CONTENT = {
//   baseURL: process.env.REACT_APP_HYNETWORK_URL,
//   headers: {
//     "Content-Type": "multipart/form-data",
//     "Access-Control-Allow-Origin": "*",
//   },
//   withCredentials: true,
// };

export interface HyLabsResponseDataUserId {
  user_id: string;
}
