import axios, { AxiosResponse } from "axios";
import { AvailableIssuer, HyLabsResponse } from "../../utils/types/interfaces";
import { HYNETWORK_AXIOS_CONFIG_JSON_CONTENT } from "../util";

export function getAvailableIssuers(): Promise<AvailableIssuer[]> {
  return axios
    .get(`/hy-tokenisation/issuers`, HYNETWORK_AXIOS_CONFIG_JSON_CONTENT)
    .then((response: AxiosResponse): AvailableIssuer[] => {
      const hynetwork_response: HyLabsResponse = response.data;
      const available_issuers: AvailableIssuer[] = hynetwork_response.data;
      return available_issuers;
    })
    .catch((err) => {
      throw err;
    });
}
