import { Grid, Typography } from "@mui/material";
import CustomInputField from "../../Base/CustomInputField";

export interface PricingSectionProps {
  unitPrice: number;
  handleUnitPriceChange: React.ChangeEventHandler<HTMLInputElement>;
  nominal: number;
  handleNominalChange: React.ChangeEventHandler<HTMLInputElement>;
  minimum: number;
  handleMinimumChange: React.ChangeEventHandler<HTMLInputElement>;
}

const PricingSection = (props: PricingSectionProps) => {
  return (
    <>
      <Typography variant="h5">Pricing</Typography>
      <Grid container columnSpacing={2} rowSpacing={2}>
        <Grid item xs={12} sm={6}>
          {/* unit price */}
          <CustomInputField
            enableDescription={true}
            description="Unit Price"
            descriptionTextColor="text.main"
            placeholder=""
            value={props.unitPrice.toString()}
            mandatory={true}
            type="number"
            disabled={false}
            fullWidth={true}
            onChange={props.handleUnitPriceChange}
            error={false}
            errorText=""
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* nominal amount */}
          <CustomInputField
            enableDescription={true}
            description="Nominal Amount"
            descriptionTextColor="text.main"
            placeholder=""
            value={props.nominal.toString()}
            mandatory={true}
            type="number"
            disabled={false}
            fullWidth={true}
            onChange={props.handleNominalChange}
            error={false}
            errorText=""
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          {/* minimum investment */}
          <CustomInputField
            enableDescription={true}
            description="Minimum Investment"
            descriptionTextColor="text.main"
            placeholder=""
            value={props.minimum.toString()}
            mandatory={true}
            type="number"
            disabled={false}
            fullWidth={true}
            onChange={props.handleMinimumChange}
            error={false}
            errorText=""
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PricingSection;
