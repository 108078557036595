import React, { useState } from "react";
import CustomInputField from "./CustomInputField";
import { IconButton, InputAdornment } from "@mui/material";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";

interface CustomInputPasswordProps {
  enableDescription: boolean;
  description: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  error: boolean;
  errorText: string;
}

function CustomInputPassword(props: CustomInputPasswordProps) {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    setShowPassword(!showPassword);
  };

  return (
    <CustomInputField
      enableDescription={props.enableDescription}
      description={props.description}
      descriptionTextColor="text.secondary"
      placeholder="&#8226; &#8226; &#8226; &#8226; &#8226; &#8226; &#8226; &#8226;"
      value={props.value}
      mandatory={true}
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
            >
              {showPassword ? (
                <VisibilityOutlined />
              ) : (
                <VisibilityOffOutlined />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      disabled={false}
      fullWidth={true}
      onChange={props.onChange}
      error={props.error}
      errorText={props.errorText}
    />
  );
}

export default CustomInputPassword;
