import { Stack, Typography, Button } from "@mui/material";
import { getTokensInProcess } from "../../../utils/mockup/tokenisation/getTokensInProcess";
import { getTokensIssued } from "../../../utils/mockup/tokenisation/getTokensIssued";
import { PreIssuanceFixedIncomeBond } from "../../../utils/types/interfaces";
import CustomRoundedRow from "../../Base/CustomRoundedRow";
import DefaultCard from "../../Base/DefaultCard";

export interface TokenisationAssetSummaryBoxProps {
  dataGetTokenInProcess: PreIssuanceFixedIncomeBond | null;
  loadedGetTokenInProcess: boolean;
  onClickDetailsMockup: any;
  onClickDetails: any;
}

const TokenisationAssetSummaryBox = (
  props: TokenisationAssetSummaryBoxProps
) => {
  const tokenIssued = getTokensIssued();
  const tokenAvailable = getTokensInProcess();
  const tokenInProcess =
    props.dataGetTokenInProcess !== null
      ? [
          ...props.dataGetTokenInProcess.as_both,
          ...props.dataGetTokenInProcess.as_issuer,
          ...props.dataGetTokenInProcess.as_product_provider,
          ...props.dataGetTokenInProcess.as_none,
        ]
      : [];

  return (
    <DefaultCard title={"Tokenisation Assets Summary"}>
      <Stack direction="column" spacing={4} width={"100%"}>
        {/* Tokens in pre-issuance status */}
        <Stack direction="column" spacing={2} width="100%">
          <Typography variant="h6">{"Tokens in Process"}</Typography>
          <Stack
            direction="column"
            spacing={1}
            width="100%"
            maxHeight={"120px"}
            overflow={"auto"}
          >
            {tokenInProcess.map((value) => {
              return (
                <CustomRoundedRow
                  key={
                    "" +
                    value.name +
                    value.issuer_id +
                    value.product_provider_id
                  }
                >
                  <Typography variant="body1">{value.name}</Typography>
                  <Button
                    color="secondary"
                    variant="text"
                    onClick={() => props.onClickDetails(value)}
                  >
                    Details
                  </Button>
                </CustomRoundedRow>
              );
            })}
            {/* Tokens in pre-issuance status - mockup data */}
            {tokenAvailable.map((value) => {
              return (
                <CustomRoundedRow key={value.id}>
                  <Typography variant="body1">{value.asset}</Typography>
                  <Button
                    color="secondary"
                    variant="text"
                    onClick={() => props.onClickDetailsMockup(value.id)}
                  >
                    Details
                  </Button>
                </CustomRoundedRow>
              );
            })}
          </Stack>
        </Stack>
        {/* Tokens in issued status - mockup data */}
        <Stack direction="column" spacing={2} width="100%">
          <Typography variant="h6">{"Tokens issued"}</Typography>
          <Stack
            direction="column"
            spacing={1}
            width="100%"
            maxHeight={"120px"}
            overflow={"auto"}
          >
            {tokenIssued.map((value) => {
              return (
                <CustomRoundedRow key={value.id}>
                  <Typography variant="body1">{value.asset}</Typography>
                  <Button
                    color="secondary"
                    variant="text"
                    onClick={() => props.onClickDetailsMockup(value.id)}
                  >
                    Details
                  </Button>
                </CustomRoundedRow>
              );
            })}
          </Stack>
        </Stack>
      </Stack>
    </DefaultCard>
  );
};

export default TokenisationAssetSummaryBox;
