import { useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import AssetDetailsView from "../../components/DigitalWallet/Assets/AssetDetailsView";
import {
  AssetDetail,
  getAssetDetails,
} from "../../utils/mockup/wallet/getAssetDetails";
import DigitalWalletView from "../../components/DigitalWallet/DigitalWalletView";
import { UserInfo } from "../../utils/types/interfaces";

const DigitalWallet = () => {
  const navigate = useNavigate();

  const userInfo = useLoaderData() as UserInfo;

  const [assetDetails, setAssetDetails] = useState<AssetDetail | undefined>(
    undefined
  );

  if (userInfo === null) {
    navigate("/auth/login");
  }

  const handleAssetDetailsOnClick = (asset_id: string) => {
    const data = getAssetDetails(asset_id);
    setAssetDetails(data);
  };

  const handleOnClickBackButtonInAssetDetailsView = () => {
    setAssetDetails(undefined);
  };

  return (
    <>
      {assetDetails === undefined ? (
        <DigitalWalletView
          userInfo={userInfo}
          handleAssetDetailsOnClick={handleAssetDetailsOnClick}
        />
      ) : (
        <AssetDetailsView
          asseDetails={assetDetails}
          onClickBackButton={handleOnClickBackButtonInAssetDetailsView}
        />
      )}
    </>
  );
};

export default DigitalWallet;
