import React from "react";
import { NavLink } from "react-router-dom";
import Hylabs_blue from "../../assets/images/Hylabs_blue.svg";
import { Box, Stack, Typography } from "@mui/material";
import theme from "../../theme";

const navListItems = [
  {
    path: "/questionnaire/summary",
    label: "Summary",
  },
  {
    path: "/questionnaire/ownership",
    label: "Ownership",
  },
  {
    path: "null_1",
    label: "Products & Services",
  },
  {
    path: "null_2",
    label:
      "AML, CTF & Sanctions Programme / Policy & Procedures / Risk Assessment",
  },
  {
    path: "null_3",
    label: "KYC, CDD and EDD",
  },
  {
    path: "null_4",
    label: "Monitoring & Reporting",
  },
  {
    path: "null_5",
    label: "Fraud",
  },
  {
    path: "null_6",
    label: "Sanctions",
  },
  {
    path: "null_7",
    label: "Payment Transparency",
  },
  {
    path: "null_8",
    label: "Anti Bribery & Corruption",
  },
  {
    path: "null_9",
    label: "Training and Education",
  },
  {
    path: "null_10",
    label: "Quality Assurance/Compliance Testing",
  },
  {
    path: "null_11",
    label: "Audit",
  },
  {
    path: "null_12",
    label: "Declaration Statement",
  },
];

function NavList() {
  return (
    <Stack
      direction="row"
      spacing="10px"
      useFlexGap
      flexWrap="wrap"
      paddingTop="10pxs"
    >
      {navListItems.map(({ path, label }) => (
        <NavLink
          key={path}
          to={path}
          style={{
            textDecoration: "none",
            pointerEvents: path.startsWith("null_") ? "none" : "auto",
          }}
          className={({ isActive }) => (isActive ? "" : "opacity-60")}
        >
          <Typography
            key={label}
            display="inline"
            paddingRight="22px"
            fontWeight="500"
            color={
              window.location.pathname === path
                ? theme.palette.primary.main
                : theme.palette.primary.light
            }
          >
            {label}
          </Typography>
        </NavLink>
      ))}
    </Stack>
  );
}

function QuestionnaireHeader() {
  return (
    <Stack spacing={2} px="64px">
      <Box width="100%" paddingY="20px">
        <Box marginX="auto" width="fit-content">
          <img src={Hylabs_blue} alt="HyLabs Logo" />
        </Box>
      </Box>
      <NavList />
    </Stack>
  );
}

export default QuestionnaireHeader;
