import { LinearProgress } from "@mui/material";
import CorporateBackgroundView, {
  CorporateBackgroundViewProps,
} from "../../components/DigitalID/CorporateBackground/CorporateBackgroundView";
import DigitalIDView from "../../components/DigitalID/DigitalIDView";
import DocumentView, {
  DocumentViewProps,
} from "../../components/DigitalID/Documents/DocumentView/DocumentView";
import { CorporateBackground, DocumentType } from "../../utils/types/enums";
import { MapEntityDocumentLight } from "../../utils/types/types";
import { getUserDocumentsLight } from "../../routes/walletid/hydid/document";
import { useEffect, useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import {
  EntityDocumentLight,
  UserDocuments,
  UserInfo,
} from "../../utils/types/interfaces";

const DigitalID = () => {
  const navigate = useNavigate();

  const userInfo = useLoaderData() as UserInfo;

  const [selectedDocument, setSelectedDocument] =
    useState<DocumentViewProps | null>(null);
  const [selectedCorporateBackground, setSelectedCorporateBackground] =
    useState<CorporateBackgroundViewProps | null>(null);
  const [userDoc, setUserDoc] = useState<UserDocuments | null>(null);
  const [loadedUserDoc, setLoadedUserDoc] = useState(false);
  const [errorGetUserDoc, setErrorGetUserDoc] = useState<any>(null);
  const [refreshUserDoc, setRefreshUserDoc] = useState(1);

  if (userInfo === null) {
    navigate("/auth/login");
  }

  useEffect(() => {
    if (userInfo !== null) {
      getUserDocumentsLight(userInfo.id)
        .then((value) => {
          const currentUserDoc = userDoc;
          if (currentUserDoc === null && value.documents.length > 0) {
            setUserDoc(value);
          } else if (currentUserDoc !== null) {
            if (
              !value.documents.every((val: EntityDocumentLight) =>
                currentUserDoc.documents.includes(val)
              )
            ) {
              setUserDoc(value);
            }
          }
        })
        .catch((err) => setErrorGetUserDoc(err))
        .finally(() => setLoadedUserDoc(true));
    }
  }, [refreshUserDoc, userDoc, userInfo]);

  if (errorGetUserDoc !== null) {
    navigate("/auth/login");
  }

  const documents: MapEntityDocumentLight = {};
  if (userDoc) {
    userDoc.documents.forEach((obj) => (documents[obj.kind] = obj));
  }

  const handleRefreshDocuments = () => {
    setRefreshUserDoc(Math.random());
  };

  const handleOnClickBackButtonInDocumentView = () => {
    setSelectedDocument(null);
    setRefreshUserDoc(Math.random());
  };

  const handleOnClickBackButtonInCorporateBackgroundView = () => {
    setSelectedCorporateBackground(null);
  };

  const handleDocumentOnClick = (document_type: DocumentType) => {
    setSelectedDocument({
      documents: userDoc?.documents,
      documentType: document_type,
      onClickBackButton: handleOnClickBackButtonInDocumentView,
      handleRefreshDocuments: handleRefreshDocuments,
    });
  };

  const handleDetailsOnClickStart = (document_type: DocumentType) => {
    setSelectedDocument({
      documents: userDoc?.documents,
      documentType: document_type,
      onClickBackButton: handleOnClickBackButtonInDocumentView,
      handleRefreshDocuments: handleRefreshDocuments,
    });
  };

  const handleCorporateBackgroundOnClick = (
    corporate_background: CorporateBackground
  ) => {
    setSelectedCorporateBackground({
      corporateBackground: corporate_background,
      onClickBackButton: handleOnClickBackButtonInCorporateBackgroundView,
    });
  };

  return (
    <>
      {selectedDocument === null && selectedCorporateBackground === null ? (
        <DigitalIDView
          handleDocumentOnClick={handleDocumentOnClick}
          handleDetailsOnClickStart={handleDetailsOnClickStart}
          handleCorporateBackgroundOnClick={handleCorporateBackgroundOnClick}
          dataGetDoc={userDoc}
          loadedGetDoc={loadedUserDoc}
          dataGetUserInfo={userInfo}
        />
      ) : selectedDocument !== null ? (
        <DocumentView {...selectedDocument} />
      ) : selectedCorporateBackground !== null ? (
        <CorporateBackgroundView {...selectedCorporateBackground} />
      ) : (
        <LinearProgress sx={{ width: "100%" }} />
      )}
    </>
  );
};

export default DigitalID;
