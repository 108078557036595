export interface CustodyWallet {
  id: string;
  account: string;
  balance: number;
}

export function getCustodyWallets(): CustodyWallet[] {
  return [
    {
      id: "CUSTODYWALLET1",
      account: "Zodia / Bitgo / Copper",
      balance: 500_000,
    },
  ];
}
