import React, {useContext, useEffect, useState} from "react";
import { Grid, Stack, Typography } from "@mui/material";
import CustomButton from "../../components/Base/CustomButton";
import CustomInputField from "../../components/Base/CustomInputField";
import CustomCheckbox from "../../components/Base/CustomCheckbox";
import {
  KYBContext,
  KYB_CONTEXT_DELIMITER,
  ProfessionalInstitutionSummary,
} from "../../context/KYBContextProvider";
import { useNavigate } from "react-router-dom";

//Reduce page size in smaller components
//TODO verify if user logged and institution reguluated

function Summary() {
  const navigate = useNavigate();

  const {
    institution,
    professionalInstitutionSummary,
    setProfessionalInstitutionSummary,
  } = useContext(KYBContext);

  useEffect(() => {
    if (
        institution === undefined ||
        institution?.registered_address === "" ||
        institution?.company_name === "" ||
        institution?.company_registration_number === ""
    ) {
      navigate("/auth/login");
    }
  }, [institution, navigate]);

  const [companyName, setCompanyName] = useState(
    institution !== undefined && institution.company_name !== undefined
      ? institution?.company_name
      : ""
  );

  const [companyRegistrationNumber, setCompanyRegistrationNumber] = useState(
    institution !== undefined &&
      institution.company_registration_number !== undefined
      ? institution?.company_registration_number
      : ""
  );

  const [incorporationDate, setIncorporationDate] = useState(
      professionalInstitutionSummary !== undefined &&
      professionalInstitutionSummary.incorporation_date !== undefined
      ? professionalInstitutionSummary.incorporation_date.split("T00:00:00Z")[0]
      : ""
  );

  const [address1, setAddress1] = useState(
    professionalInstitutionSummary !== undefined &&
      professionalInstitutionSummary.registered_address !== undefined &&
      professionalInstitutionSummary.registered_address[0] !== undefined
      ? professionalInstitutionSummary.registered_address[0]
      : institution !== undefined &&
        institution.registered_address !== undefined &&
        institution.registered_address.split(KYB_CONTEXT_DELIMITER)[0] !== undefined
      ? institution?.registered_address.split(KYB_CONTEXT_DELIMITER)[0].trim()
      : ""
  );

  const [address2, setAddress2] = useState(
    professionalInstitutionSummary !== undefined &&
      professionalInstitutionSummary.registered_address !== undefined &&
      professionalInstitutionSummary.registered_address[1] !== undefined
      ? professionalInstitutionSummary.registered_address[1]
      : institution !== undefined &&
        institution.registered_address !== undefined &&
        institution.registered_address.split(KYB_CONTEXT_DELIMITER)[1] !== undefined
      ? institution?.registered_address.split(KYB_CONTEXT_DELIMITER)[1].trim()
      : ""
  );

  const [incCountry, setIncCountry] = useState(
    professionalInstitutionSummary !== undefined &&
      professionalInstitutionSummary.registered_address !== undefined &&
      professionalInstitutionSummary.registered_address[2] !== undefined
      ? professionalInstitutionSummary.registered_address[2]
      : institution !== undefined &&
        institution.registered_address !== undefined &&
        institution.registered_address.split(KYB_CONTEXT_DELIMITER)[2] !== undefined
      ? institution?.registered_address.split(KYB_CONTEXT_DELIMITER)[2].trim()
      : ""
  );

  const [postCode, setPostCode] = useState(
    professionalInstitutionSummary !== undefined &&
      professionalInstitutionSummary.registered_address !== undefined &&
      professionalInstitutionSummary.registered_address[3] !== undefined
      ? professionalInstitutionSummary.registered_address[3]
      : institution !== undefined &&
        institution.registered_address !== undefined &&
        institution.registered_address.split(KYB_CONTEXT_DELIMITER)[3] !== undefined
      ? institution?.registered_address.split(KYB_CONTEXT_DELIMITER)[3].trim()
      : ""
  );

  const [incCity, setIncCity] = useState(
    professionalInstitutionSummary !== undefined &&
      professionalInstitutionSummary.registered_address !== undefined &&
      professionalInstitutionSummary.registered_address[4] !== undefined
      ? professionalInstitutionSummary.registered_address[4].trim()
      : ""
  );

  const [businessAsRegistered, setBusinessAsRegistered] = useState(
    professionalInstitutionSummary !== undefined &&
      JSON.stringify(professionalInstitutionSummary.business_address) === JSON.stringify(professionalInstitutionSummary.registered_address)
  );

  //Business address
  const [businessAddress1, setBusinessAddress1] = useState(
      !businessAsRegistered && professionalInstitutionSummary !== undefined && professionalInstitutionSummary.business_address !== undefined &&
      professionalInstitutionSummary.business_address[0] !== undefined
      ? professionalInstitutionSummary.business_address[0].trim()
      : ""
  );

  const [businessAddress2, setBusinessAddress2] = useState(
      !businessAsRegistered && professionalInstitutionSummary !== undefined && professionalInstitutionSummary.business_address !== undefined &&
      professionalInstitutionSummary.business_address[1] !== undefined
      ? professionalInstitutionSummary.business_address[1].trim()
      : ""
  );

  const [businessIncCity, setBusinessIncCity] = useState(
      !businessAsRegistered &&  professionalInstitutionSummary !== undefined && professionalInstitutionSummary.business_address !== undefined &&
      professionalInstitutionSummary.business_address[2] !== undefined
      ? professionalInstitutionSummary.business_address[2].trim()
      : ""
  );

  const [businessPostCode, setBusinessPostCode] = useState(
      !businessAsRegistered && professionalInstitutionSummary !== undefined && professionalInstitutionSummary.business_address !== undefined &&
      professionalInstitutionSummary.business_address[3] !== undefined
      ? professionalInstitutionSummary.business_address[3].trim()
      : ""
  );

  const handleCompanyNameChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setCompanyName(event.target.value);
  };

  const handleIncNumberChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setCompanyRegistrationNumber(event.target.value);
  };

  const handleIncDateChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setIncorporationDate(event.target.value);
  };

  const handleIncCountryChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setIncCountry(event.target.value);
  };

  const handleAddress1Change: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setAddress1(event.target.value);
  };

  const handleIncCityChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setIncCity(event.target.value);
  };

  const handleAddress2Change: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setAddress2(event.target.value);
  };

  const handlePostCodeChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setPostCode(event.target.value);
  };

  //Business address assign
  const handleBusinessAddress1Change: React.ChangeEventHandler<
    HTMLInputElement
  > = (event) => {
    setBusinessAddress1(event.target.value);
  };

  const handleBusinessIncCityChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (event) => {
    setBusinessIncCity(event.target.value);
  };

  const handleBusinessAddress2Change: React.ChangeEventHandler<
    HTMLInputElement
  > = (event) => {
    setBusinessAddress2(event.target.value);
  };

  const handleBusinessPostCodeChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (event) => {
    setBusinessPostCode(event.target.value);
  };

  const handleBusinessAsRegisteredChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (event) => {
    setBusinessAsRegistered(event.target.checked);
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    handleOnSaveDraft();
    navigate("/questionnaire/ownership");
  };

  const handleOnSaveDraft = () => {
    const form_data: ProfessionalInstitutionSummary = {
      registered_address: [address1, address2, incCountry, postCode, incCity],
      business_address: businessAsRegistered
        ? [address1, address2, incCountry, postCode, incCity]
        : [
            businessAddress1,
            businessAddress2,
            businessIncCity,
            businessPostCode,
          ],
      incorporation_date: incorporationDate,
    };
    setProfessionalInstitutionSummary?.(form_data);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack alignItems="end" spacing={4}>
        <Typography width="100%" fontSize="26px" fontWeight="500">
          Summary
        </Typography>
        <Grid container columnSpacing={4} rowGap={1}>
          <Grid item xs={12} sm={6}>
            <CustomInputField
              enableDescription={true}
              description="Company name"
              descriptionTextColor="text.main"
              placeholder=""
              value={companyName}
              mandatory={true}
              type="text"
              disabled={false}
              fullWidth={true}
              onChange={handleCompanyNameChange}
              error={false}
              errorText=""
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomInputField
              enableDescription={true}
              description="Incorporation Number"
              descriptionTextColor="text.main"
              placeholder=""
              value={companyRegistrationNumber}
              mandatory={true}
              type="text"
              disabled={false}
              fullWidth={true}
              onChange={handleIncNumberChange}
              error={false}
              errorText=""
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomInputField
              enableDescription={true}
              description="Date of Incorporation"
              descriptionTextColor="text.main"
              placeholder=""
              value={incorporationDate}
              mandatory={true}
              type="date"
              disabled={false}
              fullWidth={true}
              onChange={handleIncDateChange}
              error={false}
              errorText="Please enter a valid Date of Incorporation"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomInputField
              enableDescription={true}
              description="Country of Incorportation"
              descriptionTextColor="text.main"
              placeholder=""
              value={incCountry}
              mandatory={true}
              type="text"
              disabled={false}
              fullWidth={true}
              onChange={handleIncCountryChange}
              error={false}
              errorText=""
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomInputField
              enableDescription={true}
              description="Registered Address (Line 1)"
              descriptionTextColor="text.main"
              placeholder=""
              value={address1}
              mandatory={true}
              type="text"
              disabled={false}
              fullWidth={true}
              onChange={handleAddress1Change}
              error={false}
              errorText=""
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomInputField
              enableDescription={true}
              description="Registered Address (City)"
              descriptionTextColor="text.main"
              placeholder=""
              value={incCity}
              mandatory={true}
              type="text"
              disabled={false}
              fullWidth={true}
              onChange={handleIncCityChange}
              error={false}
              errorText=""
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomInputField
              enableDescription={true}
              description="Registered Address (Line 2)"
              descriptionTextColor="text.main"
              placeholder=""
              value={address2}
              mandatory={true}
              type="text"
              disabled={false}
              fullWidth={true}
              onChange={handleAddress2Change}
              error={false}
              errorText=""
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomInputField
              enableDescription={true}
              description="Registered Address (Post Code)"
              descriptionTextColor="text.main"
              placeholder=""
              value={postCode}
              mandatory={true}
              type="text"
              disabled={false}
              fullWidth={true}
              onChange={handlePostCodeChange}
              error={false}
              errorText=""
            />
          </Grid>
          <Grid item xs={12}>
            <CustomCheckbox
              value={businessAsRegistered}
              onChange={handleBusinessAsRegisteredChange}
              text="Business address same as Registered address?"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomInputField
              enableDescription={true}
              description="Business Address (Line 1)"
              descriptionTextColor="text.main"
              placeholder=""
              value={businessAddress1}
              mandatory={false}
              type="text"
              disabled={businessAsRegistered}
              fullWidth={true}
              onChange={handleBusinessAddress1Change}
              error={false}
              errorText=""
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomInputField
              enableDescription={true}
              description="Business Address (City)"
              descriptionTextColor="text.main"
              placeholder=""
              value={businessIncCity}
              mandatory={false}
              type="text"
              disabled={businessAsRegistered}
              fullWidth={true}
              onChange={handleBusinessIncCityChange}
              error={false}
              errorText=""
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomInputField
              enableDescription={true}
              description="Business Address (Line 2)"
              descriptionTextColor="text.main"
              placeholder=""
              value={businessAddress2}
              mandatory={false}
              type="text"
              disabled={businessAsRegistered}
              fullWidth={true}
              onChange={handleBusinessAddress2Change}
              error={false}
              errorText=""
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomInputField
              enableDescription={true}
              description="Business Address (Post Code)"
              descriptionTextColor="text.main"
              placeholder=""
              value={businessPostCode}
              mandatory={false}
              type="text"
              disabled={businessAsRegistered}
              fullWidth={true}
              onChange={handleBusinessPostCodeChange}
              error={false}
              errorText="Please enter Incorporation Post code"
            />
          </Grid>
        </Grid>
        <Stack spacing={1} width="fit-content" direction="row" pt="16px">
          <CustomButton variant="outlined" onClick={handleOnSaveDraft}>
            <Typography px="16px" py="12px" fontWeight="500">
              Save draft
            </Typography>
          </CustomButton>
          <CustomButton variant="contained" type="submit" disabled={
              companyName === '' || companyRegistrationNumber === '' || incorporationDate === '' ||
              address1 === '' || address2 === '' || incCountry === '' || postCode === '' || incCity === '' ||
              (!businessAsRegistered && (businessAddress1 === '' || businessAddress2 === ''
                  || businessIncCity === '' || businessPostCode === '')) }
          >
            <Typography px="42px" py="12px" fontWeight="500">
              Next
            </Typography>
          </CustomButton>
        </Stack>
      </Stack>
    </form>
  );
}

export default Summary;
