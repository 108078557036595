import { Grid, Stack, Typography } from "@mui/material";
import CustomCheckbox from "../Base/CustomCheckbox";
import CustomInputField from "../Base/CustomInputField";

interface BusinessAreaProps {
  retail: boolean;
  handleRetailChange: any;
  privateB: boolean;
  handlePrivateChange: any;
  commercial: boolean;
  handleCommercialChange: any;
  transactional: boolean;
  handleTransactionalChange: any;
  investment: boolean;
  handleInvestmentChange: any;
  financial: boolean;
  handleFinancialChange: any;
  securities: boolean;
  handleSecuritiesChange: any;
  broker: boolean;
  handleBrokerChange: any;
  multilateral: boolean;
  handleMultilateralChange: any;
  wealth: boolean;
  handleWealthChange: any;
  other: string;
  handleOtherChange: any;
}

function BusinessArea(props: BusinessAreaProps) {
  return (
    <Stack width="100%" spacing={2}>
      <Typography>
        Select the business areas applicable to the Entity
      </Typography>
      <Grid container rowGap={2}>
        <Grid item xs={4}>
          <CustomCheckbox
            value={props.retail}
            onChange={props.handleRetailChange}
            text="Retail Banking"
          />
        </Grid>
        <Grid item xs={4}>
          <CustomCheckbox
            value={props.privateB}
            onChange={props.handlePrivateChange}
            text="Private Banking"
          />
        </Grid>
        <Grid item xs={4}>
          <CustomCheckbox
            value={props.commercial}
            onChange={props.handleCommercialChange}
            text="Commercial Banking"
          />
        </Grid>

        <Grid item xs={4}>
          <CustomCheckbox
            value={props.transactional}
            onChange={props.handleTransactionalChange}
            text="Transactional Banking"
          />
        </Grid>
        <Grid item xs={4}>
          <CustomCheckbox
            value={props.investment}
            onChange={props.handleInvestmentChange}
            text="Investment Banking"
          />
        </Grid>
        <Grid item xs={4}>
          <CustomCheckbox
            value={props.financial}
            onChange={props.handleFinancialChange}
            text="Financial Markets Trading"
          />
        </Grid>

        <Grid item xs={4}>
          <CustomCheckbox
            value={props.securities}
            onChange={props.handleSecuritiesChange}
            text="Securities Services/Custody"
          />
        </Grid>
        <Grid item xs={4}>
          <CustomCheckbox
            value={props.broker}
            onChange={props.handleBrokerChange}
            text="Broker/Dealer"
          />
        </Grid>
        <Grid item xs={4}>
          <CustomCheckbox
            value={props.multilateral}
            onChange={props.handleMultilateralChange}
            text="Multilateral Development Bank"
          />
        </Grid>

        <Grid item xs={4}>
          <CustomCheckbox
            value={props.wealth}
            onChange={props.handleWealthChange}
            text="Wealth Manager"
          />
        </Grid>
        <Grid item xs={6}>
          <CustomInputField
            enableDescription={false}
            description=""
            descriptionTextColor="text.main"
            placeholder=""
            value={props.other}
            mandatory={false}
            type="text"
            disabled={false}
            fullWidth={true}
            className="ownership-input ownership-input-root"
            onChange={props.handleOtherChange}
            error={false}
            errorText="Please enter an area"
          />
        </Grid>
      </Grid>
    </Stack>
  );
}

export default BusinessArea;
