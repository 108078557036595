import axios, { AxiosResponse } from "axios";
import { Activate, HyLabsResponse } from "../../utils/types/interfaces";
import {
  HyLabsResponseDataUserId,
  HYNETWORK_AXIOS_CONFIG_JSON_CONTENT,
} from "../util";


export function activate(data: Activate): Promise<HyLabsResponseDataUserId> {
  return axios
    .post("/hy-network/activate", data, HYNETWORK_AXIOS_CONFIG_JSON_CONTENT)
    .then((response: AxiosResponse): HyLabsResponseDataUserId => {
      const hynetwork_response: HyLabsResponse = response.data;
      const activation_response: HyLabsResponseDataUserId =
        hynetwork_response.data;
      return activation_response;
    })
    .catch((err) => {
      throw err;
    });
}

export function resetCode(): Promise<HyLabsResponseDataUserId> {
  return axios
    .post("/hy-network/reset/code", undefined, HYNETWORK_AXIOS_CONFIG_JSON_CONTENT)
    .then((response: AxiosResponse): HyLabsResponseDataUserId => {
      const hynetwork_response: HyLabsResponse = response.data;
      const reset_activation_code_response: HyLabsResponseDataUserId =
        hynetwork_response.data;
      return reset_activation_code_response;
    })
    .catch((err) => {
      throw err;
    });
}
