import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#082B54",
    },
    secondary: {
      main: "#2373BF",
      light: "#F6FAFE",
    },
    success: {
      main: "#36D200",
    },
    error: {
      main: "#EE505A",
    },
    grey: {
      A200: "#EBEBEB",
    },
    text: {
      secondary: "#9A9A9A",
    },
  },
  typography: {
    fontFamily: "Poppins",
    button: {
      textTransform: "none",
      fontFamily: "Poppins",
      fontSize: "16px",
    },
    h1: {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "52px",
      lineHeight: "78px",
    },
    h2: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "44px",
      lineHeight: "64px",
    },
    h3: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "32px",
      lineHeight: "48px",
    },
    h4: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "26px",
      lineHeight: "39px",
    },
    h5: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "20px",
      lineHeight: "32px",
    },
    h6: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "24px",
    },
    body1: {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.1px",
    },
    body2: {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "18px",
    },
  },
});

export default theme;
