export interface Asset {
  id: string;
  asset: string;
  value: number;
  currency: string;
}

export function getAssets(): Asset[] {
  return [
    {
      id: "FIAT1",
      asset: "GBP",
      value: 150_000,
      currency: "GBP",
    },
    {
      id: "FIAT2",
      asset: "EUR",
      value: 105_000,
      currency: "EUR",
    },
    {
      id: "FIAT3",
      asset: "USD",
      value: 50_000,
      currency: "USD",
    },
    {
      id: "ASSET1",
      asset: "HyLabs 2Yr Fixed 3.5% Green Bond",
      value: 10_230,
      currency: "GBP",
    },
    {
      id: "ASSET2",
      asset: "Runtime 5Yr Variable SONIA+3% Bond",
      value: 8_030,
      currency: "GBP",
    },
    {
      id: "ASSET3",
      asset: "Runtime 3Yr Variable SONIA+3% Bond",
      value: 900,
      currency: "GBP",
    },
  ];
}

export function getRetailAssets(): Asset[] {
  return [
    {
      id: "FIAT1",
      asset: "GBP",
      value: 150_000,
      currency: "GBP",
    },
    {
      id: "FIAT2",
      asset: "EUR",
      value: 105_000,
      currency: "EUR",
    },
    {
      id: "FIAT3",
      asset: "USD",
      value: 50_000,
      currency: "USD",
    },
  ];
}
