import { useState } from "react";
import { UserInfo } from "../../utils/types/interfaces";
import { Box, Button, Checkbox, Stack, Typography } from "@mui/material";
import DefaultCard from "../Base/DefaultCard";
import CustomRoundedRow from "../Base/CustomRoundedRow";
import { UserCategory, UserKind } from "../../utils/types/enums";

export interface UserDirectoryViewProps {
  users: UserInfo[];
  onClickManage: any;
}

const UserDirectoryView = (props: UserDirectoryViewProps) => {
  const [filteredUsers, setFilteredUsers] = useState(props.users);
  const [retailFilter, setRetailFilter] = useState(false);
  const [accreditedFilter, setAccreditedFilter] = useState(false);
  const [professionalFilter, setProfessionalFilter] = useState(false);
  const [individualFilter, setIndividualFilter] = useState(false);
  const [institutionFilter, setInstitutionFilter] = useState(false);

  const handleRetailCheckboxOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => setRetailFilter(event.target.checked);

  const handleAccreditedCheckboxOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => setAccreditedFilter(event.target.checked);

  const handleProfessionalCheckboxOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => setProfessionalFilter(event.target.checked);

  const handleIndividualCheckboxOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => setIndividualFilter(event.target.checked);

  const handleInstitutionCheckboxOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => setInstitutionFilter(event.target.checked);

  const applyCategoryFilters = (user: UserInfo): boolean => {
    return (
      (retailFilter ? user.category === UserCategory.RETAIL : false) ||
      (accreditedFilter ? user.category === UserCategory.ACCREDITED : false) ||
      (professionalFilter ? user.category === UserCategory.PROFESSIONAL : false)
    );
  };

  const applyKindFilters = (user: UserInfo): boolean => {
    return (
      (individualFilter ? user.kind === UserKind.INDIVIDUAL : false) ||
      (institutionFilter ? user.kind === UserKind.INSTITUTION : false)
    );
  };

  const handleApplyFilters = () => {
    if (
      !retailFilter &&
      !accreditedFilter &&
      !professionalFilter &&
      !individualFilter &&
      !institutionFilter
    ) {
      setFilteredUsers(props.users);
      return;
    }
    const categoryFiltersApplied =
      retailFilter || accreditedFilter || professionalFilter
        ? props.users.filter((user) => applyCategoryFilters(user))
        : props.users;

    const kindFiltersApplied =
      individualFilter || institutionFilter
        ? categoryFiltersApplied.filter((user) => applyKindFilters(user))
        : categoryFiltersApplied;

    setFilteredUsers(kindFiltersApplied);
  };

  return (
    <Stack direction="row" spacing={2} width={"100%"} height={"100%"}>
      <DefaultCard title="Filters" width={"35% !important"}>
        <Stack direction="column" spacing={1}>
          <Typography variant="h6">{"User category"}</Typography>
          <Stack direction="row" spacing={1}>
            <Checkbox color="primary" onChange={handleRetailCheckboxOnChange} />
            <Box alignSelf={"center"}>
              <Typography variant="body1">Retail</Typography>
            </Box>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Checkbox
              color="primary"
              onChange={handleAccreditedCheckboxOnChange}
            />
            <Box alignSelf={"center"}>
              <Typography variant="body1">Accredited</Typography>
            </Box>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Checkbox
              color="primary"
              onChange={handleProfessionalCheckboxOnChange}
            />
            <Box alignSelf={"center"}>
              <Typography variant="body1">Professional</Typography>
            </Box>
          </Stack>
          <Typography variant="h6">{"User type"}</Typography>
          <Stack direction="row" spacing={1}>
            <Checkbox
              color="primary"
              onChange={handleIndividualCheckboxOnChange}
            />
            <Box alignSelf={"center"}>
              <Typography variant="body1">Individual</Typography>
            </Box>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Checkbox
              color="primary"
              onChange={handleInstitutionCheckboxOnChange}
            />
            <Box alignSelf={"center"}>
              <Typography variant="body1">Legal Entity</Typography>
            </Box>
          </Stack>
        </Stack>
        <Button
          variant="contained"
          onClick={handleApplyFilters}
          sx={{ marginTop: "32px" }}
          fullWidth
        >
          Search
        </Button>
      </DefaultCard>
      <DefaultCard
        title="Users"
        maxHeight={"100%"}
        justifyContent={"flex-start !important"}
      >
        <Stack
          direction="column"
          spacing={1}
          maxHeight={"600px"}
          overflow={"auto"}
          width={"100%"}
        >
          {filteredUsers.map((user) => {
            return (
              <>
                {user.kind === UserKind.ADMIN ? (
                  <></>
                ) : (
                  <CustomRoundedRow key={user.id}>
                    <Typography variant="body1">{user.username}</Typography>
                    <Button
                      variant="text"
                      color="secondary"
                      onClick={() => props.onClickManage(user)}
                    >
                      Manage
                    </Button>
                  </CustomRoundedRow>
                )}
              </>
            );
          })}
        </Stack>
      </DefaultCard>
    </Stack>
  );
};

export default UserDirectoryView;
