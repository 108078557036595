import { Signatories } from "../../types/interfaces";

export function getSignatories(): Signatories[] {
  return [
    {
      id: "1",
      name: "Ethan Lee",
      email_address: "e.lee@bluecloud.io",
      type: "Sole",
    },
    {
      id: "2",
      name: "Mavis Greene",
      email_address: "m.greene@bluecloud.io",
      type: "Joint x2",
    },
    {
      id: "3",
      name: "Amber Winters",
      email_address: "a.winters@bluecloud.io",
      type: "Joint x2",
    },
  ];
}
