import { Stack, Typography } from "@mui/material";
import UploadProductDocumentRow, {
  UploadProductDocumentRowProps,
} from "./UploadProductDocumentRow";

export interface DocumentsSectionProps {
  values: UploadProductDocumentRowProps[];
}

const DocumentsSection = (props: DocumentsSectionProps) => {
  return (
    <>
      <Typography variant="h5">Documents</Typography>
      <Stack direction="column" spacing={2}>
        {props.values.map((value) => {
          return (
            <UploadProductDocumentRow
              key={value.title}
              title={value.title}
              file={value.file}
              handleFileChange={value.handleFileChange}
            />
          );
        })}
      </Stack>
    </>
  );
};

export default DocumentsSection;
