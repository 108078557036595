export interface TokenIssued {
    id: string;
    asset: string;
  }
  
  export function getTokensIssued(): TokenIssued[] {
    return [
      {
        id: "ASSET1",
        asset: "HyLabs 2Yr Fixed 3.5% Green Bond",
      },
      {
        id: "ASSET2",
        asset: "Runtime 5Yr Variable SONIA+3% Bond",
      },
      {
        id: "ASSET3",
        asset: "Runtime 3Yr Variable SONIA+3% Bond",
      },
    ];
  }