import {
  Box,
  Button,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  AssetDetail,
  CouponHistory,
  TransactionHistory,
} from "../../../utils/mockup/wallet/getAssetDetails";
import BackButtonTitled from "../../Base/BackButtonTitled";
import DefaultCard from "../../Base/DefaultCard";
import CustomTable from "../../Base/CustomTable";
import { ReactComponent as DownloadIcon } from "../../../assets/images/download.svg";
import Propsectus from "../../../utils/mockup/documents/Prospectus.pdf";
import TermSheet from "../../../utils/mockup/documents/TermSheet.pdf";
import { currencyFormatter } from "../../../utils/functions/currencyFormatter";

export interface AssetDetailsViewProps {
  asseDetails: AssetDetail;
  onClickBackButton: any;
}

//TODO: need to be refactored once mockup data is not used anymore

const AssetDetailsView = (props: AssetDetailsViewProps) => {
  return (
    <>
      <BackButtonTitled
        section="Assets"
        title={props.asseDetails.asset}
        onClick={props.onClickBackButton}
      />
      <DefaultCard marginTop="32px">
        <Stack direction="column" spacing={4} width="100%">
          <Box>
            <Typography variant="body1" fontWeight={600} paddingBottom="16px">
              General
            </Typography>
            <CustomTable>
              <TableHead>
                <TableRow>
                  <TableCell width="30%">Asset</TableCell>
                  <TableCell width="20%" align="right">
                    Price
                  </TableCell>
                  <TableCell width="20%" align="right">
                    Units
                  </TableCell>
                  <TableCell width="20%" align="right">
                    Value
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={props.asseDetails.id}>
                  <TableCell>{props.asseDetails.asset}</TableCell>
                  <TableCell align="right">
                    {currencyFormatter(props.asseDetails.price)}
                  </TableCell>
                  <TableCell align="right">{props.asseDetails.units}</TableCell>
                  <TableCell align="right">
                    {currencyFormatter(props.asseDetails.value)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </CustomTable>
          </Box>

          <Box>
            <Typography variant="body1" fontWeight={600} paddingBottom="16px">
              {"Transaction History"}
            </Typography>
            <CustomTable>
              <TableHead>
                <TableRow>
                  <TableCell width="20%">Event</TableCell>
                  <TableCell width="20%">
                    Date
                  </TableCell>
                  <TableCell width="20%" align="right">
                    Units
                  </TableCell>
                  <TableCell width="20%" align="right">
                    Price
                  </TableCell>
                  <TableCell width="20%" align="right">
                    Book Cost
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.asseDetails.transaction_history.map(
                  (value: TransactionHistory) => {
                    return (
                      <TableRow key={value.id}>
                        <TableCell>{value.operation}</TableCell>
                        <TableCell>{value.date}</TableCell>
                        <TableCell align="right">{value.units}</TableCell>
                        <TableCell align="right">
                          {currencyFormatter(value.price)}
                        </TableCell>
                        <TableCell align="right">
                          {currencyFormatter(value.book_cost)}
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </CustomTable>
          </Box>

          <Box>
            <Typography variant="body1" fontWeight={600} paddingBottom="16px">
              {"Coupon History"}
            </Typography>
            <CustomTable>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell align="right">Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.asseDetails.coupon_history.map(
                  (value: CouponHistory) => {
                    return (
                      <TableRow key={value.id}>
                        <TableCell>{value.date}</TableCell>
                        <TableCell align="right">
                          {currencyFormatter(value.value)}
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </CustomTable>
          </Box>

          <CustomTable>
            <TableBody>
              <TableRow key="nominal">
                <TableCell style={{ width: "25%" }}>
                  <Typography variant="body1" fontWeight={600}>
                    Nominal
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="body1" textAlign="left">
                    {new Intl.NumberFormat("en-GB", {
                      style: "currency",
                      currency: "GBP",
                    }).format(props.asseDetails.nominal)}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow key="interest">
                <TableCell>
                  <Typography variant="body1" fontWeight={600}>
                    Interest
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">
                    {props.asseDetails.interest}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow key="fixed/variable">
                <TableCell>
                  <Typography variant="body1" fontWeight={600}>
                    Fixed/Variable
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">
                    {props.asseDetails.type}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow key="term">
                <TableCell>
                  <Typography variant="body1" fontWeight={600}>
                    Term
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">
                    {props.asseDetails.term}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow key="issuer">
                <TableCell sx={{ borderBottom: "unset" }}>
                  <Typography variant="body1" fontWeight={600}>
                    Issuer
                  </Typography>
                </TableCell>
                <TableCell sx={{ borderBottom: "unset" }}>
                  <Typography variant="body1">
                    {props.asseDetails.issuer}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </CustomTable>

          <CustomTable>
            <TableBody>
              <TableRow key="prospectus">
                <TableCell style={{ width: "25%" }}>
                  <Typography variant="body1" fontWeight={600}>
                    Prospectus
                  </Typography>
                </TableCell>
                <TableCell>
                  <a
                    href={Propsectus}
                    download="Prospectus"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      endIcon={<DownloadIcon />}
                      component="label"
                    >
                      Download PDF
                    </Button>
                  </a>
                </TableCell>
              </TableRow>
              <TableRow key="termsheet">
                <TableCell sx={{ borderBottom: "unset" }}>
                  <Typography variant="body1" fontWeight={600}>
                    {"Term Sheet"}
                  </Typography>
                </TableCell>
                <TableCell sx={{ borderBottom: "unset" }}>
                  <a
                    href={TermSheet}
                    download="TermSheet"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      endIcon={<DownloadIcon />}
                      component="label"
                    >
                      Download PDF
                    </Button>
                  </a>
                </TableCell>
              </TableRow>
            </TableBody>
          </CustomTable>
        </Stack>
      </DefaultCard>
    </>
  );
};

export default AssetDetailsView;
