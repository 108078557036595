import {
  Alert,
  Box,
  Button,
  Divider,
  LinearProgress,
  SelectChangeEvent,
  Snackbar,
  Typography,
} from "@mui/material";
import BackButtonTitled from "../Base/BackButtonTitled";
import DefaultCard from "../Base/DefaultCard";
import { ProductDocument, UserInfo } from "../../utils/types/interfaces";
import { useState } from "react";
import {
  useRequestWithParams,
  useRequestWithoutParams,
} from "../../hooks/useAxiosRequest";
import { getAvailableIssuers } from "../../routes/hytokenisation/getAvailableIssuers";
import {
  ProductDocumentExtension,
  ProductDocumentType,
} from "../../utils/types/enums";
import { prepareFixedIncomeBond } from "../../routes/hytokenisation/fibt/fixedIncomeBondToken";
import OverviewSection from "./TokenIssuance/OverviewSection";
import PricingSection from "./TokenIssuance/PricingSection";
import TermsSection from "./TokenIssuance/TermsSection";
import DocumentsSection from "./TokenIssuance/DocumentsSection";
import { getEntityInfo } from "../../routes/hynetwork/getEntity";
import { bytesToBase64 } from "../../utils/functions/bytesToBase64";

export interface TokenIssuanceViewProps {
  dataGetUserInfo: UserInfo;
  onClickBackButton: any;
}

const TokenIssuanceView = (props: TokenIssuanceViewProps) => {
  const {
    //cancel: cancelGetAvailableIssuers,
    data: dataGetAvailableIssuers,
    //error: errorGetAvailableIssuers,
    loaded: loadedGetAvailableIssuers,
  } = useRequestWithoutParams(getAvailableIssuers);

  const {
    //cancel: cancelGetOnboarding,
    data: dataGetEntityInfo,
    //error: errorGetOnboarding,
    loaded: loadedGetEntityInfo,
  } = useRequestWithParams(getEntityInfo, props.dataGetUserInfo.id);

  const [showError, setShowError] = useState(false);

  const handleCloseSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowError(false);
  };

  const [tokenName, setTokenName] = useState<string>("");
  const [isin, setIsin] = useState<string>("");
  const [issuer, setIssuer] = useState<string>("");
  const [capitalStructure, setCapitalStructure] = useState<string>("");
  const [currency, setCurrency] = useState<string>("");
  const [launchDate, setLaunchDate] = useState<string>("");
  const [completionDate, setCompletionDate] = useState<string>("");
  const [unitPrice, setUnitPrice] = useState<number>(0);
  const [nominal, setNominal] = useState<number>(0);
  const [minimum, setMinimum] = useState<number>(0);
  const [term, setTerm] = useState<number>(0);
  const [type, setType] = useState<string>("");
  const [coupon, setCoupon] = useState<number>(0);
  const [referenceRate, setReferenceRate] = useState<string>("");
  const [couponFrequency, setCouponFrequency] = useState<number>(0);
  const [termsheet, setTermsheet] = useState<ProductDocument | undefined>(
    undefined
  );
  const [prospectus, setProspectus] = useState<ProductDocument | undefined>(
    undefined
  );
  const [subscription, setSubscription] = useState<ProductDocument | undefined>(
    undefined
  );
  const [other, setOther] = useState<ProductDocument | undefined>(undefined);

  const handleTokenNameChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setTokenName(event.target.value);
  };

  const handleIsinChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setIsin(event.target.value);
  };

  const handleIssuerChange = (event: SelectChangeEvent<string>) => {
    setIssuer(event.target.value);
  };

  const handleCapitalStructureChange = (event: SelectChangeEvent<string>) => {
    setCapitalStructure(event.target.value);
  };

  const handleCurrencyChange = (event: SelectChangeEvent<string>) => {
    setCurrency(event.target.value);
  };

  const handleLaunchDateChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setLaunchDate(event.target.value);
  };

  const handleCompletionDateChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (event) => {
    setCompletionDate(event.target.value);
  };

  const handleUnitPriceChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setUnitPrice(Number(event.target.value));
  };

  const handleNominalChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setNominal(Number(event.target.value));
  };

  const handleMinimumChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setMinimum(Number(event.target.value));
  };

  const handleTermChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setTerm(Number(event.target.value));
  };

  const handleTypeChange = (event: SelectChangeEvent<string>) => {
    setType(event.target.value);
  };

  const handleCouponChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setCoupon(Number(event.target.value));
  };

  const handleReferenceRateChange = (event: SelectChangeEvent<string>) => {
    setReferenceRate(event.target.value);
  };

  const handleCouponFrequencyChange = (event: SelectChangeEvent<string>) => {
    setCouponFrequency(Number(event.target.value));
  };

  const handleTermSheetChange = (event: any) => {
    if (event && event.target && event.target.files[0] !== undefined) {
      event.target.files[0].arrayBuffer().then((value: ArrayBuffer) => {
        setTermsheet({
          bytes: bytesToBase64(value),
          kind: ProductDocumentType.OfferDocument,
          extension: ProductDocumentExtension.PDF,
        });
      });
    }
  };

  const handleProspectusChange = (event: any) => {
    if (event && event.target && event.target.files[0] !== undefined) {
      event.target.files[0].arrayBuffer().then((value: ArrayBuffer) => {
        setProspectus({
          bytes: bytesToBase64(value),
          kind: ProductDocumentType.Kiid,
          extension: ProductDocumentExtension.PDF,
        });
      });
    }
  };

  const handleSubscriptionChange = (event: any) => {
    if (event && event.target && event.target.files[0] !== undefined) {
      event.target.files[0].arrayBuffer().then((value: ArrayBuffer) => {
        setSubscription({
          bytes: bytesToBase64(value),
          kind: ProductDocumentType.Agreement,
          extension: ProductDocumentExtension.PDF,
        });
      });
    }
  };

  const handleOtherChange = (event: any) => {
    if (event && event.target && event.target.files[0] !== undefined) {
      event.target.files[0].arrayBuffer().then((value: ArrayBuffer) => {
        setOther({
          bytes: bytesToBase64(value),
          kind: ProductDocumentType.Resolution,
          extension: ProductDocumentExtension.PDF,
        });
      });
    }
  };

  const verifyFields = (): boolean => {
    return (
      tokenName !== "" &&
      isin !== "" &&
      issuer !== "" &&
      capitalStructure !== "" &&
      currency !== "" &&
      launchDate !== "" &&
      completionDate !== "" &&
      !Number.isNaN(unitPrice) &&
      !Number.isNaN(nominal) &&
      !Number.isNaN(minimum) &&
      !Number.isNaN(term) &&
      type !== "" &&
      !Number.isNaN(coupon) &&
      referenceRate !== "" &&
      !Number.isNaN(couponFrequency) &&
      termsheet !== undefined &&
      prospectus !== undefined &&
      subscription !== undefined &&
      other !== undefined
    );
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    if (
      verifyFields() &&
      //avoid typing issues
      termsheet !== undefined &&
      prospectus !== undefined &&
      subscription !== undefined &&
      other !== undefined
    ) {
      prepareFixedIncomeBond({
        name: tokenName,
        identifier: isin,
        structure: capitalStructure,
        issuer_identifier: issuer,
        currency: currency,
        term: term + "Y",
        launch_date: launchDate + "T00:00:00Z",
        completion_date: completionDate + "T00:00:00Z",
        notional: nominal,
        division: unitPrice,
        minimum: minimum,
        allow_professional: true,
        allow_accredited: true,
        allow_retail: true,
        coupon_frequency: couponFrequency + " months",
        coupon_type: type,
        coupon_rate: coupon.toString(),
        coupon_reference_rate: referenceRate,
        redemption: "redempion",
        optionality: "optionality",
        collateral: "collateral",
        platform_cost: "platform_cost",
        advisor_cost: "advisor_cost",
        documents: [termsheet, prospectus, subscription, other],
      })
        .then((value) => {
          props.onClickBackButton();
        })
        .catch((err: any) => {
          //TODO: handle error
          if (err.response && err.response.status === 500) {
            //High probability that document exists in the database, i.e., it is not unique
            setShowError(true);
          }
        });
    }
  };

  return (
    <>
      <BackButtonTitled
        section="Assets"
        title="Issue new token"
        onClick={props.onClickBackButton}
      />
      <DefaultCard marginTop="32px">
        {!loadedGetAvailableIssuers ||
        !loadedGetEntityInfo ||
        dataGetAvailableIssuers === null ||
        dataGetEntityInfo === null ? (
          <LinearProgress sx={{ width: "100%" }} />
        ) : (
          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            <OverviewSection
              dataGetEntityInfo={dataGetEntityInfo}
              dataGetAvailableIssuers={dataGetAvailableIssuers}
              tokenName={tokenName}
              handleTokenNameChange={handleTokenNameChange}
              isin={isin}
              handleIsinChange={handleIsinChange}
              issuer={issuer}
              handleIssuerChange={handleIssuerChange}
              capitalStructure={capitalStructure}
              handleCapitalStructureChange={handleCapitalStructureChange}
              currency={currency}
              handleCurrencyChange={handleCurrencyChange}
              launchDate={launchDate}
              handleLaunchDateChange={handleLaunchDateChange}
              completionDate={completionDate}
              handleCompletionDateChange={handleCompletionDateChange}
            />

            <Divider sx={{ marginTop: "32px", marginBottom: "16px" }} />

            <PricingSection
              unitPrice={unitPrice}
              handleUnitPriceChange={handleUnitPriceChange}
              nominal={nominal}
              handleNominalChange={handleNominalChange}
              minimum={minimum}
              handleMinimumChange={handleMinimumChange}
            />

            <Divider sx={{ marginTop: "32px", marginBottom: "16px" }} />

            <TermsSection
              term={term}
              handleTermChange={handleTermChange}
              type={type}
              handleTypeChange={handleTypeChange}
              coupon={coupon}
              handleCouponChange={handleCouponChange}
              referenceRate={referenceRate}
              handleReferenceRateChange={handleReferenceRateChange}
              couponFrequency={couponFrequency}
              handleCouponFrequencyChange={handleCouponFrequencyChange}
            />

            <Divider sx={{ marginTop: "32px", marginBottom: "16px" }} />

            <DocumentsSection
              values={[
                {
                  title: "Termsheet",
                  file: termsheet,
                  handleFileChange: handleTermSheetChange,
                },
                {
                  title: "Prospectus",
                  file: prospectus,
                  handleFileChange: handleProspectusChange,
                },
                {
                  title: "Subscription Document",
                  file: subscription,
                  handleFileChange: handleSubscriptionChange,
                },
                {
                  title: "Other",
                  file: other,
                  handleFileChange: handleOtherChange,
                },
              ]}
            />

            <Box marginTop="32px" display="flex" justifyContent={"flex-end"}>
              <Button
                variant="contained"
                type="submit"
                disabled={!verifyFields()}
              >
                <Typography variant="h4" paddingX={"32px"}>
                  Issue
                </Typography>
              </Button>
            </Box>
          </form>
        )}
      </DefaultCard>
      <Snackbar
        open={showError}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          <Typography variant="body1">
            Error! Exact same document already exists in HyNetwork.
          </Typography>
        </Alert>
      </Snackbar>
    </>
  );
};

export default TokenIssuanceView;
