import React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Button, Link, Stack, Typography } from "@mui/material";
import theme from "../../theme";
import { logout } from "../../routes/hynetwork/logout";

function Footer() {
  const navigate = useNavigate();
  const handleLogout = () => {
    logout()
      .then(() => {
        navigate("/auth/login");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Stack
      justifyContent="center"
      alignItems="end"
      height="72px"
      width="100%"
      borderTop={1}
      borderColor={theme.palette.grey.A200}
      bottom="0"
    >
      <Stack
        px="64px"
        direction="row"
        spacing={2}
        justifyContent="end"
        alignItems="center"
      >
        <Link
          component={RouterLink}
          to={"https://hylabs.io/terms/"}
          target={"_blank"}
          underline="always"
          color={theme.palette.secondary.main}
        >
          <Typography>Terms & Conditions</Typography>
        </Link>
        <Link
          component={RouterLink}
          to={"https://hylabs.io"}
          target={"_blank"}
          underline="always"
          color={theme.palette.secondary.main}
        >
          <Typography>Home page</Typography>
        </Link>
        <Link
          component={RouterLink}
          to={"mailto:contact@hylabs.io?subject=HyLabs%20Network%20Info"}
          target={"_blank"}
          underline="always"
          color={theme.palette.secondary.main}
        >
          <Typography mr="16px">Get in touch</Typography>
        </Link>
        <Button variant="outlined" onClick={handleLogout}>
          <Typography py="3px" px="1px" fontWeight="500">
            Logout
          </Typography>
        </Button>
      </Stack>
    </Stack>
  );
}

export default Footer;
