export enum DocumentType {
  Id = "Id",
  Address = "Address",
  ProofOfReserves = "ProofOfReserves",
  IncorporationCertificate = "IncorporationCertificate",
  ShareholderStructure = "ShareholderStructure",
  ArticlesOfAssociation = "ArticlesOfAssociation",
  ProfessionalStatus = "ProfessionalStatus",
  CorporateStructure = "CorporateStructure",
  ConfirmationStatement = "ConfirmationStatement",
}

export enum DocumentExtension {
  PDF = "PDF",
  PNG = "PNG",
  JPEG = "JPEG",
}

export enum AssetClassExperience {
  None = "None",
  LessThanFive = "LessThanFive",
  FiveToTen = "FiveToTen",
  MoreThanTen = "MoreThanTen",
}

export enum EmployeesNumber {
  E0to50 = "E0to50",
  E51to200 = "E51to200",
  E201to500 = "E201to500",
  E501to1000 = "E501to1000",
  E1001to5000 = "E1001to5000",
  MoreThan5000 = "MoreThan5000",
}

export namespace EmployeesNumberUtils {
  export function prettyToString(val: EmployeesNumber): string {
    switch (val) {
      case EmployeesNumber.E0to50:
        return "0 to 50";
      case EmployeesNumber.E51to200:
        return "51 to 200";
      case EmployeesNumber.E201to500:
        return "201 to 500";
      case EmployeesNumber.E501to1000:
        return "501 to 1000";
      case EmployeesNumber.E1001to5000:
        return "1001 to 5000";
      case EmployeesNumber.MoreThan5000:
        return "More Than 5000";
    }
  }
}

export enum AuditedEvery {
  Year = "Year",
  YearAndHalf = "YearAndHalf",
  ComponentBasedReview = "ComponentBasedReview",
}

export enum TotalAssetsValue {
  V0to10 = "V0to10",
  V10to100 = "V10to100",
  V100to500 = "V100to500",
  MoreThan500 = "MoreThan500",
}

export namespace TotalAssetsValueUtils {
  export function prettyToString(val: TotalAssetsValue): string {
    switch (val) {
      case TotalAssetsValue.V0to10:
        return "0 to 10";
      case TotalAssetsValue.V10to100:
        return "10 to 100";
      case TotalAssetsValue.V100to500:
        return "100 to 500";
      case TotalAssetsValue.MoreThan500:
        return "More Than 500";
    }
  }
}

export enum EmployeeInComplianceDepartment {
  LessThanTen = "LessThanTen",
  TenToFifty = "TenToFifty",
  MoreThanFifty = "MoreThanFifty",
}

export enum BoardReportingPeriod {
  Monthly = "Monthly",
  Quarterly = "Quarterly",
  HalfYearly = "HalfYearly",
  Yearly = "Yearly",
}

export enum AbcProgrammeApplicableTo {
  JoinVentures = "JoinVentures",
  ThirdParties = "ThirdParties",
  Both = "Both",
  NotApplicable = "NotApplicable",
}

export enum EnterpriseWideRiskAssessmentFrequency {
  Year = "Year",
  EighteenMonth = "EighteenMonth",
}

export enum RetentionPeriod {
  ZeroToFiveYears = "ZeroToFiveYears",
  MoreThanFiveYears = "MoreThanFiveYears",
}

export enum ScreeningMethod {
  Manual = "Manual",
  Automatic = "Automatic",
  Both = "Both",
}

export enum EddType {
  EddRiskBased = "EddRiskBased",
  EddAndRestrictedRiskBased = "EddAndRestrictedRiskBased",
  Prohibited = "Prohibited",
  None = "None",
}

export enum SanctionListUsedFor {
  ReferenceData = "ReferenceData",
  Filtering = "Filtering",
  Both = "Both",
  NotUsed = "NotUsed",
}

export enum ManualUpdateTimeframe {
  LessThanTwoDays = "LessThanTwoDays",
  ThreeToFiveDays = "ThreeToFiveDays",
  MoreThanFiveDays = "MoreThanFiveDays",
}

export enum UserKind {
  ADMIN = "ADMIN",
  INDIVIDUAL = "INDIVIDUAL",
  INSTITUTION = "INSTITUTION",
}

export enum UserStatus {
  REGISTERED = "REGISTERED",
  ACTIVE = "ACTIVE",
  RESET = "RESET",
}

export enum UserCategory {
  ADMIN = "ADMIN",
  RETAIL = "RETAIL",
  ACCREDITED = "ACCREDITED",
  PROFESSIONAL = "PROFESSIONAL",
}

export enum UserOnboardingStatus {
  MissingData = "MissingData",
  UnderValidation = "UnderValidation",
  Onboarded = "Onboarded",
  Failed = "Failed",
  NotRequired = "NotRequired",
}

export enum ProductDocumentType {
  OfferDocument = "OfferDocument",
  Kiid = "Kiid",
  Resolution = "Resolution",
  Agreement = "Agreement",
}

export enum ProductDocumentExtension {
  PDF = "PDF",
}

export enum FixedIncomeBondStatus {
  ToBeIssued = "ToBeIssued",
  Issued = "Issued",
}

export namespace DocumentType {
  export function getDocumentViewTitle(documentType: DocumentType): string {
    switch (documentType) {
      case DocumentType.Id:
        return "Passport";
      case DocumentType.Address:
        return "Proof of Address";
      case DocumentType.ProofOfReserves:
        return "Accredited Investor Proofs";
      case DocumentType.IncorporationCertificate:
        return "Incorporation Certificate";
      case DocumentType.ShareholderStructure:
        return "Shareholder Structure";
      case DocumentType.ArticlesOfAssociation:
        return "Articles of Association";
      case DocumentType.ProfessionalStatus:
        return "Professional Status";
      case DocumentType.CorporateStructure:
        return "Corporate Structure";
      case DocumentType.ConfirmationStatement:
        return "Accredited Investor Proofs";
    }
  }

  export function getDocumentViewDescription(
    documentType: DocumentType
  ): string[] | undefined {
    switch (documentType) {
      case DocumentType.Id:
        return undefined;
      case DocumentType.Address:
        return undefined;
      case DocumentType.IncorporationCertificate:
        return undefined;
      case DocumentType.ShareholderStructure:
        return undefined;
      case DocumentType.ArticlesOfAssociation:
        return undefined;
      case DocumentType.ProfessionalStatus:
        return undefined;
      case DocumentType.CorporateStructure:
        return undefined;
      case DocumentType.ConfirmationStatement:
      case DocumentType.ProofOfReserves:
        return [
          "An accredited or sophisticated investor is an investor with a special status under financial regulation. ",
          "Click here to see the requirements for your country. ",
          "We recommend that you seek financial advice and legal advice if you decide to seek accredited status.",
        ];
    }
  }

  export function getManageDocumentTitle(
    documentType: DocumentType
  ): string | undefined {
    switch (documentType) {
      case DocumentType.Id:
        return undefined;
      case DocumentType.Address:
        return undefined;
      case DocumentType.ProofOfReserves:
        return "Proof of Reserves";
      case DocumentType.IncorporationCertificate:
        return undefined;
      case DocumentType.ShareholderStructure:
        return undefined;
      case DocumentType.ArticlesOfAssociation:
        return undefined;
      case DocumentType.ProfessionalStatus:
        return undefined;
      case DocumentType.CorporateStructure:
        return undefined;
      case DocumentType.ConfirmationStatement:
        return "Confirmation Statement";
    }
  }

  export function getAssociatedDocumentType(
    documentType: DocumentType
  ): DocumentType[] | undefined {
    switch (documentType) {
      case DocumentType.Id:
        return undefined;
      case DocumentType.Address:
        return undefined;
      case DocumentType.ProofOfReserves:
        return [DocumentType.ConfirmationStatement];
      case DocumentType.IncorporationCertificate:
        return undefined;
      case DocumentType.ShareholderStructure:
        return undefined;
      case DocumentType.ArticlesOfAssociation:
        return undefined;
      case DocumentType.ProfessionalStatus:
        return undefined;
      case DocumentType.CorporateStructure:
        return undefined;
      case DocumentType.ConfirmationStatement:
        return [DocumentType.ProofOfReserves];
    }
  }

  export function getAssociatedDocumentTypeIncludingSelf(
    self: DocumentType
  ): DocumentType[] {
    switch (self) {
      case DocumentType.Id:
        return [self];
      case DocumentType.Address:
        return [self];
      case DocumentType.ProofOfReserves:
        return [self, DocumentType.ConfirmationStatement];
      case DocumentType.IncorporationCertificate:
        return [self];
      case DocumentType.ShareholderStructure:
        return [self];
      case DocumentType.ArticlesOfAssociation:
        return [self];
      case DocumentType.ProfessionalStatus:
        return [self];
      case DocumentType.CorporateStructure:
        return [self];
      case DocumentType.ConfirmationStatement:
        return [DocumentType.ProofOfReserves, self];
    }
  }

  export function getDocumentTypesByUser(
    kind: UserKind,
    category: UserCategory
  ): DocumentType[] {
    switch (kind) {
      case UserKind.ADMIN:
        return [];
      case UserKind.INDIVIDUAL:
        switch (category) {
          case UserCategory.RETAIL:
            return [DocumentType.Id, DocumentType.Address];
          case UserCategory.ACCREDITED:
            return [
              DocumentType.Id,
              DocumentType.Address,
              DocumentType.ProofOfReserves /* No need to add also ConfrimationStatement here */,
            ];
          case UserCategory.PROFESSIONAL:
          case UserCategory.ADMIN:
            return [];
        }
      // eslint-disable-next-line no-fallthrough
      case UserKind.INSTITUTION:
        switch (category) {
          case UserCategory.RETAIL:
            return [
              DocumentType.IncorporationCertificate,
              DocumentType.ArticlesOfAssociation,
              DocumentType.CorporateStructure,
            ];
          case UserCategory.ACCREDITED:
            return [
              DocumentType.IncorporationCertificate,
              DocumentType.ArticlesOfAssociation,
              DocumentType.ProofOfReserves /* No need to add also ConfrimationStatement here */,
              DocumentType.CorporateStructure,
            ];
          case UserCategory.PROFESSIONAL:
            return [
              DocumentType.IncorporationCertificate,
              DocumentType.ArticlesOfAssociation,
              DocumentType.ProfessionalStatus,
              DocumentType.CorporateStructure,
            ];
          case UserCategory.ADMIN:
            return [];
        }
    }
  }
}

export enum CorporateBackground {
  BeneficialOwners = "Beneficial Owners",
  PersonOfSignificantControl = "Person of Significant Control",
  Signatories = "Signatories",
}
