import { useState } from "react";
import theme from "../../theme";
import { TokenDetail } from "../../utils/mockup/tokenisation/getTokenDetail";
import { Box, Button, Checkbox, Stack, Typography, Grid } from "@mui/material";
import { getAvailableBalance } from "../../utils/mockup/wallet/getFiatAssets";
import CustomInputField from "../Base/CustomInputField";
import CustomRoundedRow from "../Base/CustomRoundedRow";
import CustomMark, {
  CustomMarkColor,
  CustomMarkTextColor,
} from "../Base/CustomMark";
import { currencyFormatter } from "../../utils/functions/currencyFormatter";

export interface PledgeBoxProps {
  username: string;
  nftIdAddress: string;
  tokenDetails: TokenDetail;
  signed: boolean;
  onClickSign: any;
  onClickPledge: any;
}

const PledgeBox = (props: PledgeBoxProps) => {
  const [tokenAmount, setTokenAmount] = useState(0);
  const [checkedPrivacy, setCheckedPrivacy] = useState(false);
  const [checkedTerms, setCheckedTerms] = useState(false);

  const balance = getAvailableBalance();

  const onChangeTokenAmount = (event: any) => {
    setTokenAmount(event.target.value);
  };

  return (
    <Stack direction={"column"} spacing={2} width="100%">
      <Grid container width="100%" spacing={1}>
        <Grid item xs={6}>
          <Typography variant="h5">{props.username}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" fontWeight={600} align="right">
            Available Balance: {currencyFormatter(balance)}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" color={theme.palette.text.secondary}>
            {props.nftIdAddress}
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <CustomInputField
            enableDescription={true}
            description="Number of Units"
            descriptionTextColor="text.main"
            placeholder=""
            value={tokenAmount}
            mandatory={true}
            type="number"
            disabled={false}
            fullWidth={true}
            onChange={onChangeTokenAmount}
            error={false}
            errorText=""
            InputProps={{ inputMode: "numeric" }}
            pattern="[0-9]*"
          />
        </Grid>
        <Grid item xs={6}>
          <CustomInputField
            enableDescription={true}
            description="Unit price"
            descriptionTextColor="text.main"
            value={currencyFormatter(props.tokenDetails.price)}
            placeholder={currencyFormatter(props.tokenDetails.price)}
            mandatory={true}
            type="text"
            disabled={true}
            fullWidth={true}
            onChange={() => {}}
            error={false}
            errorText=""
          />
        </Grid>
        <Grid item xs={12}>
          <CustomInputField
            enableDescription={true}
            description="Total investment"
            descriptionTextColor="text.main"
            value={currencyFormatter(props.tokenDetails.price * tokenAmount)}
            placeholder={currencyFormatter(
              props.tokenDetails.price * tokenAmount
            )}
            mandatory={true}
            type="text"
            disabled={true}
            fullWidth={true}
            onChange={() => {}}
            error={false}
            errorText=""
          />
        </Grid>
      </Grid>

      <Stack direction={"column"} paddingTop={"32px"} spacing={1}>
        <Stack direction="row" spacing={2}>
          <Checkbox
            color="primary"
            inputProps={{ "aria-label": "primary checkbox" }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setCheckedPrivacy(event.target.checked)
            }
          />
          <Box alignSelf={"center"}>
            <Typography
              variant="body2"
              fontWeight={400}
              color={theme.palette.text.secondary}
            >
              I confirm to share my data pack with the product provider and
              issuer
            </Typography>
          </Box>
        </Stack>

        <Stack direction="row" spacing={2}>
          <Checkbox
            color="primary"
            inputProps={{ "aria-label": "primary checkbox" }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setCheckedTerms(event.target.checked)
            }
          />
          <Box alignSelf={"center"}>
            <Typography
              variant="body2"
              fontWeight={400}
              color={theme.palette.text.secondary}
            >
              I confirm i have read and fully understand that investment
              documents and terms of the investment
            </Typography>
          </Box>
        </Stack>
      </Stack>

      <CustomRoundedRow>
        <Typography variant="body1" fontWeight={400}>
          Sign subscription document
        </Typography>
        {!props.signed ? (
          <Button
            variant="outlined"
            disabled={!checkedPrivacy || !checkedTerms}
            onClick={props.onClickSign}
            sx={{
              width: "100px",
            }}
          >
            Sign
          </Button>
        ) : (
          <CustomMark
            markColor={CustomMarkColor.Green}
            text="Signed"
            markTextColor={CustomMarkTextColor.Green}
          />
        )}
      </CustomRoundedRow>

      <Box display={"flex"} justifyContent={"end"}>
        <Button
          variant="contained"
          color="primary"
          component="label"
          onClick={props.onClickPledge}
          disabled={!checkedPrivacy || !checkedTerms || !props.signed}
        >
          {"Pledge"}
        </Button>
      </Box>
    </Stack>
  );
};

export default PledgeBox;
