import {
  Button,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Typography,
} from "@mui/material";
import DefaultCard from "../../Base/DefaultCard";
import { getCustodyWallets } from "../../../utils/mockup/admin/getCustodyWallets";
import CustomTable from "../../Base/CustomTable";

const CustodyWalletBox = () => {
  const custodyWallet = getCustodyWallets();

  return (
    <DefaultCard title={"Custody wallet"}>
      <CustomTable>
        <TableHead>
          <TableRow>
            <TableCell>Account</TableCell>
            <TableCell>Balance</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {custodyWallet.map((value) => {
            return (
              <TableRow key={value.id}>
                <TableCell>
                  <Typography variant="body1">{value.account}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">{value.balance}</Typography>
                </TableCell>
                <TableCell align="right">
                  <Button color="secondary" variant="text">
                    Details
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </CustomTable>
    </DefaultCard>
  );
};

export default CustodyWalletBox;
