import React from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

interface CustomTypeButtonProps {
  text: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  mb?: string;
  ml?: string;
  mr?: string;
  mt?: string;
}

const CustomStyleButton = styled(Button)<ButtonProps>(({ theme }) => ({
  "&.MuiButton-outlined": {
    borderColor: theme.palette.grey.A200,
  },
}));

function CustomTypeButton(props: CustomTypeButtonProps) {
  return (
    <CustomStyleButton
      onClick={props.onClick}
      fullWidth={true}
      variant="outlined"
      endIcon={<ChevronRightRoundedIcon fontSize="large" />}
    >
      <Typography
        marginRight="auto"
        paddingY="32px"
        paddingX="16px"
        color="black"
      >
        {props.text}
      </Typography>
    </CustomStyleButton>
  );
}

export default CustomTypeButton;
