import BackButtonTitled from "../../Base/BackButtonTitled";
import { CorporateBackground } from "../../../utils/types/enums";
import BeneficialOwnersTable from "./BeneficialOwnersTable";
import { getBeneficialOwners } from "../../../utils/mockup/corporateBackground/getBeneficialOwners";
import { getPersonOfSignificantControl } from "../../../utils/mockup/corporateBackground/getPersonOfSignificantControl";
import { getSignatories } from "../../../utils/mockup/corporateBackground/getSignatories";
import SignatoriesTable from "./SignatoriesTable";
import PersonOfSignificantControlTable from "./PersonOfSignificantControlTable";
import DefaultCard from "../../Base/DefaultCard";

export interface CorporateBackgroundViewProps {
  corporateBackground: CorporateBackground;
  onClickBackButton: any;
}

const CorporateBackgroundView = (props: CorporateBackgroundViewProps) => {
  const beneficialOwners = getBeneficialOwners();
  const personOfSignificantControl = getPersonOfSignificantControl();
  const signatories = getSignatories();

  return (
    <>
      <BackButtonTitled
        section="Corporate background"
        title={props.corporateBackground}
        onClick={props.onClickBackButton}
      />
      <DefaultCard marginTop="16px">
        {props.corporateBackground === CorporateBackground.BeneficialOwners && (
          <BeneficialOwnersTable data={beneficialOwners} />
        )}

        {props.corporateBackground === CorporateBackground.Signatories && (
          <SignatoriesTable data={signatories} />
        )}

        {props.corporateBackground ===
          CorporateBackground.PersonOfSignificantControl && (
          <PersonOfSignificantControlTable data={personOfSignificantControl} />
        )}
      </DefaultCard>
    </>
  );
};

export default CorporateBackgroundView;
