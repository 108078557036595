import {
  Button,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CustomTable from "../../Base/CustomTable";
import { Asset } from "../../../utils/mockup/wallet/getAssets";
import { currencyFormatter } from "../../../utils/functions/currencyFormatter";

export interface AssetsBoxProps {
  assets: Asset[];
  handleOnClickDetails: any;
}

const AssetsBox = (props: AssetsBoxProps) => {
  const totalAssets = props.assets.reduce((a: number, b: Asset) => {
    return a + b.value;
  }, 0);
  return (
    <Stack direction="column" spacing={4} width="100%">
      <CustomTable>
        <TableHead>
          <TableRow>
            <TableCell>Asset</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Units</TableCell>
            <TableCell>Value</TableCell>
            <TableCell style={{ textAlign: "right" }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.assets.map((value) => {
            return (
              <TableRow key={value.id}>
                <TableCell>{value.asset}</TableCell>
                <TableCell>{currencyFormatter(value.price)}</TableCell>
                <TableCell>{value.units}</TableCell>
                <TableCell>{currencyFormatter(value.value)}</TableCell>
                <TableCell style={{ textAlign: "right" }}>
                  <Button
                    variant="text"
                    component="label"
                    sx={{ color: "#2373BF" }}
                    onClick={() => props.handleOnClickDetails(value.id)}
                  >
                    Details
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </CustomTable>
      <Typography variant="h5" color="black" textAlign="right">
        {`Total assets: ${currencyFormatter(totalAssets)}`}
      </Typography>
    </Stack>
  );
};

export default AssetsBox;
