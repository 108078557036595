import React, { useState, useEffect, useContext } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Alert, Link, Container, Stack, Typography, Box } from "@mui/material";
import CustomInputField from "../../components/Base/CustomInputField";
import { login } from "../../routes/hynetwork/login";
import { getUserStatus } from "../../routes/hynetwork/getUser";
import { getUserOnboardingStatus } from "../../routes/hynetwork/onboarding";
import { emailRegex } from "../../utils/types/regexes";
import { UserOnboardingStatus } from "../../utils/types/enums";
import { UserKind } from "../../utils/types/enums";
import { UserStatus } from "../../utils/types/enums";
import CreateAccountLink from "../../components/Intro/CreateAccountLink";
import { LoginContext } from "../../context/LoginContextProvider";
import CustomButton from "../../components/Base/CustomButton";
import CustomInputPassword from "../../components/Base/CustomInputPassword";

function Login() {
  const navigate = useNavigate();

  const { setUserIdentifier } = useContext(LoginContext);

  const [loginSucceed, setLoginSucceed] = useState(false);
  const [emailText, setEmailText] = useState("");
  const [emailTextError, setEmailTextError] = useState(false);
  const [passwordText, setPasswordText] = useState("");
  const [passwordTextError, setPasswordTextError] = useState(false);
  const [invalidCredentials, setInvalidCredentials] = useState(false);

  useEffect(() => {
    if (loginSucceed) {
      setInvalidCredentials(false);
      getUserStatus()
        .then((response) => {
          setUserIdentifier?.(emailText.toString());
          if (response.status === UserStatus.REGISTERED) {
            navigate("/auth/activation");
          }

          if (response.status === UserStatus.ACTIVE) {
            getUserOnboardingStatus()
              .then((response) => {
                if (
                  response.onboarding_status ===
                    UserOnboardingStatus.Onboarded ||
                  response.onboarding_status ===
                    UserOnboardingStatus.NotRequired
                ) {
                  navigate("/");
                }
                if (
                  response.onboarding_status ===
                  UserOnboardingStatus.MissingData
                ) {
                  if (response.kind === UserKind.INDIVIDUAL) {
                    navigate("/auth/KYC");
                  }
                  if (response.kind === UserKind.INSTITUTION) {
                    navigate("/auth/KYB");
                  }
                }
              })
              .catch((err) => {
                setInvalidCredentials(true);
              });
          }

          if (response.status === UserStatus.RESET) {
            navigate("/auth/change-password");
          }
        })
        .catch((err) => {
          setInvalidCredentials(true);
        });
      setLoginSucceed(false);
    }
  }, [emailText, loginSucceed, navigate, setUserIdentifier]);

  const handleEmailChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setEmailText(event.target.value);
  };

  const handlePasswordChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setPasswordText(event.target.value);
  };

  const handleClickLogin: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    if (emailRegex.test(emailText)) {
      setEmailTextError(false);
      setPasswordTextError(false);
      login({ identifier: emailText, password: passwordText })
        .then(() => {
          setLoginSucceed(true);
        })
        .catch((err) => {
          setInvalidCredentials(true);
          setLoginSucceed(false);
        });
    } else {
      setEmailTextError(true);
    }
  };

  return (
    <Box width="416px">
      <Container disableGutters>
        <Typography textAlign="center" variant="h4">
          Login
        </Typography>
        <form onSubmit={handleClickLogin}>
          <Stack spacing={3} mt="32px" mb="32px">
            {invalidCredentials && (
              <Alert severity="warning" color="error">
                Incorrect username or password
              </Alert>
            )}
            <CustomInputField
              enableDescription={true}
              description="Email"
              descriptionTextColor="text.secondary"
              placeholder="Add your email address"
              value={emailText}
              mandatory={true}
              type="text"
              disabled={false}
              fullWidth={true}
              onChange={handleEmailChange}
              error={emailTextError}
              errorText="Please enter a valid email address"
            />
            <Stack spacing={0.5}>
              <CustomInputPassword
                enableDescription={true}
                description="Password"
                value={passwordText}
                onChange={handlePasswordChange}
                error={passwordTextError}
                errorText="Invalid password format"
              />
              <Link
                component={RouterLink}
                to={"/auth/reset-password"}
                underline="hover"
              >
                <Typography
                  color="secondary.main"
                  variant="body2"
                  textAlign="right"
                >
                  {" "}
                  Forgot password?{" "}
                </Typography>
              </Link>
            </Stack>
            <CustomButton
              variant="contained"
              fullWidth={true}
              type="submit"
              disabled={emailText === "" || passwordText === ""}
            >
              <Typography paddingY="12px" fontWeight="500">
                Login
              </Typography>
            </CustomButton>
          </Stack>
        </form>
        <CreateAccountLink />
      </Container>
    </Box>
  );
}

export default Login;
