import { Box, Button, Stack, Typography } from "@mui/material";
import {
  UserCategory,
  UserKind,
  DocumentType,
} from "../../../utils/types/enums";
import { MapEntityDocumentLight } from "../../../utils/types/types";
import CustomMark, {
  CustomMarkColor,
  CustomMarkTextColor,
} from "../../Base/CustomMark";
import DefaultCard from "../../Base/DefaultCard";
import CustomRoundedRow from "../../Base/CustomRoundedRow";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

export interface DigitalIDBoxProps {
  documents: MapEntityDocumentLight;
  userKind: UserKind;
  userCategory: UserCategory;
  handleOnClickViewMore?: any;
}

const DigitalIDBox = (props: DigitalIDBoxProps) => {
  const expectedDocuments = DocumentType.getDocumentTypesByUser(
    props.userKind,
    props.userCategory
  );

  return (
    <DefaultCard title="Digital ID">
      <Stack direction="column" spacing={1} width="100%">
        {expectedDocuments.map((value: DocumentType) => {
          return (
            <CustomRoundedRow key={value}>
              <Typography variant="h6" color="black" boxSizing="border-box">
                {DocumentType.getDocumentViewTitle(value)}
              </Typography>
              <CustomMark
                disabled
                text={props.documents[value] ? "Verified" : "Unverified"}
                markColor={
                  props.documents[value]
                    ? CustomMarkColor.Green
                    : CustomMarkColor.Red
                }
                markTextColor={
                  props.documents[value]
                    ? CustomMarkTextColor.Green
                    : CustomMarkTextColor.Red
                }
              />
            </CustomRoundedRow>
          );
        })}
        {props.userCategory === UserCategory.RETAIL && (
          <CustomRoundedRow key={"Accredited Investor"}>
            <Typography variant="h6" color="black" boxSizing="border-box">
              {"Accredited Investor"}
            </Typography>
            <CustomMark
              disabled
              text={"Unverified"}
              markColor={CustomMarkColor.Red}
              markTextColor={CustomMarkTextColor.Red}
            />
          </CustomRoundedRow>
        )}
      </Stack>
      {props.handleOnClickViewMore && (
        <Box marginTop={"32px"} justifyContent={"flex-end"} display={"flex"} width={"100%"}>
          <Button
            onClick={props.handleOnClickViewMore}
            variant="contained"
            endIcon={<ChevronRightRoundedIcon />}
          >
            <Typography variant="h6">{"View more"}</Typography>
          </Button>
        </Box>
      )}
    </DefaultCard>
  );
};

export default DigitalIDBox;
