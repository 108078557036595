export interface Request {
  id: string;
  severity: number; //0 green 1 yellow 2 orange 3 red
  text: string;
}

export function getRequests(): Request[] {
  return [
    {
      id: "REQUEST1",
      severity: 1,
      text: "Potential User AML issue"
    },
    {
      id: "REQUEST2",
      severity: 0,
      text: "Resolve Bond Issuance glitch"
    },
    {
      id: "REQUEST3",
      severity: 0,
      text: "Submit AML report on User X"
    }
  ];
}
