import React from "react";
import Hylabs_white from "../../assets/images/Hylabs_white.svg";
import { Box } from "@mui/material";

function IntroHeader() {
  return (
    <Box width="100%" paddingY="20px">
      <Box marginX="auto" width="fit-content">
        <img src={Hylabs_white} alt="HyLabs Logo" />
      </Box>
    </Box>
  );
}

export default IntroHeader;
