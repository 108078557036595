import axios, { AxiosResponse } from "axios";
import { HyLabsResponse, UserCredentials } from "../../utils/types/interfaces";
import {
  HyLabsResponseDataUserId,
  HYNETWORK_AXIOS_CONFIG_JSON_CONTENT,
} from "../util";


export function login(
  data: UserCredentials
): Promise<HyLabsResponseDataUserId> {
  return axios
    .post(
      "/hy-network/login",
      data,
      HYNETWORK_AXIOS_CONFIG_JSON_CONTENT
    )
    .then((response: AxiosResponse): HyLabsResponseDataUserId => {
      const hynetwork_response: HyLabsResponse = response.data;
      const login_response: HyLabsResponseDataUserId = hynetwork_response.data;
      return login_response;
    })
    .catch((err) => {
      throw err;
    });
}
