import axios, { AxiosResponse } from "axios";
import {
  HyDidInfo,
  HyLabsResponse,
  IsHyDidFrozen,
} from "../../../utils/types/interfaces";
import { HYNETWORK_AXIOS_CONFIG_JSON_CONTENT } from "../../util";

export function getHydidInfo(): Promise<HyDidInfo> {
  return axios
    .get("/hy-did", HYNETWORK_AXIOS_CONFIG_JSON_CONTENT)
    .then((response: AxiosResponse): HyDidInfo => {
      const hynetwork_response: HyLabsResponse = response.data;
      const hydid_info: HyDidInfo = hynetwork_response.data;
      return hydid_info;
    })
    .catch((err) => {
      throw err;
    });
}

export function isHydidFrozen(): Promise<IsHyDidFrozen> {
  return axios
    .get("/hy-did/status", HYNETWORK_AXIOS_CONFIG_JSON_CONTENT)
    .then((response: AxiosResponse): IsHyDidFrozen => {
      const hynetwork_response: HyLabsResponse = response.data;
      const hydid_frozen: IsHyDidFrozen = hynetwork_response.data;
      return hydid_frozen;
    })
    .catch((err) => {
      throw err;
    });
}

export function getHydidDisplayName(hydid_id: string): Promise<string> {
  return axios
    .get(`/hy-did/display-name/${hydid_id}`, HYNETWORK_AXIOS_CONFIG_JSON_CONTENT)
    .then((response: AxiosResponse): string => {
      const hynetwork_response: HyLabsResponse = response.data;
      const display_name: string = hynetwork_response.data;
      return display_name;
    })
    .catch((err) => {
      throw err;
    });
}