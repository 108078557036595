export interface FiatAsset {
  id: string;
  asset: string;
  available: number;
  pledged: number;
  value: number;
}

export function getFiatAssets(): FiatAsset[] {
  return [
    {
      id: "FIAT1",
      asset: "GBP",
      available: 100_000,
      pledged: 50_000,
      value: 150_000,
    },
    {
      id: "FIAT2",
      asset: "EUR",
      available: 75_000,
      pledged: 30_000,
      value: 105_000,
    },
    {
      id: "FIAT3",
      asset: "USD",
      available: 40_000,
      pledged: 10_000,
      value: 50_000,
    },
  ];
}

export function getAvailableBalance(): number {
  return getFiatAssets().reduce((acc, asset) => acc + asset.available, 0);
}
