import {
  UserCategory,
  UserKind,
  DocumentType,
} from "../../../utils/types/enums";
import { MapEntityDocumentLight } from "../../../utils/types/types";
import CustomRoundedRow from "../../Base/CustomRoundedRow";

import { ReactComponent as MissingIcon } from "../../../assets/images/gray-minus-circle.svg";
import { ReactComponent as DoneIcon } from "../../../assets/images/green-check-circle.svg";
import { ReactComponent as UpdateIcon } from "../../../assets/images/refresh-cw.svg";
import { ReactComponent as UploadIcon } from "../../../assets/images/white-upload.svg";
import { Button, Stack, Typography } from "@mui/material";
import CustomMark, {
  CustomMarkColor,
  CustomMarkTextColor,
} from "../../Base/CustomMark";
import StartButton from "../StartButton";
import { EntityDocumentLight } from "../../../utils/types/interfaces";

export interface DocumentsListProps {
  documents: MapEntityDocumentLight;
  userKind: UserKind;
  userCategory: UserCategory;
  onClick: (
    document_type: DocumentType,
    documents?: EntityDocumentLight[]
  ) => void;
}

const DocumentsList = (props: DocumentsListProps) => {
  const expectedDocuments = DocumentType.getDocumentTypesByUser(
    props.userKind,
    props.userCategory
  );

  if (props.userCategory === UserCategory.RETAIL) {
    expectedDocuments.push(DocumentType.ProofOfReserves);
  }

  const rightObjIfExists = (value: DocumentType) => {
    return props.userKind === UserKind.INDIVIDUAL ? (
      <Button
        variant="outlined"
        color="primary"
        endIcon={<UpdateIcon />}
        component="label"
        onClick={() => props.onClick(value)}
      >
        Update
      </Button>
    ) : (
      <CustomMark
        markColor={CustomMarkColor.Green}
        markTextColor={CustomMarkTextColor.Green}
        text="Completed"
        onClick={() => props.onClick(value)}
      />
    );
  };

  const rightObjIfNotExists = (value: DocumentType) => {
    return props.userKind === UserKind.INDIVIDUAL ? (
      <Button
        variant="contained"
        color="primary"
        endIcon={<UploadIcon color="white" />}
        component="label"
        onClick={() => props.onClick(value)}
      >
        Upload
      </Button>
    ) : (
      <StartButton
        variant="outlined"
        color="primary"
        onClick={() => props.onClick(value)}
      />
    );
  };

  return (
    <Stack direction="column" spacing={1} width="100%">
      {expectedDocuments.map((value: DocumentType) => {
        return props.documents[value] ? (
          <CustomRoundedRow key={value}>
            <Stack direction="row" spacing={1}>
              {props.userKind === UserKind.INDIVIDUAL && <DoneIcon />}
              <Typography variant="h6" color="black" boxSizing="border-box">
                {DocumentType.getDocumentViewTitle(value)}
              </Typography>
            </Stack>
            {rightObjIfExists(value)}
          </CustomRoundedRow>
        ) : (
          <CustomRoundedRow key={value} disabled={true}>
            <Stack direction="row" spacing={1}>
              {props.userKind === UserKind.INDIVIDUAL && <MissingIcon />}
              <Typography variant="h6" color="black">
                {DocumentType.getDocumentViewTitle(value)}
              </Typography>
            </Stack>
            {rightObjIfNotExists(value)}
          </CustomRoundedRow>
        );
      })}
    </Stack>
  );
};

export default DocumentsList;
