import { Grid, Stack } from "@mui/material";
import {
  FixedIncomeBond,
  PreIssuanceFixedIncomeBond,
  UserInfo,
  HyDidInfo,
} from "../../utils/types/interfaces";
import { useState } from "react";
import {
  TokenDetail,
  getTokenDetail,
} from "../../utils/mockup/tokenisation/getTokenDetail";
import TokenDetailsView from "../TokenisationPlatform/TokenDetailsView";
import { Asset } from "../../utils/mockup/dashboard/getAssets";
import { MapEntityDocumentLight } from "../../utils/types/types";
import DigitalIDBox from "./Common/DigitalIDBox";
import DigitalWalletBox from "./Common/DigitalWalletBox";
import TokenPlatformBox from "./Common/TokenPlatformBox";

import SettingsBox from "./Common/SettingsBox";

export interface InsitutionViewProps {
  dataGetTokenInProcess: PreIssuanceFixedIncomeBond | null;
  loadedGetTokenInProcess: boolean;
  assets: Asset[];
  documents: MapEntityDocumentLight;
  userInfo: UserInfo;
  dataGetHyDidInfo: HyDidInfo | null;
  loadedGetHyDidInfo: boolean;
  handleOnClickManageDigitalWalletBox: any;
  handleOnClickViewMoreDigitalIdBox: any;
  handleOnClickViewTokenPlatformBox: any;
}

const InstitutionView = (props: InsitutionViewProps) => {
  const [tokenDetails, setTokenDetails] = useState<TokenDetail | undefined>(
    undefined
  );

  const handleMockupTokenDetailsOnClick = (token_id: string) => {
    const data = getTokenDetail(token_id);
    setTokenDetails(data);
  };

  const handleTokenDetailsOnClick = (token: FixedIncomeBond) => {
    const data: TokenDetail = {
      id: "" + token.name + token.issuer_id + token.product_provider_id,
      asset: token.name,
      price: token.division,
      nominal: token.notional,
      interest: token.coupon_rate,
      type: token.coupon_type,
      term: token.term,
      issuer: token.issuer_id,
      prospectus_hash: token.kiid_sha256_hash,
      term_sheet_hash: token.offer_document_sha256_hash,
      in_process: true,
      mockup: false,
    };
    setTokenDetails(data);
  };

  const handleOnClickBackButtonInTokenDetailsView = () => {
    setTokenDetails(undefined);
  };

  return (
    <>
      {tokenDetails === undefined ? (
        <Grid container width={"100%"} columnSpacing={2}>
          <Grid item xs={12} sm={4}>
            <Stack direction="column" spacing={2}>
              <DigitalIDBox
                documents={props.documents}
                userKind={props.userInfo.kind}
                userCategory={props.userInfo.category}
                handleOnClickViewMore={props.handleOnClickViewMoreDigitalIdBox}
              />
              <SettingsBox />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Stack direction="column" spacing={2}>
              <DigitalWalletBox
                assets={props.assets}
                handleOnClickManage={props.handleOnClickManageDigitalWalletBox}
              />
              <TokenPlatformBox
                dataGetTokenInProcess={props.dataGetTokenInProcess}
                loadedGetTokenInProcess={props.loadedGetTokenInProcess}
                userCategory={props.userInfo.category}
                handleOnClickView={props.handleOnClickViewTokenPlatformBox}
                handleOnClickDetails={handleTokenDetailsOnClick}
                handleOnClickDetailsMockup={handleMockupTokenDetailsOnClick}
              />
            </Stack>
          </Grid>
        </Grid>
      ) : (
        <TokenDetailsView
          tokenDetails={tokenDetails}
          userInfo={props.userInfo}
          hyDidInfo={props.dataGetHyDidInfo}
          onClickBackButton={handleOnClickBackButtonInTokenDetailsView}
        />
      )}
    </>
  );
};

export default InstitutionView;
