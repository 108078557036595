import React, { PropsWithChildren } from "react";
import { Select, SelectProps, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import theme from "../../theme";

interface CustomSelectFieldProps {
  enableDescription: boolean;
  description: string;
  descriptionTextColor: string;
  placeholder: string;
  value: string | any;
  mandatory: boolean;
  disabled: boolean;
  className?: string;
  fullWidth: boolean;
  onChange: any;
  error: boolean;
  errorText: string;
  displayEmpty?: boolean;
}

const CustomStyleSelectField = styled(Select)<SelectProps>(({ theme }) => ({
  borderColor: theme.palette.grey.A200,
  borderRadius: "8px",
}));

function CustomSelectField(props: PropsWithChildren<CustomSelectFieldProps>) {
  return (
    <Stack spacing={0.5}>
      {props.enableDescription && (
        <Typography color={props.descriptionTextColor} variant="body2">
          {props.description}
          {props.mandatory && <sup>*</sup>}
        </Typography>
      )}
      <CustomStyleSelectField
        key={props.description}
        disabled={props.disabled}
        placeholder={props.placeholder}
        value={props.value}
        error={props.error}
        className={props.className ? props.className : ""}
        onChange={props.onChange}
        fullWidth={props.fullWidth}
        sx={props.disabled ? { backgroundColor: "#EBEBEB" } : {}}
        displayEmpty={props.displayEmpty}
      >
        {props.children}
      </CustomStyleSelectField>
      <Typography variant="body2" color={theme.palette.error.main}>
        {props.error ? props.errorText : ""}
      </Typography>
    </Stack>
  );
}

export default CustomSelectField;
