import axios, { AxiosResponse } from "axios";
import { HyLabsResponse, UserInfo } from "../../utils/types/interfaces";
import { HYNETWORK_AXIOS_CONFIG_JSON_CONTENT } from "../util";

export function getAllUsers(): Promise<UserInfo[]> {
  return axios
    .get("/admin/get-users", HYNETWORK_AXIOS_CONFIG_JSON_CONTENT)
    .then((response: AxiosResponse): UserInfo[] => {
      const hynetwork_response: HyLabsResponse = response.data;
      const user_info: UserInfo[] = hynetwork_response.data;
      return user_info;
    })
    .catch((err) => {
      throw err;
    });
}
