const PDFJS = require("pdfjs-dist");
PDFJS.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${PDFJS.version}/build/pdf.worker.min.js`;

export function pdfToDataUrl(buffer: ArrayBuffer, onLoadedSetState: any) {
  const uri = URL.createObjectURL(new Blob([buffer]));
  const canvas = document.createElement("canvas");
  canvas.setAttribute("className", "canv");
  document.querySelector(".canv");
  PDFJS.getDocument({ url: uri }).promise.then((pdf_doc: any) => {
    pdf_doc.getPage(1).then((page: any) => {
      var viewport = page.getViewport({ scale: 1 });
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      var render_context = {
        canvasContext: canvas.getContext("2d"),
        viewport: viewport,
      };
      page.render(render_context).promise.then(() => {
        onLoadedSetState(canvas.toDataURL("image/png"));
      });
    });
  });
}
