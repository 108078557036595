import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { activate, resetCode } from "../../routes/hynetwork/activate";
import { Box, Button, Stack, Typography } from "@mui/material";
import theme from "../../theme";
import AuthCode, { AuthCodeRef } from "react-auth-code-input";
import CustomButton from "../Base/CustomButton";

interface ActivationFormProps {
  setSuccess: (b: boolean) => void;
}

function ActivationForm(props: ActivationFormProps) {
  const [headerText, setHeaderText] = useState("Activate your account!");

  const navigate = useNavigate();

  const [codeText, setCodeText] = useState("");
  const [codeValidation, setCodeValidation] = useState(true);
  const AuthInputRef = useRef<AuthCodeRef>(null);

  const handleCodeChange = (string: string) => {
    setCodeText(string);
  };

  const handleActivateButton = () => {
    if (codeText.length === 5) {
      setCodeValidation(true);
      activate({ code: codeText })
        .then(() => {
          props.setSuccess(true);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            setCodeValidation(false);
            AuthInputRef.current?.clear();
          }
          if (err.response.status === 422) {
            navigate("/auth/login");
          }
        });
    } else {
      setCodeValidation(false);
    }
  };

  const handleSendAgain = () => {
    resetCode()
      .then(() => {
        setHeaderText("Check your inbox!");
      })
      .catch((err) => {
        if (err.response.status === 401 || err.response.status === 422) {
          navigate("/auth/login");
        }
      });
  };

  return (
    <>
      <Typography textAlign="center" mb="32px">
        {headerText}
      </Typography>
      <Box marginX="auto" mb="24px" width="fit-content">
        <AuthCode
          allowedCharacters="numeric"
          onChange={handleCodeChange}
          inputClassName={
            codeValidation
              ? "activationCodeInput"
              : "activationCodeInputInvalid"
          }
          placeholder="0"
          ref={AuthInputRef}
          length={5}
        />
        {!codeValidation && (
          <Typography color={theme.palette.error.main} variant="body2">
            The code entered is invalid. Please try again.
          </Typography>
        )}
      </Box>
      <Stack spacing={2} mt="8px" mb="32px">
        <CustomButton
          variant="contained"
          fullWidth={true}
          onClick={handleActivateButton}
        >
          <Typography paddingY="12px">Verify</Typography>
        </CustomButton>
        <Button variant="outlined" fullWidth={true} onClick={handleSendAgain}>
          <Typography paddingY="12px">Resend</Typography>
        </Button>
      </Stack>
    </>
  );
}

export default ActivationForm;
